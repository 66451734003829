import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Highlight,
  Icon,
  Link,
  SimpleGrid,
  Text,
  VStack,
  useSteps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CSVUpload from "../buttons/modalselectbrookerbutton";
import RadioComponent from "../buttons/radiobuttons";
import SelectButton from "../buttons/selectbuttons";
import EmotionSelector from "../inputs/emotionselector";
import ImageUpload from "../inputs/imageupload";
import TextEditor from "./texteditor";

const Stepsystem = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const [step, setStep] = useState(0);
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [better, setBetter] = useState([]);
  const [grade, setGrade] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [journalStatus, setJournalStatus] = useState(false);
  const [test, setTest] = useState(false);
  const buttonTitles = [
    "Exits",
    "Sizing",
    "Förberedelser",
    "Mentalt",
    "Greed & Fomo",
    "Stops",
    "Entries",
    "Orderboken",
  ];

  const findJournal = async () => {
    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/stepsystem", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const journalStatus = await response.json();

        setJournalStatus(journalStatus.journalExistsToday);
        if (journalStatus.journalExistsToday) {
          setTest(true);
        }
      }
    } catch (error) {
      //console.error("Kunde inte se om journal redan finns.");
    }
  };

  useEffect(() => {
    findJournal();
  }, []);

  const createJournal = async (journalData) => {
    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/newpage/journal", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(journalData),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(
          `HTTP error! status: ${response.status} Message: ${data.message}`
        );
      }

      const data = await response.json();
      //console.log(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const extractTextFromEditorJSON = (editorJSON) => {
    const paragraphs = editorJSON.content || [];
    let allText = "";

    paragraphs.forEach((p) => {
      p.content.forEach((c) => {
        if (c.type === "text") {
          allText += c.text;
        }
      });
    });

    return allText;
  };
  const clearStepSystemStorage = () => {
    localStorage.removeItem("editorContent_editor1");
    localStorage.removeItem("editorContent_editor2");
    localStorage.removeItem("selectedEmoji");
    localStorage.removeItem("selectedRadioOption");

    // Clear local storage for SelectButton components
    buttonTitles.forEach((title) => {
      localStorage.removeItem(title);
    });
  };

  const handleSubmit = async () => {
    // const extractedContent1 = extractTextFromEditorJSON(content1);
    // const extractedContent2 = extractTextFromEditorJSON(content2);

    const journalData = {
      beforeToday: content1,
      afterToday: content2,
      mentality: selectedEmoji,
      better: better,
      grade: grade,
      date: getCurrentDate(),
      image: selectedImages,
    };

    //console.log("Sending data:", journalData);

    try {
      await createJournal(journalData);
      // Navigate to a different route after successful journal creation
      navigate("/stats");
      clearStepSystemStorage();
    } catch (error) {
      //console.error(error);
      // Handle errors if needed
    }
  };

  const steps = [
    { title: "", description: " " },
    { title: "", description: " " },
    { title: "", description: " " },
  ];

  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  useEffect(() => {
    findJournal();

    // Initialize `better` state from localStorage
    const initialBetter = [];
    [
      "Exits",
      "Sizing",
      "Förberedelser",
      "Mentalt",
      "Greed & Fomo",
      "Stops",
      "Entries",
      "Orderboken",
    ].forEach((title) => {
      const isSelected = localStorage.getItem(title);
      if (isSelected === "true") {
        // Ensure the stored string is correctly parsed
        initialBetter.push(title);
      }
    });
    setBetter(initialBetter);
  }, []);

  const handleBetterClick = (title) => {
    setBetter((prevBetter) => {
      let updatedBetter;
      if (prevBetter.includes(title)) {
        // Remove the title from the array
        updatedBetter = prevBetter.filter((t) => t !== title);
      } else {
        // Add the title to the array
        updatedBetter = [...prevBetter, title];
      }

      // Log the updated array to the //console
      //console.log("Updated better array:", updatedBetter);

      return updatedBetter;
    });
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (index, title) => {
    setSelectedOption(index);
    setGrade(title);
    localStorage.setItem("selectedRadioOption", index); // Store the selected option index in localStorage
  };

  const handleEmojiChange = (id) => {
    localStorage.setItem("selectedEmoji", id); // Save the selected emoji's ID to localStorage
    setSelectedEmoji(id); // Update the state
  };

  const handleEditorChange = (content, step) => {
    if (step === 1) {
      setContent1(content);
      localStorage.setItem("content1", JSON.stringify(content1));
    } else if (step === 3) {
      setContent2(content);
      localStorage.setItem("content2", JSON.stringify(content2));
    }
  };

  useEffect(() => {
    const savedContent1 = JSON.parse(localStorage.getItem(content1));
    const savedContent2 = JSON.parse(localStorage.getItem(content2));

    if (savedContent1) {
      setContent1(savedContent1);
    }
    if (savedContent2) {
      setContent2(savedContent2);
    }

    // Rest of your useEffect code
  }, []);

  useEffect(() => {
    // Existing logic to initialize `better` state...

    // Initialize `selectedOption` state from localStorage
    const storedSelectedOption = localStorage.getItem("selectedRadioOption");
    if (storedSelectedOption !== null) {
      setSelectedOption(parseInt(storedSelectedOption, 10)); // Make sure to parse the stored string as a number
    }
    const storedEmojiId = localStorage.getItem("selectedEmoji");
    if (storedEmojiId) {
      setSelectedEmoji(parseInt(storedEmojiId, 10)); // Ensure the ID is stored as an integer
    }
  }, []);

  const colorWhite = "#fff";
  const navigate = useNavigate();
  const [isCsvUploadModalOpen, setIsCsvUploadModalOpen] = useState(false);
  const radioOptions = [
    {
      title: "Missnöjd",
      index: 0,
      customColor: "rgba(229, 62, 62, 0.15)",
      customTitleColor: "#E53E3E",
    },
    {
      title: "Lagom",
      index: 1,
      customTitleColor: "#00B0ED",
      customColor: "rgba(0, 176, 237, 0.15)",
    },
    {
      title: "Nöjd",
      index: 2,
      customTitleColor: "#05d8a5",
      customColor: "rgba(5, 216, 165, 0.15)",
    },
  ];
  const totalSteps = 7; // Total number of steps (from step 1 to step 7)
  const completionPercentage = ((step - 1) / (totalSteps - 1)) * 100;

  // Define the gradient for the button
  const buttonBg = `linear-gradient(to right, #05d8a5 ${completionPercentage}%, white ${completionPercentage}%)`;

  if (test) {
    return (
      <Flex
        direction="column"
        width="100%"
        height="720px"
        p={4} // Padding might help your layout breathe a little
      >
        <Flex
          direction="column"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <VStack>
            <span>
              <Icon as={FaCheckCircle} boxSize={16} color="#05d8a5" />
            </span>

            <Heading>Journal redan skriven!</Heading>
            <Box mt={-2} mb={3}>
              Ladda upp eventuell CSV på{" "}
              <Link
                onClick={() => navigate("/stats")}
                isExternal
                color={"#05d8a5"}
                _hover={{ textDecoration: "underline" }}
              >
                Alla Trades
              </Link>
            </Box>
          </VStack>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex
        direction="column"
        width="100%"
        height="720px"
        p={4} // Padding might help your layout breathe a little
      >
        <Flex
          direction="column"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          {/* STEG 0 LADDA UPP CSV FRÅN IGÅR */}
          {step === 0 && (
            <>
              <VStack>
                <Heading>Innan vi börjar!</Heading>
                <Box mt={-2} mb={3} color="#868686">
                  <Highlight query="tidigare" styles={{ color: "#05d8a5" }}>
                    Glöm ej att lägga in handeln från tidigare dagar.
                  </Highlight>
                </Box>
                <Button onClick={() => setIsCsvUploadModalOpen(true)}>
                  Ladda upp CSV fil
                </Button>
                <CSVUpload
                  onSuccess={() => {
                    setIsCsvUploadModalOpen(false); // Close the modal
                    goToNextStep(); // Move to the next step
                  }}
                  isOpen={isCsvUploadModalOpen}
                  onClose={() => setIsCsvUploadModalOpen(false)}
                />
              </VStack>
            </>
          )}

          {/* STEG 1 SKRIVA TEXT */}
          {step === 1 && (
            <VStack>
              <Heading>Pre handel</Heading>
              <VStack alignItems="center">
                <Box mb={8}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Skriv ner påminnelser och mål inför dagen.
                  </Text>
                </Box>
                <TextEditor
                  id="editor1"
                  onChange={(content) => {
                    if (step === 1) {
                      setContent1(content);
                    } else if (step === 3) {
                      setContent2(content);
                    }
                  }}
                />
              </VStack>
            </VStack>
          )}

          {/* STEG 2 MENTALITET */}
          {step === 2 && (
            <VStack>
              <Heading>Mentalitet Idag</Heading>
              <VStack alignItems="center">
                <Box mb={8}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Kom ihåg att vara mentalt förberedd.
                  </Text>
                </Box>
                <Box mt={5}>
                  <EmotionSelector
                    selectedEmoji={selectedEmoji}
                    setSelectedEmoji={setSelectedEmoji}
                    onEmojiSelect={handleEmojiChange} // Pass handleEmojiChange as a prop
                  />
                </Box>
              </VStack>
            </VStack>
          )}

          {/* STEG 3 POST-MARKET TEXT */}
          {step === 3 && (
            <VStack>
              <Heading>Post handel</Heading>
              <VStack alignItems="center">
                <Box mb={8}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Gick det som du hade tänkt dig? Skriv ner tankar.
                  </Text>
                </Box>
                <TextEditor
                  id="editor2"
                  onChange={(content) => {
                    if (step === 1) {
                      setContent1(content);
                    } else if (step === 3) {
                      setContent2(content);
                    }
                  }}
                />
              </VStack>
            </VStack>
          )}
          {/* STEG 4 FÖRBÄTTRINGAR */}
          {step === 4 && (
            <VStack>
              <Heading>Vad kunde gått bättre?</Heading>
              <VStack alignItems="center">
                <Box mb={8}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Vilka moment behöver du förbättra utvärderat från dagens
                    handel?
                  </Text>
                </Box>
                <SimpleGrid columns={4} spacing={10}>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Exits"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Sizing"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Förberedelser"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Mentalt"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Greed & Fomo"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Stops"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Entries"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                  <SelectButton
                    customtitlecolor="#05d8a5"
                    customcolor="rgba(5, 216, 165, 0.15)"
                    title={"Orderboken"}
                    onClick={handleBetterClick}
                  ></SelectButton>
                </SimpleGrid>
              </VStack>
            </VStack>
          )}

          {/* STEG 5 LADDA UPP BILDER */}
          {step === 5 && (
            <VStack>
              <Heading>Ladda upp bilder</Heading>
              <VStack alignItems="center">
                <Box mb={4}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Bifoga dina magiska screenshots när du är helt sne.
                  </Text>
                </Box>
              </VStack>

              <ImageUpload
                onImagesChange={(images) => setSelectedImages(images)}
              />
            </VStack>
          )}

          {/* STEG 6 NÖJD ELLER INTE */}
          {step === 6 && (
            <VStack>
              <Heading>Betygsättning</Heading>
              <VStack alignItems="center">
                <Box mb={8}>
                  <Text fontSize="14" color="#868686" opacity={0.87}>
                    Är du nöjd eller missnöjd med dagen?
                  </Text>
                </Box>

                <SimpleGrid columns={3} spacing={10}>
                  {radioOptions.map((option) => (
                    <RadioComponent
                      key={option.index}
                      title={option.title}
                      customColor={option.customColor}
                      titleColor={option.customTitleColor}
                      isSelected={selectedOption === option.index}
                      onChange={() =>
                        handleRadioChange(option.index, option.title)
                      }
                    />
                  ))}
                </SimpleGrid>
              </VStack>
            </VStack>
          )}

          {/* STEG 7 KLAR! */}
          {step === 7 && (
            <>
              <VStack>
                <Heading color={"#05D8A5"}>Journal klar!</Heading>
                <Text>
                  Kom tillbaka imorgon för uppladdning av statistik, eller ladda
                  upp ikväll.
                </Text>
                <HStack spacing={4}>
                  <Button onClick={() => setIsCsvUploadModalOpen(true)}>
                    Ladda upp CSV idag
                  </Button>
                  <Button colorScheme="pink" onClick={handleSubmit}>
                    {/* UPPLADDNING CSV*/}
                    Spara & Analysera
                  </Button>
                </HStack>

                <Button
                  background={"none"}
                  color={colorWhite}
                  mt={4}
                  onClick={goToPreviousStep}
                  size={"sm"}
                  _hover={{ background: "#1C1E22" }}
                >
                  Tillbaka
                </Button>

                <CSVUpload
                  isOpen={isCsvUploadModalOpen}
                  onClose={() => setIsCsvUploadModalOpen(false)}
                />
              </VStack>
            </>
          )}
        </Flex>

        {/* FOOTER */}
        <Flex direction="column" align="center">
          {/* Navigation Buttons */}
          <HStack spacing={4}>
            {step !== 0 && step !== 7 ? (
              <>
                <Button
                  background={"none"}
                  color={colorWhite}
                  onClick={goToPreviousStep}
                  _hover={{ background: "#1C1E22" }}
                >
                  Tillbaka
                </Button>
                <Button style={{ background: buttonBg }} onClick={goToNextStep}>
                  Nästa
                </Button>
              </>
            ) : null}

            {step === 0 && (
              <Button
                background={"none"}
                color={colorWhite}
                onClick={goToNextStep}
                size={"sm"}
                _hover={{ background: "#1C1E22" }}
              >
                Hoppa över
              </Button>
            )}
          </HStack>
        </Flex>
      </Flex>
    );
  }
};

export default Stepsystem;
