import { Button, ChakraProvider, Flex, HStack, Text, VStack, extendTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Kontosida = () => {
	const navigate = useNavigate();
	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={75} mr={75} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Konto
							</Text>
						</HStack>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Kontosida;

//<Thincard title="SQN Värde" content={"(.....)"} />
//
//<Icon ml={10} boxSize={18} as={IoMdBookmark} />
