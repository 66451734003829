import {
	Box,
	Button,
	ChakraProvider,
	Flex,
	HStack,
	Heading,
	SimpleGrid,
	Text,
	useBreakpointValue,
	VStack,
	extendTheme,
	Tooltip,
	Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import "../../components/pagerelated/css/feed.css";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";
import Annncharten from "../../components/charts/annanchart";
import Stockchart from "../../components/charts/stockchart";
import { AiOutlineInfoCircle } from "react-icons/ai";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Newschart = () => {
	const navigate = useNavigate();
	const [stockData, setStockData] = useState([]);
	const [selectedTicker, setSelectedTicker] = useState("");
	const [newsFeed, setNewsFeed] = useState([]);
	const [latestPostId, setLatestPostId] = useState("");
	const [isLiveUpdate, setIsLiveUpdate] = useState(true);

	useEffect(() => {
		// Function to fetch GlobeNewswire feed
		let intervalId;

		const fetchGlobeNewswireFeed = async () => {
			console.log("Refetching GlobeNewswire feed...");
			const globeNewswireUrl = "https://www.globenewswire.com/JSonFeed/country/United States/feedTitle/GlobeNewswire";
			try {
				const response = await fetch(globeNewswireUrl);
				const data = await response.json();
				// Check if the fetched post is different from the last one
				if (data && data.length > 0 && data[0].Identifier !== latestPostId) {
					console.log("New post detected with ID:", data[0].Identifier);
					setLatestPostId(data[0].Identifier);
					setNewsFeed(data);
					const latestTickerPost = data.find((post) => post.StockTickers && post.StockTickers.length > 0);
					if (latestTickerPost) {
						const latestTickerPostIdentifier = latestTickerPost.Identifier;

						console.log("Latest post with StockTickers ID:", latestTickerPostIdentifier);
						selectAndFetchTicker(latestTickerPost.StockTickers[0]);
					}
				}
			} catch (error) {
				console.error("Fetching GlobeNewswire feed failed:", error);
			}
		};
		if (isLiveUpdate) {
			// Initial fetch on component mount or when toggled on
			fetchGlobeNewswireFeed();
			// Set up an interval to fetch the feed every 10 seconds
			intervalId = setInterval(fetchGlobeNewswireFeed, 10000);
		}

		// Clean up the interval when component unmounts or when live update is toggled off
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
				console.log("Live updates stopped."); // Optionally log when stopping
			}
		};
	}, [isLiveUpdate, latestPostId]); // Add latestPostId as a dependency

	// Toggle function
	const toggleLiveUpdates = () => {
		setIsLiveUpdate(!isLiveUpdate);
	};

	const fetchStockDataForTicker = async (ticker) => {
		console.log("Fetching stock data for ticker:", ticker);
		const apiKeyTwelveData = "4032d1c4269c466ea1b4a09cbef0c8ac"; // Replace with your Twelve Data API key
		const today = new Date();
		const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
		const startYTD = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
		const urlTwelveData = `https://api.twelvedata.com/time_series?symbol=${ticker}&interval=1day&start_date=${
			threeMonthsAgo.toISOString().split("T")[0]
		}&end_date=${new Date().toISOString().split("T")[0]}&apikey=${apiKeyTwelveData}`;

		try {
			const response = await fetch(urlTwelveData);
			const data = await response.json();
			if (data.values && data.values.length > 0) {
				const formattedData = data.values.map((item) => [
					new Date(item.datetime).getTime(),
					parseFloat(item.open),
					parseFloat(item.high),
					parseFloat(item.low),
					parseFloat(item.close),
					parseInt(item.volume),
				]);
				setStockData(formattedData.reverse()); // Update stock data in state
				setSelectedTicker(ticker); // Update the currently selected ticker
			} else {
				setStockData([]); // Empty the data if none returned
			}
		} catch (error) {
			console.error("Error fetching stock data:", error);
			setStockData([]); // Ensure state is cleared on error
		}
	};
	const selectAndFetchTicker = async (tickerWithExchange) => {
		const tickerSymbol = tickerWithExchange.split(":")[1];
		fetchStockDataForTicker(tickerSymbol);
	};
	const renderNewsFeedItems = () => {
		return newsFeed.map((item, index) => (
			<Box
				key={index}
				{...boxStyles}
				onClick={() => {
					if (item.StockTickers && item.StockTickers.length > 0) {
						// Assuming the ticker is in a format like "Exchange:Symbol" and you want the Symbol
						const tickerSymbol = item.StockTickers[0].split(":")[1];
						fetchStockDataForTicker(tickerSymbol);
					}
				}}
			>
				<Heading size="md">{item.Title}</Heading>

				<Text fontSize="sm" my={2}>
					Publicerad:{" "}
					<b>
						{new Date(item.ReleaseDateTime).toLocaleDateString()} {new Date(item.ReleaseDateTime).toLocaleTimeString()}
					</b>
				</Text>
				{item.StockTickers && item.StockTickers.length > 0 && (
					<Text fontSize="sm" my={2}>
						Ticker: <b>{item.StockTickers.map((ticker) => ticker.split(":")[1]).join(", ")}</b>
					</Text>
				)}
				<Text mt={2}>{item.ContentSummary}</Text>
				<HStack
					pl={6}
					pr={6}
					spacing={8}
					alignContent={"center"}
					borderRadius={"lg"}
					mt={4}
					w={"full"}
					backgroundColor={"white"}
					color={"black"}
					pt={2}
					pb={2}
				>
					<Button as="a" href={item.Url} target="_blank">
						Läs mer
					</Button>

					{item.OrgLogo && item.OrgLogo.ImageUrl && (
						<Box align="center">
							<img
								src={item.OrgLogo.ImageUrl} // Use the ImageUrl directly
								alt={item.OrgLogo.ImageAlternateText || "Organization Logo"}
								style={{ maxWidth: "100px", maxHeight: "100px" }} // Adjust size as needed
							/>
						</Box>
					)}
				</HStack>
			</Box>
		));
	};

	const boxStyles = {
		minWidth: "100%",
		border: "4px solid ", // Use a transparent border

		borderRadius: "lg",
		backgroundColor: "white",
		color: "white",
		bg: "#090A0B",
		borderWidth: "1px",
		borderColor: "#1C1E22",
		borderRadius: "2xl",

		boxShadow: "rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset",
		p: 6,
		ml: 0,
		mr: 0,
		_hover: {
			borderColor: "#05D8A5", // Slightly darker border on hover
			boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
			borderWidth: "4px", // Optional: adds a subtle shadow effect on hover
		},
	};

	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack w={"100%"} flex={1} alignItems="flex-start" gap="7" ml={50} mr={50} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ News Chart
							</Text>
						</HStack>
						<MyMarqueeComponent></MyMarqueeComponent>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<HStack align={"center"} w="auto" spacing={6}>
						<Heading>Demo Nyhetsflöde</Heading>
						<Tooltip
							label={
								"Detta är en tidig demo för hur vi vill bygga det bästa nyhetsflöde med realtid AI sentimentering. Endast USA och tidiga funktioner i nuläget. Begränsat med API calls, därav fungerar ibland inte chartsen till vänster funktionen begränsat."
							}
							placement="auto-start"
							gutter={20}
							bg="#090A0B"
							color={colorWhite}
							padding={3}
							borderRadius={"2xl"}
						>
							<span>
								<Icon as={AiOutlineInfoCircle} boxSize={5} color="#868686" />
							</span>
						</Tooltip>
					</HStack>

					<Button
						onClick={toggleLiveUpdates}
						color={isLiveUpdate ? "#E53E3E" : "#05D8A5"} // Dynamically setting the background color
						bg={isLiveUpdate ? "rgba(229, 62, 62, 0.15)" : "rgba(5, 216, 165, 0.15)"} // Dynamically setting the text color
						_hover={{
							bg: isLiveUpdate ? "rgba(229, 62, 62, 0.3)" : "rgba(5, 216, 165, 0.3)",
						}}
					>
						{isLiveUpdate ? "Stop Live Updates" : "Start Live Updates"}
					</Button>

					<Flex w={"100%"} flex={1} alignItems="flex-start" gap="7" ml={50} mr={50} pt={0} className="custom-scrollbar" direction="row">
						<Box w="50%" flex="1">
							<Stockchart title={selectedTicker} data={stockData}></Stockchart>
						</Box>
						<VStack
							overflowY="auto"
							maxH="675px"
							w="50%"
							css={{
								"&::-webkit-scrollbar": {
									width: "4px",
								},
								"&::-webkit-scrollbar-track": {
									width: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									background: "gray",
									borderRadius: "24px",
								},
							}}
						>
							{renderNewsFeedItems()}
						</VStack>
					</Flex>
					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Newschart;
