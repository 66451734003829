import {
  Badge,
  Box,
  Button,
  ChakraProvider,
  Flex,
  HStack,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Text,
  VStack,
  extendTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import { getLocalTimeZone, today } from "@internationalized/date";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { IoMdBookmark, IoMdImage } from "react-icons/io";
import { LuShare2 } from "react-icons/lu";
import { MdFileUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { parseData } from "../../algorithmer/algorithmavanza";
import { processTrades } from "../../algorithmer/processTrades";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import CSVUpload from "../../components/buttons/modalselectbrookerbutton";
import CalendarView from "../../components/calendarbig/adobCalendar";
import SmallcardCalendar from "../../components/calendarbig/smallcardCalendar";
import ImproveDisplay from "../../components/cards/displaycards/displayImprove";
import MentalityDisplay from "../../components/cards/displaycards/displayMentality";
import PicturesDisplay from "../../components/cards/displaycards/displayPictures";
import RatingDisplay from "../../components/cards/displaycards/displayRating";
import Longcard from "../../components/cards/longcard";
import Middlecard from "../../components/cards/middlecard";
import Smallcard from "../../components/cards/smallcard";
import Thincard from "../../components/cards/thincard";
import Tradetablecard from "../../components/cards/tradetablecard";
import LinechartComponent from "../../components/charts/linechart";
import PiechartComponent from "../../components/charts/piechart";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import ReadOnlyTextEditor from "../../components/pagerelated/readonlytexteditor";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#090A0B",
        color: "white",
      },
    },
  },
});

const colorWhite = "#fff";

const Stats = () => {
  const [results, setResults] = useState({
    totalProfit: "",
    numberOfTrades: "",
    winRate: "",
    winningTrades: "",
    losingTrades: "",
    totalCourtage: "",
    maxProfit: "",
    minProfit: "",
    averageWinLossTrade: "",
    completedTrades: "",
    averageWin: "",
    averageLoss: "",
    profitFactor: "",
    winLossRatio: "",
    expectancy: "",
    longestWinningStreak: "",
    longestLosingStreak: "",
    intraLow: "",
    intraHigh: "",
    profits: "",
    cumulativeProfits: "",
    evenRate: "",
    lossRate: "",
    stocks: "",
    selectedDay: "",
    newArrayWithMaxProfit: "",
  });

  const navigate = useNavigate();
  const fileInputRef = React.createRef();
  const [step, setStep] = useState("chooseBroker"); // Track the current step
  const [mentality, setMentality] = useState("");
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const [data, setData] = useState(null);
  const handleButtonClick = () => {
    if (step === "chooseBroker") {
      // Move to the next step: uploading CSV file
      setStep("uploadCSV");
      fileInputRef.current.click(); // Open the file picker immediately
    } else if (step === "uploadCSV") {
      // Handle the CSV file upload
      fileInputRef.current.click();
    }
  };

  const createCSV = async (CSVData) => {
    try {
      const formData = new FormData();
      formData.append("source", CSVData.source);
      formData.append("transactions", CSVData.transactions);

      const response = await fetch("https://backend-production-41c0.up.railway.app/stats/csv", {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      //console.error(error);
    }
  };

  const handleSubmit = () => {
    const selectedBrokerName = radioOptions[selectedOption]?.title;

    const CSVData = {
      source: selectedBrokerName,
      transactions: fileInputRef.current.files[0],
    };

    createCSV(CSVData);
  };

  const processFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target.result;
      const results = parseData(data);
      setResults(results);
      setStep("chooseBroker");
      setIsRadioComponentModalOpen(true);
    };
    reader.readAsText(file);
  };

  const MaxprofitValue =
    results.newArrayWithMaxProfit && results.newArrayWithMaxProfit.length > 0
      ? results.newArrayWithMaxProfit[0].profit
      : null;

  const MaxprofitName =
    results.newArrayWithMaxProfit && results.newArrayWithMaxProfit.length > 0
      ? results.newArrayWithMaxProfit[0].stock
      : null;

  const MinprofitValue =
    results.newArrayWithMaxProfit && results.newArrayWithMaxProfit.length > 0
      ? results.newArrayWithMaxProfit[1].profit
      : null;

  const MinprofitName =
    results.newArrayWithMaxProfit && results.newArrayWithMaxProfit.length > 0
      ? results.newArrayWithMaxProfit[1].stock
      : null;

  const positiveAverageLoss = Math.abs(results.averageLoss);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const fullPercantage = positiveAverageLoss + results.averageWin;

  const closeModal = () => {
    setIsRadioComponentModalOpen(false);
  };
  const winPercantage =
    Number(results.averageWin / fullPercantage).toFixed(2) * 100;
  const lossPercantage =
    Number(positiveAverageLoss / fullPercantage).toFixed(2) * 100;

  // Beräknar förhållandet mellan genomsnittlig vinst och genomsnittlig förlust
  const averageWinLossTrade = results.averageWin / positiveAverageLoss;
  const dag = results.selectedDay;

  const [selectedOption, setSelectedOption] = useState(null);
  const [isCsvUploadModalOpen, setIsCsvUploadModalOpen] = useState(false);
  const [isRadioComponentModalOpen, setIsRadioComponentModalOpen] =
    useState(false);
  const handleOpenRadioComponentModal = () => {
    setIsRadioComponentModalOpen(true);
  };

  const handleRadioChange = (index) => {
    setSelectedOption(index);
  };

  const radioOptions = [
    {
      title: "Avanza",
      index: 0,
      customColor: "#05d8a5",
      customTitleColor: "#090A0B",
    },
    {
      title: "Nordnet",
      index: 1,
      customColor: "#090A0B",
      customTitleColor: colorWhite,
    },
    {
      title: "Pareto",
      index: 2,
      customColor: "#1d335a",
      customTitleColor: colorWhite,
    },
  ];

  const renderModalButton = () => {
    if (selectedOption !== null) {
      return (
        <Button
          backgroundColor={"#090A0B"}
          color={colorWhite}
          onClick={handleButtonClick}
          mr={220}
        >
          Ladda upp
        </Button>
      );
    } else {
      return null; // Return null when no broker is selected
    }
  };

  const renderSaveCSV = () => {
    if (selectedOption !== null) {
      return (
        <Button
          backgroundColor={"green.500"}
          color={colorWhite}
          onClick={handleSubmit}
        >
          Spara
        </Button>
      );
    } else {
      return null; // Return null when no broker is selected
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const todayDate = today(getLocalTimeZone());

  const toggleEditing = () => setIsEditing((prevState) => !prevState);
  const handleModalClose = () => {
    setIsCsvUploadModalOpen(false);
    setIsEditing(false); // Reset editing state when modal closes
  };
  //console.log(dag);

  const [journalData, setJournalData] = useState({ journal: {} });
  const [processedTradesData, setProcessedTradesData] = useState(null);

  const handleJournalData = (data) => {
    setJournalData(data);
  };

  const [imageSrc, setImageSrc] = useState("");

  function bufferToBase64(buf) {
    var binstr = Array.prototype.map
      .call(buf, function (ch) {
        return String.fromCharCode(ch);
      })
      .join("");
    return btoa(binstr);
  }

  useEffect(() => {
    // Make sure that journalData and journalData.screenshots are defined and have at least one item
    if (
      journalData &&
      journalData.screenshots &&
      journalData.screenshots.length > 0 &&
      journalData.screenshots[0].image &&
      journalData.screenshots[0].image.data
    ) {
      // Konvertera buffer från responsen till en base64-sträng
      const base64 = bufferToBase64(journalData.screenshots[0].image.data.data);
      setImageSrc(
        `data:${journalData.screenshots[0].image.contentType};base64,${base64}`
      );
    } else {
      setImageSrc(null);
    }
  }, [journalData]);

  useEffect(() => {
    //console.log("journalData has changed:", journalData);

    if (journalData && journalData.trades) {
      const processedData = processTrades(journalData.trades);
      setProcessedTradesData(processedData);
      console.log("Trades:", journalData.trades);
    }
  }, [journalData]);

  useEffect(() => {
    if (processedTradesData) {
      //console.log("Processed Trades Data:", processedTradesData);
    }
  }, [processedTradesData]);

  const averageLoss =
    processedTradesData?.averageLoss ?? "default_value_for_loss";
  const averageWin = processedTradesData?.averageWin ?? "default_value_for_win";

  const transformTradesForTable = () => {
    return (
      journalData?.trades.map((trade) => ({
        stock: trade.Name,
        size: trade.Size.toFixed(0),
        profit: trade.Profit.toFixed(0),
        totalProfitPercentage: ((trade.Profit / trade.Size) * 100).toFixed(2),
        date: trade.Datum,
        courtage: trade.Courtage,
      })) || []
    );
  };

  const hStackMaxWidth = useBreakpointValue({
    base: "100%", // 100% of the screen width on small devices
    md: "100%", // 100% of the screen width on medium devices
    lg: "100%", // 100% of the screen width on large devices
    xl: "100%", // 100% of the screen width on extra-large devices
  });
  return (
    <ChakraProvider theme={theme}>
      <Flex>
        <Sidebar />

        <VStack
          maxW={hStackMaxWidth}
          flex={1}
          alignItems="flex-start"
          gap="7"
          pl={50}
          mr={50}
          pt={0}
          className="custom-scrollbar"
        >
          <HStack justifyContent="space-between" w="100%" mt={9} mb={10}>
            <HStack alignSelf="self-start">
              <Text alignSelf="center" fontSize="12" color="#868686">
                Fönster
              </Text>
              <Text alignSelf="center" fontSize="16" color={colorWhite}>
                / Statistik
              </Text>
            </HStack>
            <MyMarqueeComponent></MyMarqueeComponent>
            <HStack mr={5} alignItems="center" alignSelf="self-end">
              <FeedbackButton></FeedbackButton>
              <Button
                onClick={() => handleLogout(navigate)}
                fontWeight={"medium"}
              >
                Logga ut
              </Button>
            </HStack>
          </HStack>

          <HStack spacing={12} w="100%">
            <Heading>Statistik</Heading>

            <Button
              background="rgb(213,63,140, 0.15)"
              textColor="#d53f8c"
              _hover={{ bg: "rgba(213,63,140, 0.3)" }}
              onClick={() => navigate("/trender#handel")}
              size={"sm"}
              fontWeight={"medium"}
            >
              Analysera dina Statistik Trender
            </Button>

            <Popover backgroundColor="#090A0B">
              <PopoverTrigger backgroundColor="#090A0B">
                <span>
                  <Box
                    as={IoMdBookmark}
                    w={6}
                    h={6}
                    color={"#868686"}
                    _hover={{
                      cursor: "pointer",
                      filter: "brightness(1.2)", // Adds a hover effect with increased brightness
                    }}
                  />
                </span>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  shadow="lg"
                  backgroundColor="#090A0B"
                  color={colorWhite}
                  borderRadius={"2xl"}
                  p={4}
                  border={0}
                >
                  <PopoverArrow backgroundColor="#090A0B" />
                  <PopoverCloseButton color="#868686" />
                  <PopoverBody fontWeight={"medium"}>
                    <VStack spacing={4} align={"flex-start"}>
                      <Text color={"#05D8A5"} mb={-1}>
                        Kommer snart...
                      </Text>
                      <Text mb={-1}>
                        Bokmärk dina dagar för att snabbt gå tillbaka och
                        analysera!
                      </Text>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
            <Popover backgroundColor="#090A0B">
              <PopoverTrigger backgroundColor="#090A0B">
                <span>
                  <Box
                    as={IoMdImage}
                    w={6}
                    h={6}
                    color={"#868686"}
                    _hover={{
                      cursor: "pointer",
                      filter: "brightness(1.2)", // Adds a hover effect with increased brightness
                    }}
                  />
                </span>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  shadow="lg"
                  backgroundColor="#090A0B"
                  color={colorWhite}
                  borderRadius={"2xl"}
                  p={4}
                  border={0}
                >
                  <PopoverArrow backgroundColor="#090A0B" />
                  <PopoverCloseButton color="#868686" />
                  <PopoverBody fontWeight={"medium"}>
                    <VStack spacing={4} align={"flex-start"}>
                      <Text color={"#05D8A5"} mb={-1}>
                        Kommer snart...
                      </Text>
                      <Text mb={-1}>
                        Spara som en bild och dela på mindre än 10 sekunder.
                      </Text>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
            <Popover backgroundColor="#090A0B">
              <PopoverTrigger backgroundColor="#090A0B">
                <span>
                  <Box
                    as={LuShare2}
                    w={6}
                    h={6}
                    color={"#868686"}
                    _hover={{
                      cursor: "pointer",
                      filter: "brightness(1.2)", // Adds a hover effect with increased brightness
                    }}
                  />
                </span>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  shadow="lg"
                  backgroundColor="#090A0B"
                  color={colorWhite}
                  borderRadius={"2xl"}
                  p={4}
                  border={0}
                >
                  <PopoverArrow backgroundColor="#090A0B" />
                  <PopoverCloseButton color="#868686" />
                  <PopoverBody fontWeight={"medium"}>
                    <VStack spacing={4} align={"flex-start"}>
                      <Text color={"#05D8A5"} mb={-1}>
                        Kommer snart...
                      </Text>
                      <Text mb={-1}>
                        Dela din dag med önskade nyckeltal via en länk.
                      </Text>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>

            {/* UPPLADDNING CSV*/}
            <Button
              background={"none"}
              color={"white"}
              leftIcon={<Icon as={AiFillEdit}></Icon>}
              size={"md"}
              onClick={() => navigate("/allatrades")} // Navigate to "/stats" when clicked
              _hover={{
                borderBottomWidth: "0px",
                backgroundColor: "rgba(29, 32, 35, 0.6)",
                filter: "hue-rotate(0deg)",
                opacity: "1",
                boxShadow: "rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset",
              }}
            >
              Ändra
            </Button>
          </HStack>

          <VStack gap={6} w="100%">
            <HStack spacing={16} w="100%" justifyContent="space-between">
              <Box>
                <SmallcardCalendar
                  customWidth="464px"
                  content={
                    <CalendarView onJournalDataFetched={handleJournalData} />
                  }
                  showIcon={false}
                ></SmallcardCalendar>
              </Box>
              <Smallcard
                title="Resultat"
                content={
                  processedTradesData && Number(processedTradesData.totalProfit)
                    ? Number(processedTradesData.totalProfit).toFixed(1) + " kr"
                    : ""
                }
                buttonText="Ladda upp CSV"
                tooltip={"Ditt resultat för dagen."}
              />
              <Smallcard
                title="Totala Avslut"
                content={
                  processedTradesData &&
                  Number(processedTradesData.numberOfTrades)
                    ? Number(processedTradesData.numberOfTrades).toFixed(0)
                    : ""
                }
                buttonText="Ladda upp CSV"
                tooltip={"Antal avslut i form av sälj och köp."}
              />
              <Smallcard
                title="Vinster"
                content={
                  processedTradesData &&
                  Number(processedTradesData.winningTrades)
                    ? Number(processedTradesData.winningTrades)
                    : ""
                }
                buttonText="Ladda upp CSV"
                tooltip={"Antal vunna trades."}
              />
              <Smallcard
                title="Förluster"
                content={
                  processedTradesData &&
                  Number(processedTradesData.losingTrades)
                    ? Number(processedTradesData.losingTrades)
                    : ""
                }
                buttonText="Ladda upp CSV"
                tooltip={"Antal förlorade trades."}
              />
            </HStack>

            <HStack spacing={16} alignItems="flex-start" width="100%">
              <VStack flex={1} spacing={8}>
                <HStack spacing={16} width="100%">
                  <Smallcard
                    title="Totalt courtage"
                    content={
                      processedTradesData &&
                      Number(processedTradesData.totalCourtage)
                        ? Number(processedTradesData.totalCourtage).toFixed(0) +
                          " kr"
                        : ""
                    }
                    buttonText="Ladda upp CSV"
                    tooltip={"Totala courtage och avgifter för dagen."}
                  />

                  <Smallcard
                    title="Profit Factor"
                    content={
                      processedTradesData &&
                      Number(processedTradesData.profitFactor)
                        ? Number(processedTradesData.profitFactor).toFixed(2)
                        : ""
                    }
                    buttonText="Ladda upp CSV"
                    tooltip={
                      "Mått på lönsamheten i en handelsstrategi och beräknas genom att dela den totala vinsten med den totala förlusten. En hög profit factor, till exempel över 1, anses vara önskvärd eftersom det indikerar att den totala vinsten är större än den totala förlusten."
                    }
                  />

                  <Smallcard
                    title="Win/Loss Ratio"
                    content={
                      processedTradesData &&
                      Number(processedTradesData.winLossRatio)
                        ? Number(processedTradesData.winLossRatio).toFixed(2)
                        : ""
                    }
                    buttonText=""
                    tooltip={
                      "Win/loss ratio är förhållandet mellan antalet vinnande och förlorande trades, tillskillnad från winrate % som mäter andelen vinnande trades i förhållande till det totala antalet trades. Ett högt win/loss ratio-värde indikerar fler vinnande än förlorande trades. Ett högt win/loss ratio-värde, till exempel över 1, anses oftast vara önskvärt eftersom det indikerar fler vinnande än förlorande trades."
                    }
                  />
                </HStack>

                <HStack spacing={16} width="100%">
                  <Smallcard
                    title="Längsta Trender"
                    content={
                      <>
                        <Badge
                          p={1}
                          fontSize={12}
                          mb={-2}
                          background="rgba(5, 216, 165, 0.15)"
                          textColor="#05D8A5"
                          textTransform={"none"}
                          borderRadius={"md"}
                        >
                          {processedTradesData &&
                          Number(processedTradesData.longestWinningStreak)
                            ? `${processedTradesData.longestWinningStreak} vinster`
                            : "vinster"}
                        </Badge>
                        <Badge
                          p={1}
                          fontSize={12}
                          mb={-2}
                          ml={2}
                          background="rgba(229, 62, 62, 0.15)"
                          textColor="#E53E3E"
                          textTransform={"none"}
                          borderRadius={"md"}
                        >
                          {processedTradesData &&
                          Number(processedTradesData.longestLosingStreak)
                            ? `${processedTradesData.longestLosingStreak} förluster`
                            : " förluster"}
                        </Badge>
                      </>
                    }
                    buttonText="Ladda upp CSV"
                    tooltip={
                      "Antal vinster och förluster i de längsta vinst- och förluststreaksen."
                    }
                  />

                  <Longcard
                    leftPercentage={
                      processedTradesData && processedTradesData.lossRate
                        ? processedTradesData.lossRate * 100
                        : 0
                    }
                    rightPercentage={
                      processedTradesData && processedTradesData.winRate
                        ? processedTradesData.winRate * 100
                        : 0
                    }
                    title="Avg winloss trade"
                    content={
                      processedTradesData &&
                      Number.isFinite(processedTradesData.averageWin) &&
                      Number.isFinite(processedTradesData.averageLoss) &&
                      processedTradesData.averageLoss !== 0
                        ? (
                            processedTradesData.averageWin /
                            Math.abs(processedTradesData.averageLoss)
                          ).toFixed(2)
                        : ""
                    }
                    averageLoss={
                      processedTradesData && processedTradesData.averageLoss
                        ? processedTradesData.averageLoss
                        : 0
                    }
                    averageWin={
                      processedTradesData && processedTradesData.averageWin
                        ? processedTradesData.averageWin
                        : 0
                    }
                    buttonText="Ladda upp CSV"
                    tooltip="Visar hur mycket du i genomsnitt vinner jämfört med förluster i dina avslutade affärer under en viss tidsperiod. Det beräknas genom att dela den genomsnittliga vinsten per affär med den genomsnittliga förlusten per affär. Till exempel, om du har en genomsnittlig vinst på 500 kr per affär och en genomsnittlig förlust på 250 kr per affär, blir förhållandet 2:1. Det innebär att du i genomsnitt vinner dubbelt så mycket som du förlorar i dina affärer."
                  />
                </HStack>
              </VStack>

              <Box>
                <PiechartComponent
                  title="Winrate %"
                  content={"...."}
                  valueoneName={"Vinster"}
                  valuetwoName={"Förluster"}
                  valuethreeName={"Even"}
                  customHeight={"240px"}
                  customWidth={"486px"}
                  buttonText=""
                  valueoneFormal={
                    processedTradesData && processedTradesData.winRate
                      ? processedTradesData.winRate
                      : 0
                  }
                  valuetwoFormal={
                    processedTradesData && processedTradesData.lossRate
                      ? processedTradesData.lossRate
                      : 0
                  }
                  valuethreeFormal={
                    processedTradesData && processedTradesData.evenRate
                      ? processedTradesData.evenRate
                      : 0
                  }
                  valueone={
                    processedTradesData && processedTradesData.winRate
                      ? (processedTradesData.winRate * 100).toFixed(2) + " %"
                      : "0 %"
                  }
                  valuetwo={
                    processedTradesData && processedTradesData.lossRate
                      ? (processedTradesData.lossRate * 100).toFixed(2) + " %"
                      : "0 %"
                  }
                  valuethree={
                    processedTradesData && processedTradesData.evenRate
                      ? (processedTradesData.evenRate * 100).toFixed(2) + " %"
                      : "0 %"
                  }
                  tooltip={
                    "Andelen vinnande affärer i förhållande till det totala antalet affärer. En hög winrate, till exempel över 50%, är generellt sett önskvärd eftersom det indikerar att fler affärer är vinnande än förlorande."
                  }
                  labelone={"vinster"}
                  labeltwo={"förluster"}
                  labelthree={"even"}
                ></PiechartComponent>
              </Box>

              <Box>
                <Middlecard
                  title="Bästa & Sämsta"
                  array={processedTradesData ? processedTradesData : null}
                  buttonText=""
                  tooltip="Visar dagens mest lönsamma (bästa) och minst lönsamma (sämsta) handelstransaktioner."
                  StörstaProfit={
                    processedTradesData &&
                    Number.isFinite(processedTradesData.maxProfitValue)
                      ? processedTradesData.maxProfitValue
                      : ""
                  }
                  StörstaStock={
                    processedTradesData && processedTradesData.maxProfitName
                      ? processedTradesData.maxProfitName
                      : ""
                  }
                  MinstaProfit={
                    processedTradesData &&
                    Number.isFinite(processedTradesData.minProfitValue)
                      ? processedTradesData.minProfitValue
                      : ""
                  }
                  MinstaStock={
                    processedTradesData && processedTradesData.minProfitName
                      ? processedTradesData.minProfitName
                      : ""
                  }
                ></Middlecard>
              </Box>
            </HStack>

            <HStack
              spacing={16}
              w="100%"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Thincard
                title="Intra Low"
                content={
                  processedTradesData &&
                  Number.isFinite(processedTradesData.intraLow)
                    ? Number(Math.round(processedTradesData.intraLow)).toFixed(
                        2
                      ) + " kr"
                    : ""
                }
                tooltip={"Det lägsta värdet under dagen."}
              />

              <Thincard
                title="Största Förlust"
                content={
                  processedTradesData &&
                  Number.isFinite(processedTradesData.minProfit)
                    ? Number(Math.round(processedTradesData.minProfit)).toFixed(
                        0
                      ) + " kr"
                    : ""
                }
                tooltip={"Den största förlusten under dagen."}
              />
              <Thincard
                title="Expectancy"
                content={
                  processedTradesData && Number(processedTradesData.expectancy)
                    ? Number(
                        Math.round(processedTradesData.expectancy)
                      ).toFixed(2) + " kr"
                    : ""
                }
                tooltip={
                  "Förväntat värde av varje trade baserat på historisk data."
                }
              />
              <Thincard
                title="Intra High"
                content={
                  processedTradesData &&
                  Number.isFinite(processedTradesData.intraHigh)
                    ? Number(Math.round(processedTradesData.intraHigh)).toFixed(
                        2
                      ) + " kr"
                    : ""
                }
                tooltip={"Det högsta värdet under dagen."}
              />
              <Thincard
                title="Största Vinst"
                content={
                  processedTradesData &&
                  Number.isFinite(processedTradesData.maxProfit)
                    ? Number(Math.round(processedTradesData.maxProfit)).toFixed(
                        0
                      ) + " kr"
                    : ""
                }
                tooltip={"Den största vinsten under dagen."}
              />
            </HStack>

            <SimpleGrid
              columns={2}
              alignItems="flex-start"
              width={"100%"}
              justifyContent="space-between"
              gap="16"
              mb={0}
            >
              <LinechartComponent
                title={"Intradagsutveckling"}
                secondTitle={"Cumulative PnL"}
                roundLastValue={1}
                showXAxis={false}
                showDayInTooltip={false}
                prefix={"kr"}
                data={
                  processedTradesData && processedTradesData.cumulativeProfits
                    ? processedTradesData.cumulativeProfits
                    : 0
                }
                tooltip={
                  "Visar den samlade nettovinsten eller nettoförlusten över handelsdagen. Y-axeln är därmed resultat i kr, samt X-axeln antalet trades."
                }
                defaultChartType={"areaspline"}
              ></LinechartComponent>
              <LinechartComponent
                title={"Dagens Trades"}
                roundLastValue={1}
                prefix={"kr"}
                showXAxis={false}
                showDayInTooltip={false}
                secondTitle={"W&L historik av trades"}
                data={
                  processedTradesData && processedTradesData.profits
                    ? processedTradesData.profits
                    : 0
                }
                tooltip={
                  "Visar en sammanfattning av de affärer som genomförts under aktuell handelsdag. Det inkluderar information om både vinst (W) och förlust (L) för varje enskild trade. Används för att få en översiktlig bild av hur handeln har utvecklats över dagen."
                }
                defaultChartType={"column"}
              ></LinechartComponent>
            </SimpleGrid>

            <Box w={"100%"}>
              {journalData?.trades && (
                <Tradetablecard
                  trades={transformTradesForTable()}
                  title={"Trades"}
                  secondTitle={"Dagens alla trades"}
                  tooltip={"Bläddra bland alla dina trades för dagen."}
                  antalTrades={processedTradesData?.numberOfTrades ?? 0}
                />
              )}
            </Box>
          </VStack>

          <HStack spacing={12} w="100%">
            <Heading>Journal</Heading>
            <Button
              background="rgb(213,63,140, 0.15)"
              textColor="#d53f8c"
              _hover={{ bg: "rgba(213,63,140, 0.3)" }}
              onClick={() => navigate("/trender#journal")}
              size={"sm"}
              fontWeight={"medium"}
            >
              Analysera dina Journal Trender
            </Button>
          </HStack>

          <HStack spacing={16} w="100%">
            <ReadOnlyTextEditor
              content={journalData.journal?.beforeToday ?? "Väntar på data.."}
              title={"Innan Öppning"}
              tooltip={"Dina noteringar innan öppning."}
            ></ReadOnlyTextEditor>
            <ReadOnlyTextEditor
              content={journalData.journal?.afterToday ?? "Väntar på data.."}
              title={"Efter Öppning"}
              tooltip={"Dina noteringar efter öppning."}
            ></ReadOnlyTextEditor>
          </HStack>

          <HStack
            spacing={16}
            w={"100%"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            h={"100%"}
          >
            <VStack spacing={8}>
              <MentalityDisplay
                isEditing={isEditing}
                mentalityvalue={
                  journalData.journal?.mentality ?? "Väntar på data..."
                }
                title={"Inställning Inför "}
                tooltip={"Din inställning innan öppning."}
                secondTitle={"Mentaliteten inför dagen"}
              ></MentalityDisplay>
              <PicturesDisplay
                isEditing={isEditing}
                imageSource={imageSrc}
                title={"Bilder "}
                secondTitle={"Dagens uppladdade"}
                tooltip={"Dina uppladdade bilder."}
              ></PicturesDisplay>
            </VStack>
            <ImproveDisplay
              isEditing={isEditing}
              tooltip={"Dina valda förbättringar."}
              initialText={journalData.journal?.better ?? "Väntar på data..."}
              title={"Förbättringar "}
              secondTitle={"Vad du kunde gjort bättre"}
            ></ImproveDisplay>

            <RatingDisplay
              isEditing={isEditing}
              tooltip={"Dina betyg för denna dag."}
              grade={journalData.journal?.grade ?? "Väntar på data..."}
              title={"Betyg "}
              secondTitle={"Var du nöjd med dagen"}
            ></RatingDisplay>
          </HStack>

          <HStack width={"100%"} spacing={1}></HStack>
          <Footer></Footer>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default Stats;
