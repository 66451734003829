import { Box, Flex, Heading, Icon, Skeleton, Tooltip, VStack } from "@chakra-ui/react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HSIndicators from "highcharts/indicators/indicators";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import RSI from "highcharts/indicators/rsi"; 
HSIndicators(Highcharts);
RSI(Highcharts);
function Stockchart({ data, title, tooltip, secondTitle }) {
	const chartRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (data) {
			setIsLoading(false);
		}
	}, [data]);

	const volumeData = data.map((point) => [point[0], point[5]]);
	// Inside your Stockchart component before defining the options

	{
		/*const flagSeries = articles.map((article, index) => ({
		type: "flags",
		data: [
			{
				x: new Date(article.publishedAt).getTime(),
				title: " ", // No title, just space as you specified
				articletitle: article.title,
				linkedTo: article.url,
				text: article.description, // Description of the article
				fillColor: "#d53f8c", // Specific fill color for all flags
				color: "black",
			},
		],
		color: "transparent",
		shape: "circlepin",
		onSeries: "dataseries",
		width: 5,
		height: 5,
		zIndex: 1,
		y: 1 * -20,
	})); */
	}

	const options = {
		chart: {
			type: "candlestick",
			backgroundColor: null,
			height: "550",
	
			zoomType: "x",
			resetZoomButton: {
				theme: {
					style: {
						display: "none",
					},
				},
			},
			spacingBottom: 70,
			spacingTop: 50,
			spacingLeft: 20,
			spacingRight: 20,
		},

		xAxis: {
			type: "datetime",
			dateTimeLabelFormats: {
				day: "%e. %b",
				month: "%b '%y",
				year: "%Y",
			},
		},
		yAxis: [
			{
				gridLineColor: "#868686",
				gridLineWidth: 0.5,
				type: 'logarithmic',
				gridLineDashStyle: "longdash",
				labels: {
					style: {
						color: "#868686",
					},
					align: "left",
				},
				title: {
					enabled: false,
				},
				height: '65%',
			},
			{
				// Secondary yAxis (Volume)
				labels: {
					enabled: false,
				},
				title: {
					enabled: false,
				},
				top: '55%',
                height: '25%',
                offset: 0,
				lineWidth: 0, // Remove yAxis line
				gridLineWidth: 0, // Remove grid lines
			},
			{
				// Additional yAxis for the RSI Indicator
				labels: {
					enabled: false,
				},
				title: {
					enabled: false,
				},
                top: '80%', // Adjust the top to place it below the main chart and any other indicators
                height: '20%', // Set the height of the RSI chart panel
                lineWidth: 0,
				gridLineColor: "#868686",
				gridLineWidth: 0.5,
				min:0,
				max:100,
                opposite: true,
				
			
				plotLines: [{
					value: 70,
					color: '#868686',
					dashStyle: 'longdash',
					width: 0.5,
					
					label: {
						text: '70',
						align: 'right',
						x: 30,
						y: 4,
						style: {
							color: '#868686',
						}
					}
				}, {
					value: 30,
					color: '#868686',
					dashStyle: 'longdash',
					width: 0.5,
					label: {
						text: '30',
						y: 4,
						align: 'right',
						x: 30,
						style: {
							color: '#868686',
						}
					}
				},]
			}
		
		],
		navigator: {
			enabled: false,
		},
		legend: {
			enabled: true, // Enable the legend
			y: -80,
			align: "center", // Optional: Position the legend (left, center, or right)
			verticalAlign: "top", // Optional: Position the legend vertically (top, middle, or bottom)
			floating: true,
		},
		tooltip: {
			useHTML: true,
			shared: true,
			formatter: function () {
				let s = `<div style="max-width: 200px; word-wrap: break-word; color: #fff; font-size: 12px;"><b>${Highcharts.dateFormat("%A, %b %e, %Y", this.x)}</b>`;
				// Loop over each point in the tooltip
				if (this.points) {
					this.points.forEach(function (point) {
						let value = point.y.toFixed(1);
						let formattedValue = value;
						// Custom formatter logic for volume values
						if (point.series.name === "Volume") {
							if (value >= 1000000) {
								formattedValue = (value / 1000000).toFixed(2) + 'M';
							} else if (value >= 1000) {
								formattedValue = (value / 1000).toFixed(2) + 'K';
							}
						}
						// Append the formatted data to the tooltip string
						s += `<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${formattedValue}</b>`;
					});
				}
				s += `</div>`;
				return s;
			},
			style: {
				color: "#fff",
				padding: "8px",
			},
			backgroundColor: "rgba(9, 10, 11, 0.85)",
			borderColor: "#868686",
			positioner: function (boxWidth, boxHeight, point) {
				// Calculate default tooltip position
				let defaultPosition = this.getPosition(boxWidth, boxHeight, point);
		
				// Adjust the y-coordinate to make the tooltip slightly lower than the default
				let adjustedYPosition = defaultPosition.y + 20; // Increase this value to move the tooltip lower
		
				// Ensure the tooltip does not go outside the chart area
				if (adjustedYPosition + boxHeight > this.chart.plotHeight + this.chart.plotTop) {
					adjustedYPosition = this.chart.plotHeight + this.chart.plotTop - boxHeight - 5; // Adjust so it stays within the chart area
				}
		
				return {
					x: defaultPosition.x, // Keep the default x-coordinate
					y: adjustedYPosition // Use the adjusted y-coordinate
				};
			}
		},
		

		scrollbar: {
			enabled: false,
		},

		rangeSelector: {
			inputEnabled: false,
			buttonPosition: {
				y: -40,
				align: "right",
			},
			labelStyle: {
				display: "none",
			},
			buttonTheme: {
				fill: "none",
				stroke: "#1C1E22",
				"stroke-width": 1,
				r: 8,
				padding: 3,
				style: {
					color: "#282828",
					fontWeight: "medium",
					fontSize: "12px",
				},
				states: {
					hover: {},
					select: {
						fill: "#090A0B",
						style: {
							color: "white",
						},
					},
				},
			},
			buttons: [
				{ type: "month", count: 1, text: "1m" },
				{ type: "month", count: 3, text: "3m" },
				{ type: "ytd", text: "YTD" },
				{ type: "all", text: "All" },
			],
			floating: true,
		},
		series: [
			{
				name: "Closing",
				id: "dataseries",
				upColor: "#05D8A5", // Color for the bullish candle (price went up)
				color: "#E53E3E", // Color for the bearish candle (price went down)
				lineColor: "#E53E3E", // Color for the wicks
				upLineColor: "#05D8A5", // Color for the wicks of the bullish candle

				data: data,
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(5, 216, 165, 0.3)"], // Start color for area
						[1, "rgba(5, 216, 165, 0)"], // End color, fades out for area
					],
				},
				tooltip: {
					valueDecimals: 2,
				},
				showInLegend: false,
				zIndex: 3,
			},

			{
				// New series for volume
				type: "column",
				name: "Volume",
				showInLegend: false,
				color: "#666666",
				opacity: 0.5,
				data: volumeData,
				
				yAxis: 1, // This assigns the series to the secondary yAxis
				// Additional configurations for volume series...
			},
			{
				type: "sma",
				linkedTo: "dataseries", // Make sure this ID matches the ID of your main series
				params: {
					period: 10, // Adjust the period according to your needs
				},
				zIndex: 1,
				color: "yellow",
				lineWidth: 1,
				visible: true,
				marker: {
					enabled: false, // Use state to control marker visibility
				},
				name: "SMA (10)", // This will be displayed in the legend
				showInLegend: true, // Ensure the series is shown in the legend
			},
			{
                // RSI Series
                type: 'rsi',
                linkedTo: 'dataseries', // Link to the main series
                params: {
                    period: 14, // Typically, a 14-period RSI is used
                },
                yAxis: 2, // Specify which yAxis the RSI should be plotted against
               
				marker: {
					enabled: false, // Use state to control marker visibility
				},
             
                lineWidth: 2,
                color: '#ff2c79', // Customize the line color
                name: 'RSI', // Name displayed in the legend
            },
			
		],
		credits: {
			enabled: false,
		},
		exporting: {
			enabled: false,
		},
	};


	
	  

	return (
		<Box
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			mb={2}
			ml={0}
			mr={0}
			minW="728px"
			height="550px"
			maxHeight="550px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			{isLoading ? (
				<Skeleton height="600px" isLoaded={true} width="00px" startColor="#050606" endColor="#090A0B" borderRadius="2xl" />
			) : (
				<Box width={"100%"} position="absolute" top={0} left={0} p={6}>
					<VStack align="flex-start" mt={1}>
						<Flex justifyContent="space-between" w="100%">
							<Box>
								<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
									{title}
									<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color="#fff" padding={3} borderRadius={"2xl"}>
										<span>
											<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
										</span>
									</Tooltip>
								</Heading>
								<Heading fontSize="14" ml={5} color="#868686" fontWeight="medium" opacity={0.87}>
									{secondTitle}
								</Heading>
							</Box>
						</Flex>
					</VStack>
					<Box mt={-6}>
						<HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} ref={chartRef} options={options} />
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default Stockchart;
