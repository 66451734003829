import { Box, Heading, Icon, Image, Modal, ModalBody, ModalContent, ModalOverlay, Tooltip, VStack, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const PicturesDisplay = ({ title, tooltip, secondTitle, isEditing, imageSource }) => {
	const white = "#fff";
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedImage, setSelectedImage] = useState(null);

	const handleClickImage = () => {
		setSelectedImage(imageSource);
		onOpen();
	};

	return (
		<Box
			borderBottomWidth="0px"
			borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
			borderLeftWidth="0.5px"
			borderRightWidth="0.5px"
			borderStyle="solid"
			borderTopWidth="0.5px"
			backgroundColor="rgba(29, 32, 35, 0.6)"
			filter="hue-rotate(0deg)"
			opacity="1"
			borderRadius="12px"
			boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
			p={6}
			
			w={"100%"}
			minWidth="464px"
			minH="200px"
			position="relative"
			overflow="hidden"
		>
			<VStack align="flex-start" mt={1} spacing={5}>
				<Box>
					<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
						{title}{" "}
						<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={white} padding={3} borderRadius={"2xl"}>
							<span>
								<Icon as={AiOutlineInfoCircle} boxSize={18} color="#434A54" ml={2} />
							</span>
						</Tooltip>
					</Heading>
					<Heading fontSize="14" ml={5} fontWeight="medium" color="#868686" opacity={0.87}>
						{secondTitle}
					</Heading>
				</Box>
				<Box mb={6} align="flex-start" display="flex" maxW="300px" maxH="500px" mx="auto" ml={5}>
					<Image
						boxShadow="dark-lg"
						src={imageSource}
						maxW="100%"
						maxH="100%"
						objectFit="contain"
						cursor="pointer"
						onClick={handleClickImage}
					></Image>
				</Box>
			</VStack>

			{/* Modal for Full-Size Image Preview */}
			<Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
				<ModalOverlay bg="blackAlpha.800" />
				<ModalContent backgroundColor="transparent" boxShadow="none">
					<ModalBody>
						<Image
							src={selectedImage}
							alt="Selected"
							maxWidth="100%" // Set the max width to 80% of the modal
							maxHeight="100%" // Set the max height to 80% of the modal
							margin="auto" // Center the image in the modal
							display="block"
							objectFit="contain"
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default PicturesDisplay;
