import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Switch,
  Text,
  VStack,
  extendTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineConsoleSql } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import TradeCard from "../../components/cards/tradecard";
import SelectChoc from "../../components/inputs/selectchoc";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import TradeFullView from "../../components/pagerelated/tradefullview";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

const customSelectStyles = {
  backgroundColor: "black",
  color: "white",
  // Add other styles or overrides as needed
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#090A0B",
        color: "white",
      },
    },
  },
});

const colorWhite = "#fff";

const Playbook = () => {
  const navigate = useNavigate();
  const [tagColor, setTagColor] = useState("pink");
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [selectedTradeId, setSelectedTradeId] = useState(null);
  const [sortedTrades, setSortedTrades] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [fetchedTrades, setFetchedTrades] = useState([]);
  const [rankedTrades, setRankedTrades] = useState([]);
  const [selectedTradeRank, setSelectedTradeRank] = useState(null);
  const [selectedTradeAvslut, setSelectedTradeAvslut] = useState(null);
  const [isOrderedByProfit, setIsOrderedByProfit] = useState(false);
  const [isOrderedBySymbol, setIsOrderedBySymbol] = useState(false);
  const [isOrderedByTag, setIsOrderedByTag] = useState(false);

  const playbookData = async () => {
    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/playbook", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const playbook = await response.json();
        //console.log("Fetched data:", playbook); // Log fetched data
        const transformedData = transformData(playbook);
        //console.log("Transformed data:", transformedData); // Log transformed data
        setFetchedTrades(transformedData);

        const rankedTrades = transformedData.sort(
          (a, b) => parseFloat(b.profit) - parseFloat(a.profit)
        );
        setRankedTrades(rankedTrades);
      }
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };

  const transformData = (data) => {
    return data
      .map((item) => {
        const trade = item.trade?.[0];
        if (!trade) {
          console.warn("Invalid trade data structure:", item);
          return null;
        }

        const trades = trade.trades?.[0];
        if (!trades) {
          console.warn("No trades array or trades data available:", trade);
          return null;
        }

        return {
          id: item._id,
          tag: item.notes,
          stock: trades.stock ?? "Unknown",
          size: trade.totalSize ? parseFloat(trade.totalSize).toFixed(2) : "0",
          profit: trade.totalProfit
            ? parseFloat(trade.totalProfit).toFixed(2)
            : "0",
          date: trades.date,
          avslut: item.avslut,
          globalNotes: item.globalNotes,
          images: item.images,
        };
      })
      .filter((item) => item !== null);
  };

  useEffect(() => {
    playbookData();
  }, []);

  useEffect(() => {
    let sorted;
    if (isOrderedByTag) {
      sorted = [...fetchedTrades].sort((a, b) => a.tag.localeCompare(b.tag));
    } else if (isOrderedByProfit) {
      sorted = [...fetchedTrades].sort(
        (a, b) => parseFloat(b.profit) - parseFloat(a.profit)
      );
    } else if (isOrderedBySymbol) {
      sorted = [...fetchedTrades].sort((a, b) =>
        a.stock.localeCompare(b.stock)
      );
    } else {
      sorted = fetchedTrades;
    }
    //console.log("Sorted trades:", sorted); // Log sorted trades
    setSortedTrades(sorted);
  }, [isOrderedByTag, isOrderedByProfit, isOrderedBySymbol, fetchedTrades]);

  const uniqueTags = [...new Set(fetchedTrades.map((trade) => trade.tag))];
  const tagOptions = uniqueTags.map((tag) => ({ value: tag, label: tag }));

  //console.log("Trades to render:", sortedTrades);

  const handleTagChange = (selectedOptions) => {
    const tags = selectedOptions.map((option) => option.value);
    //console.log("Selected tags:", tags);
    setSelectedTags(tags);
  };
  useEffect(() => {
    //console.log("Fetched Trades Set:", fetchedTrades);
    //console.log("Ranked Trades Set:", rankedTrades);
  }, [fetchedTrades, rankedTrades]);

  useEffect(() => {
    //console.log("Final Sorted Trades:", sortedTrades);
  }, [sortedTrades]);

  const removeCard = async (tradeIdToRemove) => {
    try {
      const response = await fetch(
        `https://backend-production-41c0.up.railway.app/deletePlaybook/${tradeIdToRemove}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (response.ok) {
        setFetchedTrades(
          fetchedTrades.filter((trade) => trade.id !== tradeIdToRemove)
        );
      } else {
        const errorData = await response.json();
        //console.error("Error deleting trade:", errorData.message);
      }
    } catch (error) {
      //console.error("Exception when calling delete on playbook:", error);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Flex>
        <Sidebar />

        <VStack
          flex={1}
          alignItems="flex-start"
          minWidth="max-content"
          gap="7"
          pl={50}
          mr={50}
          pt={0}
          className="custom-scrollbar"
        >
          <HStack justifyContent="space-between" w="100%" mt={9}>
            <HStack alignSelf="self-start">
              <Text alignSelf="center" fontSize="12" color="#868686">
                Fönster
              </Text>
              <Text alignSelf="center" fontSize="16" color={colorWhite}>
                / Playbook
              </Text>
            </HStack>
            <MyMarqueeComponent></MyMarqueeComponent>
            <HStack mr={5} alignItems="center" alignSelf="self-end">
              <FeedbackButton></FeedbackButton>
              <Button
                onClick={() => handleLogout(navigate)}
                fontWeight={"medium"}
              >
                Logga ut
              </Button>
            </HStack>
          </HStack>

          {selectedTrade ? (
            <TradeFullView
              trade={selectedTrade}
              playbookId={selectedTradeId}
              rank={selectedTradeRank}
              onBack={() => {
                setSelectedTrade(null);
                setSelectedTradeRank(null);
                setSelectedTradeId(null);
              }}
              tagColor={tagColor}
            />
          ) : (
            <>
              <HStack
                justifyContent="space-between"
                w="100%"
                mt={9}
                align={"center"}
              >
                <Heading>Playbook</Heading>
                <Box mt={-8}>
                  <SelectChoc
                    options={tagOptions}
                    onChange={handleTagChange}
                  ></SelectChoc>
                </Box>
                <HStack>
                  <FormLabel htmlFor="orderByTagToggle" mb="0">
                    Sortera på tag
                  </FormLabel>
                  <Switch
                    id="orderByTagToggle"
                    isChecked={isOrderedByTag}
                    sx={{
                      ".chakra-switch__track": {
                        bg: "gray.100",
                        _checked: {
                          bg: "#05d8a5", // Replace 'yourCustomColor' with your desired color
                        },
                      },
                    }}
                    onChange={() => {
                      ////console.log("Toggling Tag Sort", !isOrderedByTag); // Debug log
                      setIsOrderedByTag(!isOrderedByTag);
                    }}
                  />
                </HStack>

                <HStack>
                  <FormLabel htmlFor="orderByProfitToggle" mb="0">
                    Sortera på vinst
                  </FormLabel>
                  <Switch
                    id="orderByProfitToggle"
                    sx={{
                      ".chakra-switch__track": {
                        bg: "gray.100",
                        _checked: {
                          bg: "#05d8a5", // Replace 'yourCustomColor' with your desired color
                        },
                      },
                    }}
                    isChecked={isOrderedByProfit}
                    onChange={() => {
                      //   //console.log("Toggling Profit Sort", !isOrderedByProfit); // Debug log
                      setIsOrderedByProfit(!isOrderedByProfit);
                    }}
                  />
                </HStack>

                <HStack>
                  <FormLabel htmlFor="orderBySymbolToggle" mb="0">
                    Sortera på symbol
                  </FormLabel>
                  <Switch
                    id="orderBySymbolToggle"
                    sx={{
                      ".chakra-switch__track": {
                        bg: "gray.100",
                        _checked: {
                          bg: "#05d8a5", // Replace 'yourCustomColor' with your desired color
                        },
                      },
                    }}
                    isChecked={isOrderedBySymbol}
                    onChange={() => {
                      //  //console.log("Toggling Symbol Sort", !isOrderedBySymbol); // Debug log

                      setIsOrderedBySymbol(!isOrderedBySymbol);
                    }}
                  />
                </HStack>
              </HStack>
              <Grid templateColumns="repeat(4, 1fr)" gap={4} width="100%">
                {sortedTrades
                  .filter(
                    (trade) =>
                      selectedTags.length === 0 ||
                      selectedTags.includes(trade.tag)
                  )
                  .map((trade, index) => {
                    // Find the rank of the current trade in the rankedTrades array
                    return (
                      <TradeCard
                        key={trade.id}
                        tag={trade.tag}
                        date={trade.date}
                        stock={trade.stock}
                        onRemove={() => removeCard(trade.id)}
                        profit={trade.profit}
                        text={"Här ska specifik vara"}
                        onTagColorChange={(color) => setTagColor(color)}
                        size={trade.size}
                        onClick={() => {
                          setSelectedTrade(trade);
                          setSelectedTradeId(trade.id);
                          // Calculate and set the rank of the selected trade
                          const rank =
                            rankedTrades.findIndex(
                              (t) =>
                                t.stock === trade.stock && t.date === trade.date
                            ) + 1;
                          setSelectedTradeRank(rank);
                        }}
                      />
                    );
                  })}
              </Grid>
            </>
          )}

          <Footer></Footer>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default Playbook;
