import { Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

function TermsModal({ isOpen, onClose, onAccept }) {
	const [isChecked, setIsChecked] = useState(false);
	const modalContentRef = useRef(null);

	useEffect(() => {
		if (isOpen && modalContentRef.current) {
			// Scroll to the top of the modal content when it becomes visible
			modalContentRef.current.scrollTop = 0;
		}
	}, [isOpen]);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleAccept = () => {
		if (isChecked) {
			// Perform the acceptance action here
			onAccept();
		} else {
			// Display an error message or handle the case where the checkbox is not checked
		}
	};
	return (
		<Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropFilter="blur(3px) " />
			<ModalContent maxH="80vh" overflowY="auto" minW="700px" background="#090A0B" ref={modalContentRef}>
				<ModalHeader></ModalHeader>
				<ModalBody>
					<Heading fontSize={24} mb={5} color="#05D8A5">
						Användarvillkor
					</Heading>
					<Text fontSize="14px" color="#868686" opacity={0.87}>
						<strong>Allmänt</strong>
						<br />
						Materialet på denna hemsida tillhandahålls av Tradalyze AB. Genom att besöka denna hemsida, lämnar du ditt samtycke till nedanstående
						villkor. Om du inte accepterar villkoren bör du inte använda dig av hemsidan samt avstå från att ladda ner någon information eller något
						material från den. Tradalyze tillåter att du besöker bolagets hemsida via din dator eller skriver ut kopior av utdrag från dessa sidor
						enbart för ditt personliga bruk och inte för spridning om inte detta först skriftligen godkänts av bolaget. Tillgång till enskilda
						dokument på Tradalyze hemsida kan vara föremål för ytterligare villkor som anges i dessa enskilda dokument. Användandet av denna hemsida
						och dess innehåll är tillåtet i privat, icke-kommersiellt syfte. Pressmeddelande och andra dokument som klassificeras som offentliga kan
						användas för offentlig kommunikation.
						<br />
						<br />
						<strong>Frånsägande av ansvar</strong>
						<br />
						All information på denna webbplats är endast för underhållnings- och utbildningssyften. Även om informationen anses vara korrekt bör ingen
						av uppgifterna på denna webbplats betraktas som helt pålitlig vid verkliga investeringsbeslut. Tradalyze är en distributör (och inte en
						utgivare) av innehåll som tillhandahålls av tredje parter. Ingen av informationen på denna webbplats garanteras vara korrekt, komplett,
						användbar eller aktuell. Tradalyze och dess tredje parts leverantörer garanterar inte eller säkerställer sådan noggrannhet,
						fullständighet, användbarhet eller aktualitet. Ingen av informationen på hemsidan skall heller ses som någon form av finansiell
						rådgivning. Tradalyze kan heller inte garantera att informationen är korrekt gällande exempelvis skrivfel, yttre påverkan och tekniska fel
						resultera i missledande information. Bolaget garanterar inte att anslutningen till hemsidan är avbrottsfri eller felfri. Tradalyze
						förbehåller sig rätten att när som helst revidera hemsidan eller ta bort tillgången till den.
						<br />
						<br />
						Allt som laddas ner från hemsidan, eller någon annan hemsida som du länkats vidare till, sker efter eget gottfinnande och på egen risk. Du
						är ensam ansvarig för eventuell åverkan på ditt datasystem eller förlust av data som orsakats av en nedladdning.
						<br />
						<br />
						<strong>Användarens skyldigheter</strong>
						<br />
						Som användare garanterar och utfäster du, att du kommer använda denna hemsida på ett sätt som är förenligt med samtliga regler och
						bestämmelser i dessa villkor och följa alla befintliga och framtida uppdaterade riktlinjer och regler för hemsidan.
						<br />
						Du godkänner att inte använda hemsidan för att:
						<br />
						<ul>
							<br />
							<li>sprida spam eller oönskad e-post,</li>
							<li>utge dig för att representera Tradalyze eller vara någon annan,</li>
							<li>förfalska rubriker eller manipulera innehållet på hemsidan för att dölja dess ursprung,</li>
							<li>lämna falska uppgifter om din anknytning till en person eller enhet,</li>
							<li>agera på ett sätt som inverkar negativt på andras möjlighet att använda hemsidan,</li>
							<li>deltar i aktiviteter som förbjuds av gällande lagstiftning,</li>
							<li>
								skicka in material som kränker eller gör intrång i andras rättigheter, eller som är olagligt, kränkande, nedsättande, vulgärt eller på
								annat sätt anstötligt, eller som innehåller reklam eller erbjudanden om produkter eller tjänster, eller
							</li>
							<li>
								samla in eller spara en annan användares personuppgifter om du inte erhållit uttryckligt medgivande från användaren och Tradalyze.
							</li>
						</ul>
						Alla som använder hemsidan måste följa dessa villkor.
					</Text>
					<Heading fontSize={24} mb={5} mt={5} color="#05D8A5">
						Integritetspolicy
					</Heading>
					<Text fontSize="14" maxWidth="800px" color="#868686" opacity={0.87}>
						<strong>Denna integritetspolicy beskriver hur Tradalyze AB hanterar personlig information. </strong>
						<br />
						Tradalyze ("vi", "oss" eller "vår") driver www.tradalyze.com
						<br />
						Denna sida informerar dig om våra riktlinjer för insamling, användning och delning av personlig data när du använder vår tjänst samt de
						valmöjligheter du har i samband med denna information. Genom att använda tjänsten samtycker du till att vi samlar in och använder
						information enligt denna policy. Denna integritetspolicy gäller endast information som samlas in via denna webbplats. Den kommer att
						meddela dig följande:
						<br />
						<br />
						Vilken personligt identifierbar information som samlas in från dig via webbplatsen, hur den används och med vilka den kan delas. Vilka
						valmöjligheter som finns tillgängliga för dig angående användningen av din data. De säkerhetsåtgärder som vidtas för att skydda mot
						missbruk av din information. Hur du kan korrigera eventuella felaktigheter i informationen.
						<br />
						<br />
						<strong>Insamling, användning och delning av information</strong>
						<br />
						Vi är ensamma ägare av informationen som samlas in på denna webbplats. Vi har endast tillgång till och samlar in information som du
						frivilligt ger oss när du använder webbplatsen. Vi kommer inte att sälja eller hyra ut denna information till någon. Vi använder din
						information för att tillhandahålla tjänsterna på webbplatsen. Vi kommer inte att dela din information med någon tredje part utanför vår
						organisation, förutom när det är nödvändigt för att uppfylla din begäran. Specifikt: (Detta är om vi kör det med att dela affärer) Om du
						uttryckligen delar en affär offentligt kommer viss information om din handel att vara tillgänglig på webbplatsen (du kan se exempel genom
						att titta på andra delade affärer på webbplatsen). Om du inte vill att denna information ska vara offentlig, vänligen dela inte affärer.
						Om du inte ber oss att inte göra det kan vi i framtiden kontakta dig via e-post för att informera dig om erbjudanden, nya produkter eller
						tjänster, eller förändringar i denna integritetspolicy. Vi samlar in statistisk information om webbtrafik med hjälp av en tredjeparts
						webbanalystjänst; detta kan använda en cookie på din dator, men ingen användaridentifierbar information delas någonsin med denna tredje
						part utöver "vanlig" webbanalys som IP-adress, webbläsare, etc. I inget fall kommer dina individuella data att göras tillgängliga för
						någon, och ingen personligt identifierbar information kommer att göras tillgänglig eller associeras med någon av datan.
						<br />
						<br />
						<strong>Åtkomst till och kontroll över din information</strong>
						<br />
						Du kan när som helst välja att inte få framtida kontakter från oss. Du kan göra följande när som helst genom att kontakta oss via e-post
						på tradalyze@gmail.com, och begära att vi tar bort alla data vi har om dig eller uttrycka eventuella farhågor du har angående vår
						användning av din data.
						<br />
						<br />
						<strong>Säkerhet</strong>
						<br />
						Vi vidtar försiktighetsåtgärder för att skydda din information. När du skickar känslig information via webbplatsen, skyddas din
						information både online och offline. Varje gång vi samlar in känslig information (som din handelsdata) krypteras denna information och
						skickas till oss på ett säkert sätt. Om du känner att vi inte följer denna integritetspolicy, vänligen kontakta oss omedelbart via e-post
						på tradalyze@gmail.com.
					</Text>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default TermsModal;
