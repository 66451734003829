import {
	Button,
	ChakraProvider,
	Flex,
	HStack,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	VStack,
	extendTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

import Papa from "papaparse";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Screener = () => {
	const navigate = useNavigate();
	const [csvData, setCsvData] = useState([]);
	const [csvHeaders, setCsvHeaders] = useState([]); // State variable to hold CSV headers
	const [sortOrder, setSortOrder] = useState({}); // State variable to hold sorting order for each column
	const [tickerModalOpen, setTickerModalOpen] = useState(false); // State variable to manage modal open/close
	const [selectedTickers, setSelectedTickers] = useState([]); // State variable to store selected tickers

	useEffect(() => {
		// Fetch the CSV file from GitHub
		fetch("https://raw.githubusercontent.com/skyte/rs-log/main/output/rs_industries.csv")
			.then((response) => response.text())
			.then((data) => {
				// Parse the CSV data using papaparse
				Papa.parse(data, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
						setCsvHeaders(result.meta.fields); // Set the CSV headers in the state
						setCsvData(result.data);
					},
					error: (error) => {
						//console.error("Error parsing CSV:", error);
					},
				});
			})
			.catch((error) => {
				//console.error("Error fetching CSV:", error);
			});
	}, []);

	// Function to toggle sorting order for a column
	// Function to toggle sorting order for a column
	const toggleSortOrder = (column) => {
		setSortOrder((prevSortOrder) => ({
			[column]: prevSortOrder[column] === "asc" ? "desc" : "asc",
		}));
	};

	// Function to check if a column is currently being sorted
	const isColumnSorted = (column) => {
		return sortOrder[column] !== undefined;
	};

	// Function to clear sorting order for all columns except the one being sorted
	const clearSortOrder = (column) => {
		const newSortOrder = {};
		newSortOrder[column] = sortOrder[column];
		setSortOrder(newSortOrder);
	};

	// Function to sort data based on column and sorting order
	const sortedData = () => {
		const sortedColumn = Object.keys(sortOrder)[0]; // Get the currently sorted column
		if (!sortedColumn) return csvData;

		return csvData.slice().sort((a, b) => {
			const order = sortOrder[sortedColumn];
			let valueA = a[sortedColumn] || "";
			let valueB = b[sortedColumn] || "";

			// Convert the values to numbers if the column is the first column
			if (sortedColumn === csvHeaders[0]) {
				valueA = parseFloat(valueA);
				valueB = parseFloat(valueB);
			}

			const comparison = valueA - valueB; // Numeric comparison

			return order === "asc" ? comparison : -comparison;
		});
	};

	const openTickerModal = (tickers) => {
		setSelectedTickers(tickers.split(",")); // Assuming tickers are comma-separated
		setTickerModalOpen(true);
	};
	const cleanAndTransformValue = (value) => {
		// Remove special characters like "â€”" and replace them with an empty string
		return value.replace(/[^\w\s]/g, " ");
	};
	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={50} mr={50} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Screener
							</Text>
						</HStack>
						<MyMarqueeComponent></MyMarqueeComponent>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<HStack align={"center"} w="auto" spacing={20}>
						{" "}
						<Heading fontSize={24} color={"#05D8A5"}>
							USA RS Screener
						</Heading>
						<Tooltip
							placement="auto-start"
							gutter={20}
							bg="#090A0B"
							padding={3}
							borderRadius={"2xl"}
							label="Snart kommmer du kunna screena fram din handelstatistik. Sortera efter strategier, vinster, modeller, algoritmer. Tillsvidare slänger vi in en USA RS Screener som personligen används flitigt //Filip. "
							hasArrow
						>
							<Button
								background="none"
								_hover={{
									backgroundColor: "rgba(29, 32, 35, 0.8)",
								}}
								color="#fff"
								backgroundColor="rgba(29, 32, 35, 0.6)"
								textDecor={""}
								filter="hue-rotate(0deg)"
								opacity="1"
								boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
								size="md"
							>
								Screena din handelstatistik
							</Button>
						</Tooltip>
					</HStack>

					<VStack align={"flex-start"} w="100%" spacing={5}>
						<TableContainer w="100%">
							<Table variant="striped" colorScheme="blackAlpha">
								<Thead>
									<Tr>
										{/* Render the CSV headers in <Th> elements with sorting functionality */}
										{csvHeaders.map((header, index) => (
											<Th   color={"#868686"} key={index} onClick={() => toggleSortOrder(header)}>
												{header}
												{sortOrder[header] === "asc" && <span>&uarr;</span>}
												{sortOrder[header] === "desc" && <span>&darr;</span>}
											</Th>
										))}
									</Tr>
								</Thead>
								<Tbody>
									{/* Render the sorted CSV data here */}
									{sortedData().map((rowData, rowIndex) => (
										<Tr key={rowIndex}>
											{csvHeaders.map((header, colIndex) => (
												<Td fontWeight={"medium"} py={2} key={colIndex}>
													{colIndex === csvHeaders.length - 1 ? (
														<Button
															borderBottomWidth="0px"
															backgroundColor={"rgba(29, 32, 35, 0.6)"}
															filter="hue-rotate(0deg)"
															opacity="1"
															color={"white"}
															boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
															ml={8}
															size={"sm"}
															fontWeight={"medium"}
															_hover={{
																backgroundColor: "rgba(29, 32, 35, 0.9)",
															}}
															onClick={() => openTickerModal(rowData[header])}
														>
															Se Tickers
														</Button>
													) : colIndex === 3 ? (
														rowData[header].split(".")[0] // Keep only the integer part for the fourth column
													) : (
														cleanAndTransformValue(rowData[header]) // Apply existing transformation for other columns
													)}
												</Td>
											))}
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</VStack>

					<Footer></Footer>
				</VStack>
			</Flex>

			{/* Ticker Modal */}
			<Modal isOpen={tickerModalOpen} onClose={() => setTickerModalOpen(false)}>
				<ModalOverlay />
				<ModalContent
					borderBottomWidth="0px"
					borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
					borderLeftWidth="0.5px"
					borderRightWidth="0.5px"
					borderStyle="solid"
					borderTopWidth="0.5px"
					overflowX="hidden"
					backgroundColor="#090A0B"
					filter="hue-rotate(0deg)"
					opacity="1"
					borderRadius="12px"
					boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
				>
					<ModalHeader>Tickers Inkluderade</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{selectedTickers.map((ticker, index) => (
							<div key={index}>{ticker}</div>
						))}
					</ModalBody>
					<ModalFooter>
						<Button
							borderBottomWidth="0px"
							backgroundColor={"rgba(29, 32, 35, 0.6)"}
							filter="hue-rotate(0deg)"
							opacity="1"
							color={"white"}
							boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
							ml={8}
							size={"sm"}
							fontWeight={"medium"}
							_hover={{
								backgroundColor: "rgba(29, 32, 35, 0.9)",
							}}
							onClick={() => setTickerModalOpen(false)}
						>
							Stäng
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</ChakraProvider>
	);
};

export default Screener;
