import {
  Badge,
  Box,
  HStack,
  Icon,
  Button as Knapp,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getLocalTimeZone, isWeekend, today } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import React, { useEffect, useState } from "react";
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Group,
  Heading,
} from "react-aria-components";
import { IoMdJournal } from "react-icons/io";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import styles from "../calendarbig/adobeCalendar.css";

const CalendarView = (props) => {
  const colorWhite = "#fff";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const disabledRanges = [];
  const [selectedDay, setSelectedDay] = useState(today(getLocalTimeZone()));

  const defaultDate = today(getLocalTimeZone());
  const [focusedDate, setFocusedDate] = useState(defaultDate);

  const newDate = new Date(
    selectedDay.year,
    selectedDay.month - 1,
    selectedDay.day
  );

  const formateraDatumTillISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isoString = selectedDay
    ? formateraDatumTillISO(
        new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day)
      )
    : defaultDateISO;

  const [journalData, setJournalData] = useState([]);

  const [klumpadData, setKlumpadData] = useState([]);

  const calenderData = async () => {
    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/calender", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const profitData = await response.json();

        // //console.log(profitData);

        const klumpad = {};

        profitData.profits.forEach((item) => {
          const date = item.Datum.split("T")[0];

          if (!klumpad[date]) {
            klumpad[date] = { Profit: 0, Antal: 0 };
          }

          klumpad[date].Profit += item.Profit;
          klumpad[date].Antal += 1;
        });
        setJournalData(profitData.journaler);
        setKlumpadData(klumpad);
        ////console.log(klumpadData);
      }
    } catch (error) {
      //console.error("Fel vid hämtning av data:", error);
    }
  };
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(focusedDate.year, focusedDate.month - 1)
  );

  const calculateTotalMonthlyProfit = () => {
    let totalProfit = 0;
    if (klumpadData) {
      Object.keys(klumpadData).forEach((date) => {
        const [year, month] = date.split("-").map(Number);
        const dateObj = new Date(year, month - 1);
        if (
          dateObj.getFullYear() === selectedMonth.getFullYear() &&
          dateObj.getMonth() === selectedMonth.getMonth()
        ) {
          totalProfit += klumpadData[date].Profit;
        }
      });
    }
    return totalProfit.toFixed(1); // Formatera till två decimaler
  };
  const totalMonthlyProfit = calculateTotalMonthlyProfit();
  const isProfitNegative = parseFloat(totalMonthlyProfit) < 0;

  const calculateTotalMonthlyAntal = () => {
    let totalAntal = 0;
    if (klumpadData) {
      Object.keys(klumpadData).forEach((date) => {
        const [year, month] = date.split("-").map(Number);
        const dateObj = new Date(year, month - 1);
        if (
          dateObj.getFullYear() === selectedMonth.getFullYear() &&
          dateObj.getMonth() === selectedMonth.getMonth()
        ) {
          totalAntal += klumpadData[date].Antal;
        }
      });
    }
    return totalAntal; // Ingen decimalbehov för antal
  };

  const calculateTotalMonthlyJournals = () => {
    const journalDates = new Set(); // Använd en Set för att lagra unika datum
    journalData?.forEach((journal) => {
      const [year, month, day] = journal.split("-").map(Number);
      const journalDate = new Date(year, month - 1, day);
      const dayOfWeek = journalDate.getDay();

      // Kontrollera om datumet är inom den valda månaden och inte på en helg
      if (
        journalDate.getFullYear() === selectedMonth.getFullYear() &&
        journalDate.getMonth() === selectedMonth.getMonth() &&
        dayOfWeek !== 0 &&
        dayOfWeek !== 6
      ) {
        journalDates.add(journal); // Lägg till unikt datum i Set om det inte är en helg
      }
    });
    return journalDates.size; // Antalet unika datum (exklusive helger) representerar antalet journaler
  };

  const fetchJournalData = async (selectedDate) => {
    try {
      ////console.log("Fetching data for date:", selectedDate);

      const response = await fetch(
        `https://backend-production-41c0.up.railway.app/stats/${selectedDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${document.cookie.replace("token=", "")}`,
          },
          credentials: "include",
        }
      );

      ////console.log("Response status:", response.status);
      ////console.log("Response headers:", [...response.headers.entries()]);

      if (response.ok) {
        const data = await response.json();
        ////console.log(data);
        //hantera journal här
        ////console.log("jorunarelel: ", data.journal.afterToday);
        props.onJournalDataFetched(data);
        ////console.log("props körs");
      } else {
        //console.error("Response not ok, status:", response.status);
        const errorResponse = await response.text();
        //console.error(errorResponse);
      }
    } catch (error) {
      //console.error("Ett fel inträffade när journaldatan skulle hämtas.", error);
    }
  };
  useEffect(() => {
    calenderData();
  }, []);

  useEffect(() => {
    if (klumpadData) {
      ////console.log("Trades:", klumpadData);
    }
  }, [klumpadData]);

  const handleDateSelection = (date) => {
    ////console.log("Datum valt i kalender:", date);
    setSelectedDay(date);
    setFocusedDate(date);
  };

  const localeData = useLocale();
  const locale = localeData.locale;

  const isDateUnavailable = (date) =>
    isWeekend(date, locale) ||
    disabledRanges.some(
      (interval) =>
        date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0
    );

  const renderSaveCSV = () => (
    <Knapp backgroundColor="#d53f8c" color="#FFFFFF" onClick={handleSave}>
      Spara
    </Knapp>
  );

  const handleSave = () => {
    // //console.log("Valt datum:", isoString);
    fetchJournalData(isoString);
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const toast = useToast();
  const defaultDateISO = formateraDatumTillISO(
    new Date(defaultDate.year, defaultDate.month - 1, defaultDate.day)
  );
  const updateSelectedMonth = (newDate) => {
    setSelectedMonth(new Date(newDate.year, newDate.month - 1));
  };

  const handlePreviousMonthClick = () => {
    const newDate = focusedDate.add({ months: -1 });
    setFocusedDate(newDate);
    setSelectedMonth(new Date(newDate.year, newDate.month - 1));
    //console.log("Navigerar till föregående månad:", newDate);
  };

  // Funktion för att hantera navigering till nästa månad
  const handleNextMonthClick = () => {
    const newDate = focusedDate.add({ months: 1 });
    setFocusedDate(newDate);
    setSelectedMonth(new Date(newDate.year, newDate.month - 1));
    //console.log("Navigerar till nästa månad:", newDate);
  };

  const incrementDate = () => {
    if (selectedDay) {
      const nextDay = selectedDay.add({ days: 1 });
      updateDate(nextDay);
      updateSelectedMonth(nextDay);
    }
  };

  const decrementDate = () => {
    if (selectedDay) {
      const previousDay = selectedDay.add({ days: -1 });
      updateDate(previousDay);
      updateSelectedMonth(previousDay);
    }
  };

  const updateDate = (newDate) => {
    setSelectedDay(newDate);
    setFocusedDate(newDate);
    const newISOString = formateraDatumTillISO(
      new Date(newDate.year, newDate.month - 1, newDate.day)
    );
    fetchJournalData(newISOString);
  };

  return (
    <Box>
      <HStack>
        <Knapp
          onClick={decrementDate}
          backgroundColor={"transparent"}
          _hover={{
            backgroundColor: "transparent",
          }}
        >
          <Icon
            boxSize={10}
            _hover={{ color: "#05d8a5" }}
            as={MdNavigateBefore}
          />
        </Knapp>
        <Knapp
          background="none"
          color={colorWhite}
          gap={4}
          onClick={onOpen}
          size="md"
          _hover={{
            borderBottomWidth: "0px",
            backgroundColor: "rgba(29, 32, 35, 0.6)",
            filter: "hue-rotate(0deg)",
            opacity: "1",
            boxShadow: "rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset",
          }}
        >
          <HStack mt={3}>
            <Text>Valt Datum:</Text>
            <Text color="#05d8a5">
              {isoString.includes("NaN") ? defaultDateISO : isoString}
            </Text>
          </HStack>
        </Knapp>
        <Knapp
          onClick={incrementDate}
          backgroundColor={"transparent"}
          _hover={{
            backgroundColor: "transparent",
          }}
        >
          <Icon
            boxSize={10}
            _hover={{ color: "#05d8a5" }}
            as={MdNavigateNext}
          />
        </Knapp>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderBottomWidth="0px"
          borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
          borderLeftWidth="0.5px"
          borderRightWidth="0.5px"
          borderStyle="solid"
          borderTopWidth="0.5px"
          overflowX="hidden"
          minW="1200px"
          minH="620px"
          backgroundColor="#090A0B"
          filter="hue-rotate(0deg)"
          opacity="1"
          borderRadius="12px"
          boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
        >
          <ModalHeader>
            <HStack mt={2}></HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody with>
            <Calendar
              isDateUnavailable={isDateUnavailable}
              aria-label="Appointment date"
              className={styles["react-aria-Calendar-two"]}
              onChange={handleDateSelection}
              defaultValue={focusedDate}
            >
              <Group>
                <Button slot="previous" onClick={handlePreviousMonthClick}>
                  <Icon as={MdNavigateBefore} />
                </Button>
                <Heading />
                <Button slot="next" onClick={handleNextMonthClick}>
                  <Icon as={MdNavigateNext} />
                </Button>
              </Group>

              <HStack justifyContent="center">
                <CalendarGrid>
                  <CalendarGridHeader>
                    {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
                  </CalendarGridHeader>
                  <CalendarGridBody>
                    {(date) => {
                      const dateISO = formateraDatumTillISO(
                        new Date(date.year, date.month - 1, date.day)
                      );
                      const isJournalDate = journalData.includes(dateISO);

                      const journalIconColor = isJournalDate
                        ? "orange"
                        : "#282828";
                      const profit =
                        klumpadData && klumpadData[dateISO]
                          ? klumpadData[dateISO].Profit
                          : 0;
                      const antal =
                        klumpadData && klumpadData[dateISO]
                          ? klumpadData[dateISO].Antal
                          : 0;
                      // Determine the color based on the profit value

                      // Determine if the date is unavailable
                      const isUnavailable = isDateUnavailable(date);

                      // Initialize variables for profit color and left bar class
                      let profitColor, leftBarClass;
                      if (isUnavailable) {
                        leftBarClass = "rectangle-unavailable";
                        profitColor = "#868686"; // Gray color for unavailable dates
                      } else {
                        // For available dates, determine color and class based on profit
                        const profit =
                          klumpadData && klumpadData[dateISO]
                            ? klumpadData[dateISO].Profit
                            : 0;

                        if (profit > 0) {
                          profitColor = "#05d8a5"; // Green for positive profit
                          leftBarClass = "rectangle-positive";
                        } else if (profit < 0) {
                          profitColor = "#E53E3E"; // Red for negative profit
                          leftBarClass = "rectangle-negative";
                        } else {
                          profitColor = "#868686"; // Gray for zero profit
                          leftBarClass = "rectangle-zero";
                        }
                      }
                      let rectangleClass;
                      let valueStyle = {
                        fontWeight: "normal",
                        fontSize: "smaller",
                        color: profitColor,
                      };
                      if (isDateUnavailable(date)) {
                        rectangleClass = "rectangle-unavailable";
                      } else {
                        rectangleClass =
                          profit > 0
                            ? "rectangle-positive"
                            : "rectangle-negative";
                      }

                      return (
                        <CalendarCell
                          date={date}
                          onClick={() => {
                            // //console.log("CalendarCell clicked!");
                          }}
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* Render the appropriate rectangle based on the date's availability */}
                          <div className={leftBarClass}></div>

                          {/* Render the date, profit value and trade count */}
                          <div className="date-value-container">
                            <VStack>
                              <span style={{ marginRight: "8px" }}>
                                {date.day}
                              </span>
                              {!isDateUnavailable(date) && (
                                <div>
                                  <Icon
                                    position={"absolute"}
                                    bottom={1}
                                    left={4}
                                    as={IoMdJournal}
                                    boxSize={3}
                                    color={isJournalDate ? "orange" : "#282828"} // Apply the dynamic color here
                                  ></Icon>
                                </div>
                              )}
                            </VStack>
                            {/* Only render the profit and trade count if the date is available */}
                            {!isDateUnavailable(date) && (
                              <>
                                <VStack>
                                  <HStack>
                                    <div style={{ fontSize: "smaller" }}>
                                      Avslut: {antal}
                                    </div>
                                  </HStack>
                                  <div style={valueStyle}>
                                    {profit.toFixed(1)} kr
                                  </div>
                                </VStack>
                              </>
                            )}
                          </div>
                        </CalendarCell>
                      );
                    }}
                  </CalendarGridBody>
                </CalendarGrid>
              </HStack>
            </Calendar>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={14} mb={4}>
              <Group>
                <Badge
                  p={1}
                  pl={2}
                  pr={2}
                  fontSize={12}
                  background={
                    isProfitNegative
                      ? "rgba(229, 62, 62, 0.15)"
                      : "rgba(5, 216, 165, 0.15)"
                  }
                  textColor={isProfitNegative ? "#E53E3E" : "#05D8A5"}
                  textTransform={"none"}
                  borderRadius={"md"}
                >
                  Total Profit: {calculateTotalMonthlyProfit()} kr
                </Badge>
                <Badge
                  p={1}
                  pl={2}
                  pr={2}
                  fontSize={12}
                  background="rgba(5, 216, 165, 0.15)"
                  textColor="#05D8A5"
                  textTransform={"none"}
                  borderRadius={"md"}
                >
                  Total Avslut: {calculateTotalMonthlyAntal()}
                </Badge>
                <Badge
                  p={1}
                  pl={2}
                  pr={2}
                  fontSize={12}
                  background="rgb(255,165,0,0.15)"
                  textColor="#ffa500"
                  textTransform={"none"}
                  borderRadius={"md"}
                >
                  Skrivna Journaler: {calculateTotalMonthlyJournals()}
                </Badge>
              </Group>
              {renderSaveCSV()}{" "}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CalendarView;
