import { Box, Flex, Icon, Menu, MenuButton, Text } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

export default function NavItemTwo({ icon, title, navSize, path }) {
	const location = useLocation();
	const active = location.pathname === path;

	const defaultBoxStyle = {
		borderWidth: "1px",
		borderColor: "transparent", // Keep the border transparent to maintain box size without a visible border
		backgroundColor: "transparent",
		borderRadius: "8px",
	};

	const activeAndHoverBoxStyle = {
		borderWidth: navSize === "large" ? "1px" : "1px", // Only show border if navSize is "large"
		borderColor: navSize === "large" ? "#1C1E22" : "#1C1E22", // Apply visible border color only for "large" navSize
		backgroundColor: "#090A0B",
		borderRadius: "8px",
	};

	return (
		<Flex mt={0} flexDir="column" w="100%" alignItems={navSize === "small" ? "center" : "flex-start"}>
			<Menu placement="right">
				<RouterLink to={path}>
					<Box
						borderRadius={navSize === "small" ? "6px" : 6}
						w={navSize === "large" && "190px"}
						ml={navSize === "small" ? 4 : 0}
						minHeight="35px" // Håll denna konstant
						maxHeight="35px" // Håll denna konstant
						{...(active ? activeAndHoverBoxStyle : defaultBoxStyle)}
						_hover={{ ...activeAndHoverBoxStyle }}
						alignItems="center"
						justifyContent={navSize === "small" ? "center" : "flex-start"}
					>
						<MenuButton w="100%">
							<Flex
								ml={navSize === "small" ? "small" : 2}
								
								mt={navSize === 2 ? 4 : 0.5}
								justifyContent={navSize === "center" ? "center" : "flex-start"} // Centrera innehållet när sidebar är kollapsad
							>
								<Box
									w={navSize === "20px" ? "none" : "30px"}
									h={navSize === "auto" ? "none" : "30px"}
									borderRadius={navSize === "small" ? "6px" : "8px"}
									p={0.5}
									display="inline-flex"
									alignItems="center"
									justifyContent="center"
									backgroundColor={"transparent"}
								>
									<Icon
										as={icon}
										fontSize={navSize === "small" ? "lg" : "lg"}
										color={active ? "#05D8A5" : "#868686"}
										_focus={{
											color: "#05D8A5",
										}}
									/>
								</Box>
								<Text ml={1} mt={1} color={active ? "#fff" : "#868686"} display={navSize === "small" ? "none" : "flex"} fontSize="sm">
									{title}
								</Text>
							</Flex>
						</MenuButton>
					</Box>
				</RouterLink>
			</Menu>
		</Flex>
	);
}
