export const handleLogout = async (navigateFunction) => {
  try {
    const response = await fetch("https://backend-production-41c0.up.railway.app/logout", {
      method: "POST",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Logout failed");
    }

    navigateFunction("/login");
  } catch (error) {
    //console.error("An error occurred during logout", error);
  }
};
