import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const ImageUpload = ({ isEditing = true, onImagesChange }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileUpload = async (event) => {
    if (isEditing) {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        uploadImageToServer(file);
      });

      const filePreviews = files.map((file) => ({
        file,
        name: file.name,
        dataUrl: URL.createObjectURL(file),
      }));

      setUploadedFiles((prev) => {
        const updatedFiles = [...prev, ...filePreviews];
        onImagesChange(updatedFiles);
        return updatedFiles;
      });
    }
  };

  const uploadImageToServer = async (imageFile) => {
    const formData = new FormData();
    formData.append("image", imageFile);

    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/newpage/image", {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Bild uppladdad:", responseData);
      } else {
        throw new Error("Servern svarade med felstatus: " + response.status);
      }
    } catch (error) {
      console.error("Ett fel uppstod när bilden skulle laddas upp:", error);
    }
  };

  useEffect(() => {
    const savedFiles = JSON.parse(localStorage.getItem("uploadedFiles")) || [];
    setUploadedFiles(savedFiles);
  }, []);

  useEffect(() => {
    localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
  }, [uploadedFiles]);

  const handleClickImage = (image) => {
    setSelectedImage(image);
    onOpen();
  };

  const handleRemoveImage = (index) => {
    setUploadedFiles((prev) => {
      const updatedFiles = prev.filter((_, idx) => idx !== index);
      onImagesChange(updatedFiles);
      return updatedFiles;
    });
  };

  return (
    <VStack alignItems="center">
      <input
        type="file"
        onChange={handleFileUpload}
        multiple
        style={{ display: "none" }}
        id="upload-button"
        disabled={!isEditing}
      />
      <label htmlFor="upload-button">
        <Button as="span" disabled={!isEditing}>
          Bifoga Screenshots
        </Button>
      </label>

      {/* Image Previews in a Horizontal Row */}
      <HStack mt={10} spacing={4} position="relative" role="group">
        {uploadedFiles.map(({ name, dataUrl }, index) => (
          <Box key={index} position="relative">
            <Image
              boxShadow="2xl"
              src={dataUrl}
              alt={name}
              height="150px"
              cursor="pointer"
              objectFit="contain"
              onClick={() => handleClickImage(dataUrl)}
            />
            <Box
              position="absolute"
              top="0"
              right="2"
              p="2"
              bg="transparent"
              borderRadius="full"
              cursor="pointer"
              onClick={() => handleRemoveImage(index)}
              display="none"
              _groupHover={{ display: "block" }}
            >
              <CloseIcon boxSize={2} color="white" />
            </Box>
          </Box>
        ))}
      </HStack>

      {/* Modal for Full-Size Image */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent backgroundColor="transparent" boxShadow="none">
          <ModalBody>
            <div>
              <Image
                src={selectedImage}
                alt="Selected"
                maxWidth="100%" // Set the max width to 80% of the modal
                maxHeight="100%" // Set the max height to 80% of the modal
                margin="auto" // Center the image in the modal
                display="block"
                objectFit="contain"
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ImageUpload;
