import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Spacer } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { IoMdStats } from "react-icons/io";
import { LuArrowDownWideNarrow, LuBookOpen, LuLayoutDashboard, LuLibrary, LuNewspaper, LuPlus } from "react-icons/lu";
import { MdQueryStats } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MyIcon } from "./sidebarlogo.svg";
import NavItemTwo from "./NavItemTwo";
import AccountBar from "./account";
import ComingSoon from "./comingsoon";
import { ReactComponent as MyIconTwo } from "./sidelogo.svg";
export default function Sidebar() {
	const [navSize, changeNavSize] = useState(localStorage.getItem("navSize") || "large");
	const navigate = useNavigate();
	const colorWhite = "#fff";
	const location = useLocation();
	const [activeButton, setActiveButton] = useState(null);
	const boxStyle = {
		backgroundColor: location.pathname === "/stats" ? "#1C1E22" : "transparent",
		transition: "background-color 0.3s ease-out",
		_hover: {
			backgroundColor: "#1C1E22",
		},
	};
	useEffect(() => {
        localStorage.setItem("navSize", navSize);
    }, [navSize]);

  
	const toggleNavSize = () => {
        changeNavSize(prevSize => (prevSize === "small" ? "large" : "small"));
    };

	return (
		<Flex
			pos="sticky"
			top="0"
			left="0"
			pr="5"
			h="100vh"
			backgroundColor={"#050606"}
			borderRight="1px solid #1C1E22"
			boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
			w={navSize === "small" ? "75px" : "240px"}
			flexDir="column"
			zIndex="10"
			justifyContent="flex-start"
		>
			<Box ml={5} mt={5} mr={5} flex="1" display="flex" flexDirection="column">
				<Box ml="0px" onClick={() => navigate("/dashboard")} cursor={"pointer"} mt={4}>
					{navSize === "small" ? (
						<Box ml={0.5}>
							<MyIconTwo width="300%" height="300%" />
						</Box>
					) : (
						<MyIcon width="100%" height="100%" /> // Ensure consistency in size
					)}
				</Box>

				<Flex mt={10} p="0" flexDir="column" w="100%" alignItems={navSize === "small" ? "center" : "flex-start"} as="nav" gap="4">
					<NavItemTwo navSize={navSize} icon={LuLayoutDashboard} title="Dashboard" path="/dashboard" />
					<NavItemTwo navSize={navSize} icon={LuPlus} title="Skapa idag" path="/newpage" />
					<NavItemTwo navSize={navSize} icon={IoMdStats} title="Statistik" path="/stats" />
					
					<NavItemTwo navSize={navSize} icon={LuBookOpen} title="Playbook" path="/playbook" />
					<IconButton
						aria-label="Toggle Sidebar"
						icon={navSize === "small" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						onClick={toggleNavSize}
						color={"#868686"}
						position="absolute"
						right={"-12px"} // Adjust position to half outside the sidebar
						top="70%"
						backgroundColor={"#050606"}
						zIndex="10"
						borderRadius={"full"} // Half-circle shape
						background="#333" // The color of the button; change as needed
						borderRight="1px solid #1C1E22"
						_hover={{
							background: null,
							color: "#fff",
						}}
						_active={{
							background: null,
							color: null,
						}}
						// If you want a shadow similar to the sidebar, you can add it here
					/>
					<NavItemTwo navSize={navSize} icon={LuLibrary} title="Alla Trades" path="/allatrades" />
					<NavItemTwo navSize={navSize} icon={MdQueryStats} title="Trender" path="/trender" />
					<NavItemTwo navSize={navSize} icon={LuArrowDownWideNarrow} title="Screener" path="/screener" />
					<NavItemTwo navSize={navSize} icon={LuNewspaper} title="News Chart"  path="/newschart" />
					<ComingSoon navSize={navSize} icon={AiFillQuestionCircle} title="Tradalyze AI" />
					<ComingSoon navSize={navSize} icon={AiFillQuestionCircle} title="Community" />
					
				</Flex>
				<Spacer />
				<Box>
					<AccountBar navSize={navSize} icon={AiFillQuestionCircle} title="Tradalyze AI"></AccountBar>
				</Box>
				<Flex p="0" flexDir="column" w="100%" alignItems={navSize === "small" ? "center" : "flex-start"} mb={4}></Flex>
			</Box>
		</Flex>
	);
}
