import {
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  VStack,
  Text,
  Flex,
  Tooltip
} from "@chakra-ui/react";

import { STATUSES } from "./data";
import { LuAirplay, LuFilter } from "react-icons/lu";

const StatusItem = ({ status, setColumnFilters, isActive }) => (
  <Flex
    align="center"
    cursor="pointer"
    borderRadius={5}
    fontWeight="bold"
    p={1.5}
    bg={isActive ? "gray.800" : "transparent"}
    _hover={{
      bg: "gray.800",
    }}
    onClick={() =>
      setColumnFilters((prev) => {
        const statuses = prev.find((filter) => filter.id === "status")?.value;
        if (!statuses) {
          return prev.concat({
            id: "status",
            value: [status.id],
          });
        }

        return prev.map((f) =>
          f.id === "status"
            ? {
                ...f,
                value: isActive
                  ? statuses.filter((s) => s !== status.id)
                  : statuses.concat(status.id),
              }
            : f
        );
      })
    }
  >
    <Icon as={LuAirplay} color={status.color} mr={3} />
    {status.name}
  </Flex>
);

const FilterPopover = ({ columnFilters, setColumnFilters }) => {
  const filterStatuses =
    columnFilters.find((f) => f.id === "status")?.value || [];

  return (
    <Tooltip label={"Kommmer snart..."} placement="auto-start" gutter={20} bg="#090A0B" color={"white"} padding={3} borderRadius={"2xl"}shouldWrapChildren>
      <div>
        <Button
          size="sm"
          backgroundColor={"transparent"}
          borderWidth="1px"
          borderColor="#1C1E22"
          fontWeight={"normal"}
          color={"#868686"}
          _hover={{ bg: "transparent", color: "#868686",  }}
          _focus={{ bg: "transparent", color: "#868686" , borderColor: "#868686"}}
          _active={{ bg: "transparent", color: "#868686", borderColor: "#868686" }}
          leftIcon={<Icon as={LuFilter} color={"#868686"} fontSize={18} />}
          isDisabled
          _disabled={{
            opacity: 1 // maintain normal opacity
          }}
        >
          Filter
        </Button>
      </div>
    </Tooltip>
  );
};

export default FilterPopover;
