import { Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import * as React from "react";

const SelectChoc = ({ options, onChange }) => {
	const chakraStyles = {
		option: (provided, state) => ({
			...provided,
			color: "#1C1E22", // Set text color to white
			bg: state.isHovered ? "gray.100" : "transparent", // Gray background on hover
			_hover: {
				bg: "gray.100", // Additional hover styling
			},
		}),
		control: (provided, state) => ({
			...provided,
			borderWidth: "1px",
			borderColor: state.isFocused ? "#868686" : "#1C1E22",
			boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove box shadow on focus
			bg: "transparent", // Set background color to transparent
			_hover: {
				bg: "transparent", 
				color: "white"
			},
			_focus: {
				bg: "transparent",
				color: "white",
				borderColor: "#868686"
			},
			_active: {
				bg: "transparent",
				color: "white",
				borderColor: "#868686"
			},
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: "#1C1E22", // Set dropdown indicator icon color to gray
		}),
		// Add styles for other components if needed
		multiValue: (provided, state) => ({
			...provided,
			bg: "#1C1E22",
			color: "#868686",
			borderRadius: "5px",
		}),
		multiValueLabel: (provided, state) => ({
			...provided,
			color: "#868686",
		}),
		multiValueRemove: (provided, state) => ({
			...provided,
			color: "#868686",
			_hover: {
				bg: "gray.700",
				color: "white",
			},
		}),
		clearIndicator: (provided, state) => ({
			...provided,
			color: "#868686",
			cursor: "pointer",
			_hover: {
				color: "white",
			},
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: "#868686", // Set placeholder color to gray
		}),
	};

	return (
		<Flex justify="center" align="center" w="500px">
			<FormControl p={4}>
				<FormLabel>Filtrera</FormLabel>
				<Select
					isMulti
					name="colors"
					chakraStyles={chakraStyles}
					options={options}
					placeholder="Välj taggar..."
					noOptionsMessage={() => "Inga alternativ"}
					onChange={(selectedOptions) => onChange(selectedOptions || [])}
					variant="filled"
				/>
			</FormControl>
		</Flex>
	);
};

export default SelectChoc;
