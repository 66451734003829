import { Box, Flex, HStack, Heading, Icon, Tooltip, VStack } from "@chakra-ui/react";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

HighchartsMore(Highcharts);
const colorWhite = "#fff";
const Mentalitetchart = ({ title, profitData, tooltip, secondTitle, data }) => {
	const options = {
		chart: {
			type: "areaspline", // Use spline for area chart
			backgroundColor: null,
			spacingBottom: 80,
			height: "300",
			width: "625",
			spacingTop: 50,
			spacingLeft: 50,
			spacingRight: 50, // Default type, can be overridden by series.type
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: false,
		},
		scrollbar: {
			enabled: false,
		},
		title: null,
		xAxis: {
			type: "datetime",

			dateTimeLabelFormats: {
				day: "%m-%d",
			},
			visible: true, // Set to true to show the xAxis
		},
		yAxis: [
			{
				labels: {
					formatter: function () {
						switch (this.value) {
							case 1:
								return "😟"; // Example custom label
							case 2:
								return "😀";
							case 3:
								return "🤩";
							default:
								return "";
						}
					},
					style: {
						color: "#868686",
						fontWeight: 500,
						fontSize: "18px",
					},
				},
				title: {
					enabled: false,
				},
				tickPositions: [1, 2, 3],
				gridLineColor: "#596672",
				gridLineWidth: 0.5,
				gridLineDashStyle: "longdash",
				offset: 30,
			},
			{
				title: {
					text: null,
				},
				gridLineWidth: 0,
				gridLineDashStyle: "longdash",
				labels: {
					style: {
						color: "#CBD5E0",
						fontWeight: 500,
					},
				},
				opposite: true,
			},
		],
		tooltip: {
			shared: true,
			useHTML: true,
			backgroundColor: "#090A0B",
			borderColor: "#1C1E22",
			style: {
				color: "#fff",
			},
			formatter: function () {
				const weekdays = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
				const dayOfWeek = weekdays[new Date(this.x).getDay()];
				var emojiMap = {
					1: "😟",
					2: "😀",
					3: "🤩",
				};
				var points = this.points; // Get all points for the shared tooltip
				var tooltipHtml = `<b>${dayOfWeek}, ${Highcharts.dateFormat("%e %b %Y", this.x)}</b><table>`; // Format date in Swedish

				points.forEach((point) => {
					if (point.series.name === "Resultat") {
						// Handle "Resultat kr." series differently
						tooltipHtml +=
							`<tr><td style="color: ${point.series.color}">${point.series.name}: </td>` +
							`<td style="text-align: right"><b>${point.y} kr</b></td></tr>`;
					} else {
						// Assume other series is "Mentalitet" and use emoji mapping
						var emoji = emojiMap[point.y] || ""; // Map the y value to an emoji, default to empty if not found
						tooltipHtml +=
							`<tr><td style="color: ${point.series.color}">Mentalitet: </td>` + `<td style="text-align: right"><b>${emoji}</b></td></tr>`;
					}
				});

				tooltipHtml += "</table>";
				return tooltipHtml;
			},
		},

		plotOptions: {
			column: {
				opacity: 0.5, // Keeping the opacity setting for columns
			},
		},
		series: [
			{
				data: data,
				color: "rgba(5, 216, 165, 1)",
				showInLegend: false,
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(5, 216, 165, 0.3)"],
						[1, "rgba(5, 216, 165, 0)"],
					],
				},
			},
			{
				name: "Resultat",
				type: "column",
				yAxis: 1,
				data: profitData,
				color: "rgba(255, 255, 255, 0.2)",
			},
		],
		credits: {
			enabled: false,
		},
	};

	return (
		<Box
			bg="#090A0B"
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			width="728px"
			height="350px"
			maxHeight="350px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<Box position="absolute" top={0} left={0} p={6}>
				<VStack align="flex-start" mt={1}>
					<HStack justifyContent="space-between" w="100%">
						<Flex alignItems="center">
							<Box>
								<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
									{title}{" "}
									<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
										<span>
											<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
										</span>
									</Tooltip>
								</Heading>
								<Heading fontSize="14" ml={5} color="#868686" opacity={0.87}>
									{secondTitle}
								</Heading>
							</Box>
						</Flex>
					</HStack>
				</VStack>

				<HighchartsReact highcharts={Highcharts} options={options} />
			</Box>
		</Box>
	);
};

export default Mentalitetchart;
