import React from "react";
import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import Smallcard from "../cards/smallcard";
import Longcard from "../cards/longcard";

const StatisticsBox = ({
  courtage,
  expectancy,
  longestTrends,
  winLossRatios,
  avgWinLossTrade,
}) => {
  return (
    <Box
      bg="#090A0B"
      borderWidth="1px"
      borderColor="#1C1E22"
      h={{base: "auto", md: "auto", xl: "440px", "2xl": "auto"}}
      borderRadius="2xl"
      overflow="hidden"
      p={1}
      ml={0}
      mr={0}
      minW={{ base: "100%", xl:"470px", "2xl": "500px" }}
      minH={{ base: "50vh", md: "480px", xl: "480px", "2xl": "490px" }}
      display={"flex"}
      flexDirection={"column"}
      justifyContent="flex-start"
      alignItems={{base: "end", md: "start", xl: "start", "2xl": "end"}}
      gap={{ base: 6, md: 0, xl: 0 }}
      position="relative"
    >
      <Box
        transform={{
          base: "translateX(-10%) translateY(-20%)",
          md: "translateX(70%) translateY(0%)",
          xl: "translateX(70%) translateY(10%)",
          "2xl": "translateX(-40%)"
        }}
        mt={{ base: "0", md: "-10", xl: "-10" }}
        display="flex"
        flexDirection="column"
        mb={{ base: -6, md: 0, xl: 0 }}
        justifyContent="center"
        flex="1"
      >
        <Smallcard
          title="Courtage"
          content={courtage}
          tooltip="Totala courtaget för perioden, detta är inräknat i resultatet."
          customWidth="229px"
          customHeight="108px"
        />
      </Box>

      <HStack
        transform={{ base: "translateX(10%)", xl: "translateX(10%) translateY(6%)" , "2xl": "translateX(10%)"}}
        display="flex"
        flexDirection="Row"
        justifyContent="center"
        spacing={6}
        flex="1"
      >
        <Smallcard
          title="Expectancy"
          content={expectancy}
          tooltip="Förväntat värde av varje trade baserat på historisk data."
          customWidth="229px"
          customHeight="108px"
        />
        <Smallcard
          title="Längsta Trender"
          customWidth="229px"
          customHeight="108px"
          content={longestTrends}
          buttonText="Ladda upp CSV"
          tooltip="Antal vinster och förluster i de längsta vinst- och förluststreaksen."
        />
      </HStack>

      <Box
        transform={{ base: "translateX(45%)", md: "translateX(30%)", xl: "translateX(20%)" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex="1"
      >
        <Longcard
          leftPercentage={winLossRatios.leftPercentage}
          rightPercentage={winLossRatios.rightPercentage}
          title="Avg win/loss trade"
          content={`${avgWinLossTrade.ratio}`}
          averageLoss={avgWinLossTrade.averageLoss}
          averageWin={avgWinLossTrade.averageWin}
          buttonText="Ladda upp CSV"
          tooltip="Visar hur mycket du i genomsnitt vinner jämfört med förluster i dina avslutade affärer under en viss tidsperiod."
        />
      </Box>

      <VStack justify={"center"} mb={4} align={"start"} ml={10}>
        <Text fontSize="18" color="#fff" opacity={0.87}>
          Statistik
        </Text>
        <Text
          fontSize="16"
          mt={-4}
          width={"80%"}
          color="#868686"
          opacity={0.87}
        >
          Få alla detaljer och nyckeltal du kan önska. Allt samlat på ett
          ställe.
        </Text>
      </VStack>
    </Box>
  );
};

export default StatisticsBox;
