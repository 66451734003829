import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState } from "react";

const NonEditableInput = ({ placeholderValue }) => {
	const colorWhite = "#fff";
	const [editing, setEditing] = useState(false);
	const [newPlaceholderValue, setNewPlaceholderValue] = useState(placeholderValue);

	const handleButtonClick = () => {
		if (editing) {
			// Save the new value and exit edit mode
			setEditing(false);
			placeholderValue = newPlaceholderValue; // Update the original placeholderValue
		} else {
			// Enter edit mode
			setEditing(true);
		}
	};

	const handleInputChange = (e) => {
		setNewPlaceholderValue(e.target.value);
	};

	return (
		<InputGroup>
			<Input
				type="text"
				placeholder={placeholderValue}
				value={newPlaceholderValue}
				onChange={handleInputChange}
				borderWidth="1px"
				borderColor="#868686"
				color="white" // Set the text color
				placeholderTextColor={colorWhite}
				isReadOnly={!editing} // Make the input non-editable when not in edit mode
			/>
			<InputRightElement>
				<button
					onClick={handleButtonClick}
					style={{
						backgroundColor: editing ? "#d53f8c" : "#868686", // Change the button color when editing
						color: "white", // Button text color
						border: "none",
						borderRadius: "0 4px 4px 0",
						padding: "0.5rem",
						cursor: "pointer",
					}}
				>
					{editing ? "Save" : "Ändra"}
				</button>
			</InputRightElement>
		</InputGroup>
	);
};

export default NonEditableInput;
