import { EditIcon } from "@chakra-ui/icons";
import { Checkbox } from "@chakra-ui/react";
import React from "react";

const SelectCell = ({ row, selectedRowIds, toggleRowSelected }) => {
  // Använd 'tradeId' istället för 'id' för att hantera urval
  const tradeId = row.original.tradeId; // Antag att 'tradeId' finns som en egenskap på 'row.original'
  const isSelected = selectedRowIds.includes(tradeId);

  const handleChange = () => {
    toggleRowSelected(tradeId); // Skicka 'tradeId' till toggle-funktionen
  };

  return (
    <Checkbox
      colorScheme="whiteAlpha"
      isChecked={isSelected}
      onChange={handleChange}
    />
  );
};

export default SelectCell;
