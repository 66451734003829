import { Box, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
const Informationcard = ({ title, customwidth, content, tooltip }) => {
	const colorWhite = "#868686";

	return (
		<Box bg="#090A0B" overflow="hidden" p={6} mb={2} ml={0} mr={0} width={customwidth} height="250px" display="flex" justifyContent="space-between">
			<Box display="flex" alignItems="center">
				<Box boxSize={12} mr={4}></Box>
				<Box>
					<Heading fontSize="18" color="#fff" opacity={0.87}>
						{title}
					</Heading>
					<Box>
						<Text lineHeight={6} maxW={"100%"} color={colorWhite}>
							{content}
						</Text>
					</Box>
				</Box>
			</Box>
			<Box display="flex" justifyContent="flex-end" alignItems="flex-end" mb={2} mr={10}>
				<Link
					fontWeight={"medium"}
					as={RouterLink}
					to="/omoss"
					color="#05D8A5"
					whiteSpace="nowrap" // prevent text wrap
					overflow="hidden" // hide overflow
					textOverflow="ellipsis" // add ellipsis for overflow text
				>
					Läs mer
				</Link>
			</Box>
		</Box>
	);
};
export default Informationcard;
