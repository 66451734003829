import { Box, HStack, Heading, Icon, Tooltip, VStack } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const colorWhite = "#fff";
const Barchart = ({ secondTitle, data, tooltip, title }) => {
	const options = {
		chart: {
			type: "bar",
			backgroundColor: null,
			spacingBottom: 50,
			height: "275",
			width: "625",
			spacingTop: 50,
			spacingLeft: 50,
			spacingRight: 50,
		},
		title: null,

		xAxis: {
			type: "category",
			labels: {
				style: {
					color: "#868686",
					fontWeight: 500,
				},
			},
		},

		yAxis: {
			gridLineColor: "#596672",
			gridLineWidth: 0.5,
			gridLineDashStyle: "longdash",
			offset: 30,
			unitColor: "#fff",
			labels: {
				style: {
					color: "#868686",
					fontWeight: 500,
				},
			},
			title: {
				enabled: false,
			},
		},

		legend: {
			enabled: false,
		},

		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: "{point.y:.1f}%",
				},
			},
		},
		tooltip: {
			headerFormat: null,
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> av totalen<br/>',
		},
		exporting: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		series: [
			{
				colorByPoint: true,
				data: data,
				dataLabels: {
					enabled: false, // Set this to false to hide the data labels
				},
			},
		],
	};
	return (
		<Box
			bg="#090A0B"
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			width="728px"
			height="350px"
			maxHeight="350px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<Box position="absolute" top={0} left={0} p={6}>
				<VStack align="flex-start" mt={1}>
					<HStack justifyContent="space-between" w="100%">
						<Box>
							<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
								{title}{" "}
								<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
									<span>
										<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
									</span>
								</Tooltip>
							</Heading>
							<Heading fontSize="14" ml={5} color="#868686" opacity={0.87}>
								{secondTitle}
							</Heading>
						</Box>
					</HStack>
				</VStack>

				<HighchartsReact highcharts={Highcharts} options={options} />
			</Box>
		</Box>
	);
};

export default Barchart;
