import { Box, Flex, Heading, Icon, Skeleton, Tooltip, VStack } from "@chakra-ui/react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HSIndicators from "highcharts/indicators/indicators";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

HSIndicators(Highcharts);

function Annncharten({ data, title, tooltip, secondTitle }) {
	const chartRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (data) {
			setIsLoading(false);
		}
	}, [data]);

	const events = [
		{
			x: new Date("2024-02-07").getTime(),
      title: ' ',
			text: "Product Launch: New iPhone",
			fillColor: "#05D8A5",
		},
		{
			x: new Date("2024-02-27").getTime(),
      title: ' ',
			text: "Earnings Report: Q2 Results",
			fillColor: "#d53f8c",
		},
		{
			x: new Date("2024-01-12").getTime(),
			title: ' ',
			text: "Earnings Report: Q1 Results",
			fillColor: "#d53f8c",
		},
	];

	const volumeData = data.map((point) => [point[0], point[5]]);



	const options = {
		chart: {
			type: "candlestick",
			backgroundColor: null,
			height: "420",
			zoomType: "x",
			resetZoomButton: {
				theme: {
					style: {
						display: "none",
					},
				},
			},
			spacingBottom: 70,
			spacingTop: 50,
			spacingLeft: 50,
			spacingRight: 50,
		},

		xAxis: {
			type: "datetime",
			dateTimeLabelFormats: {
				day: "%e. %b",
				month: "%b '%y",
				year: "%Y",
			},
		},
		yAxis: [
			{
				gridLineColor: "#868686",
				gridLineWidth: 0.5,
				gridLineDashStyle: "longdash",
				labels: {
					style: {
						color: "#868686",
					},
					align: "left",
				},
				title: {
					enabled: false,
				},
			},
			{
				// Secondary yAxis (Volume)
				labels: {
					enabled: false,
				},
				title: {
					enabled: false,
				},
				top: "75%",
				height: "25%",
				offset: 0,
				lineWidth: 0, // Remove yAxis line
				gridLineWidth: 0, // Remove grid lines
			},
		],
		navigator: {
			enabled: false,
		},
		tooltip: {
			useHTML: true,
			backgroundColor: "#090A0B",
			borderColor: "#1C1E22",
			style: {
				color: "#fff",
				maxWidth: "100px", // Adjust tooltip width as needed
			},

			shared: false, // Important for individual point tooltips
		},
		scrollbar: {
			enabled: false,
		},

		rangeSelector: {
			inputEnabled: false,
			buttonPosition: {
				y: -40,
				align: "right",
			},
			labelStyle: {
				display: "none",
			},
			buttonTheme: {
				fill: "none",
				stroke: "#1C1E22",
				"stroke-width": 1,
				r: 8,
				padding: 3,
				style: {
					color: "#282828",
					fontWeight: "medium",
					fontSize: "12px",
				},
				states: {
					hover: {},
					select: {
						fill: "#090A0B",
						style: {
							color: "white",
						},
					},
				},
			},
			buttons: [
				{ type: "month", count: 1, text: "1m" },
				{ type: "month", count: 3, text: "3m" },
				{ type: "ytd", text: "YTD" },
				{ type: "all", text: "All" },
			],
			floating: true,
		},
		series: [
			{
				name: "AAPL",
				id: "dataseries",
				upColor: "#05d8a5", // Color for the bullish candle (price went up)
				lineColor: "#666666", // Color for the wicks
				upLineColor: "#666666", // Color for the wicks of the bullish candle
				color: "#fff",
				data: data,
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(5, 216, 165, 0.3)"], // Start color for area
						[1, "rgba(5, 216, 165, 0)"], // End color, fades out for area
					],
				},
				tooltip: {
					valueDecimals: 2,
				},
			},
      
			{
				// New series for volume
				type: "column",
				name: "Volume",
				color: "#666666",
				opacity: 0.5,
				data: volumeData,
				yAxis: 1, // This assigns the series to the secondary yAxis
				// Additional configurations for volume series...
			},
      {
        type: "flags",
        onSeries: "dataseries",
        data: events,
        lineColor: "#FFF",
        shape: "circlepin",
        width: 5,
        height: 5,
        fillColor: "#FFFFFF",
        style: {
          color: "#000000",
        },
        states: {
          hover: {
            fillColor: "#000000",
            style: {
              color: "#FFFFFF",
            },
          },
        },
        yAxis: 0,
       
        useHTML: true,
    }
		],
		credits: {
			enabled: false,
		},
		exporting: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
	};

	return (
		<Box
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			mb={2}
			ml={0}
			mr={0}
			minW="828px"
			height="450px"
			maxHeight="450px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			{isLoading ? (
				<Skeleton height="300px" isLoaded={true} width="700px" startColor="#050606" endColor="#090A0B" borderRadius="2xl" />
			) : (
				<Box width={"100%"} position="absolute" top={0} left={0} p={6}>
					<VStack align="flex-start" mt={1}>
						<Flex justifyContent="space-between" w="100%">
							<Box>
								<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
									{title}
									<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color="#fff" padding={3} borderRadius={"2xl"}>
										<span>
											<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
										</span>
									</Tooltip>
								</Heading>
								<Heading fontSize="14" ml={5} color="#868686" fontWeight="medium" opacity={0.87}>
									{secondTitle}
								</Heading>
							</Box>
						</Flex>
					</VStack>
					<Box mt={-6}>
						<HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} ref={chartRef} options={options} />
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default Annncharten;
