import { Box, Heading, Icon, SimpleGrid, Tooltip, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import RadioComponent from "../../buttons/radiobuttons";

const RatingDisplay = ({ grade, initialText, tooltip, title, secondTitle, isEditing }) => {
	const colorWhite = "#fff";
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		switch (grade) {
			case "Nöjd":
				setSelectedOption(2);
				break;
			case "Missnöjd":
				setSelectedOption(0);
				break;
			case "Lagom":
				setSelectedOption(1);
				break;
			default:
				setSelectedOption(null); // Reset for null or unexpected values
		}
	}, [grade]);

	const radioOptions = [
		{
			title: "Missnöjd",
			index: 0,
			customColor: "rgba(229, 62, 62, 0.15)",
			customTitleColor: "#E53E3E",
		},
		{
			title: "Lagom",
			index: 1,
			customTitleColor: "#00B0ED",
			customColor: "rgba(0, 176, 237, 0.15)",
		},
		{
			title: "Nöjd",
			index: 2,
			customTitleColor: "#05d8a5",
			customColor: "rgba(5, 216, 165, 0.15)",
		},
	];

	const handleRadioChange = (index) => {
		if (isEditing) {
			setSelectedOption(index);
		}
	};

	return (
		<Box
			borderBottomWidth="0px"
			borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
			borderLeftWidth="0.5px"
			borderRightWidth="0.5px"
			borderStyle="solid"
			borderTopWidth="0.5px"
			backgroundColor="rgba(29, 32, 35, 0.6)"
			filter="hue-rotate(0deg)"
			opacity="1"
			borderRadius="12px"
			boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
			overflow="hidden"
			p={6}
			
			ml={0}
			mr={0}
			w={"100%"}
			minWidth="464px"
			height="200px"
			minHeight="auto"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<Box position="absolute" top={0} left={0} p={6}>
				<VStack align="flex-start" mt={1}>
					<Box>
						<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
							{title}{" "}
							<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
								<span>
									<Icon as={AiOutlineInfoCircle} boxSize={18} color="#434A54" ml={2} />
								</span>
							</Tooltip>
						</Heading>
						<Heading fontSize="14" ml={5} fontWeight="medium" color="#868686" opacity={0.87}>
							{secondTitle}
						</Heading>
					</Box>

					<Box ml={5} mt={5}>
						<SimpleGrid columns={3} spacing={10}>
							{radioOptions.map((option) => (
								<RadioComponent
									key={option.index}
									title={option.title}
									customColor={option.customColor}
									titleColor={option.customTitleColor}
									isSelected={selectedOption === option.index}
									onChange={() => handleRadioChange(option.index)}
									isDisabled={!isEditing}
								/>
							))}
						</SimpleGrid>
					</Box>
				</VStack>
			</Box>
		</Box>
	);
};

export default RatingDisplay;
