import { Box } from "@chakra-ui/react";
import React from "react";

const SmallcardCalendar = ({ title, showIcon = true, content, tooltip, customWidth = "200px", customHeight = "100px" }) => (
	<Box
		bg="#090A0B"
		borderWidth="1px"
		borderColor="#1C1E22"
		borderRadius="2xl"
		overflow="hidden"
		boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
		p={6}
		ml={0}
		mr={0}
		width={customWidth}
		height={customHeight}
		alignItems="center"
		display={"flex"}
		justifyContent="center"
	>
		{content}
	</Box>
);

export default SmallcardCalendar;
