import {
  Badge,
  Box,
  Button,
  ChakraProvider,
  Divider,
  Flex,
  Grid,
  HStack,
  Link,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
  Image,
  SimpleGrid,
  Text,
  VStack,
  extendTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
import { IoMdBookmark, IoMdImage, IoMdStats } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaDiscord, FaXTwitter } from "react-icons/fa6";
import Smallcardtwo from "../../components/cards/smallcardtwo";
import RadioComponent from "../../components/buttons/radiobuttons";
import PiechartComponent from "../../components/charts/piechart";
import "../../components/pagerelated/css/customScrollbar.css";
import "../../components/pagerelated/css/snakeBorder.css";
import { EmailIcon } from "@chakra-ui/icons";
import { ReactComponent as MyIcon } from "../../pages/Sidebar/alphalogo.svg";
import { ReactComponent as MyIconTwo } from "../../pages/Sidebar/MyIconTwo.svg";
import LinechartComponent from "../../components/charts/linechart";
import LinechartComponentLP from "../../components/pagerelated/landingpage/linechartlanding";
import EmotionSelector from "../../components/inputs/emotionselector";
import TextEditor from "../../components/pagerelated/texteditor";
import TextEditorLP from "../../components/pagerelated/landingpage/texteditorlanding";
import Smallcard from "../../components/cards/smallcard";
import Longcard from "../../components/cards/longcard";
import { LuFileDown, LuShare2 } from "react-icons/lu";
import { TbFileTypeCsv } from "react-icons/tb";
import Footer from "../../components/pagerelated/footer";
import Imported from "../../components/cards/importedcard";
import MiddlecardLP from "../../components/pagerelated/landingpage/middlecardlanding";
import PiechartComponentLP from "../../components/pagerelated/landingpage/piechartlanding";
import StatisticsBox from "../../components/landingPageBoxes/StatisticsBox";
import InputBox from "../../components/landingPageBoxes/InputBox";
import ShareBox from "../../components/landingPageBoxes/ShareBox";
import TrendsBox from "../../components/landingPageBoxes/TrendsBox";
import JournalBox from "../../components/landingPageBoxes/JournalBox";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "black",
        color: "white",
      },
    },
  },
});

const colorWhite = "#fff";

const Home = () => {
  const [isSignedUp, setIsSignedUp] = useState(false); // Steg 1
  const navigate = useNavigate();
  const radioOptions = [
    {
      title: "Missnöjd",
      index: 0,
      customColor: "rgba(229, 62, 62, 0.15)",
      customTitleColor: "#E53E3E",
    },
    {
      title: "Lagom",
      index: 1,
      customTitleColor: "#00B0ED",
      customColor: "rgba(0, 176, 237, 0.15)",
    },
    {
      title: "Nöjd",
      index: 2,
      customTitleColor: "#05d8a5",
      customColor: "rgba(5, 216, 165, 0.15)",
    },
  ];

  const [email, setEmail] = useState("");

  const waitlistFetch = () => {
    fetch("https://backend-production-41c0.up.railway.app/waitlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmail("");
        setIsSignedUp(true); // Steg 2
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleRadioChange = (index, title) => {
    setSelectedOption(index);

    localStorage.setItem("selectedRadioOption", index); // Store the selected option index in localStorage
  };
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const handleEmojiChange = (id) => {
    localStorage.setItem("selectedEmoji", id); // Save the selected emoji's ID to localStorage
    setSelectedEmoji(id); // Update the state
  };
  const logos = [
    {
      src: "https://investors.avanza.se/images/f23056c3-d0cb-420d-9b8c-0bda0a55846c/sp/Avanza_logo_NEG_hires.png",
      alt: "Avanza Logo",
    },
    {
      src: "https://nordnetab.com/wp-content/uploads/2020/10/Nordnet_logo.png",
      alt: "Nordnet Logo",
    },
    {
      src: "https://sebgroup.com/siteassets/sebgroup.com/press-and-news/seb_k_neg_45mm150dpi.png",
      alt: "SEB Logo",
    },
    {
      src: "https://assets-global.website-files.com/5dd6b5c7f9822e5ea3513ede/5e67605ec608b56490db5dfa_nordea_logo.webp",
      alt: "Nordea Logo",
    },
    {
      src: "https://assets-global.website-files.com/62547917cb55990b8de4d83f/63063a1a0fc9bd8657b09592_Freetrade%20Logo%20-%20Horizontal%20White%20(1).png",
      alt: "Freetrade Logo",
    },
    // ... add more logos here if needed
  ];
  const [courtage, setCourtage] = useState("204 kr");
  const [expectancy, setExpectancy] = useState("2312 kr");
  const [longestTrends, setLongestTrends] = useState({ wins: 5, losses: 3 });
  const [avgWinLossTrade, setAvgWinLossTrade] = useState({
    ratio: 1.5,
    averageLoss: 12,
    averageWin: 18,
  });
  const [winLossRatios, setWinLossRatios] = useState({
    leftPercentage: 50,
    rightPercentage: 50,
  }); // Initial state

  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    // Use the generateSmoothRandomData function to generate initial data
    const initialData = generateSmoothRandomData(50); // Adjust the number of data points as needed
    setLineChartData(initialData);
  }, []);

  const generateSmoothRandomData = (length = 50, daysBack = 365) => {
    let data = [];
    const endDate = new Date();
    const startDate = new Date().setDate(endDate.getDate() - daysBack);

    for (let i = 0; i < length; i++) {
      const time = startDate + (i / length) * (endDate - startDate);

      // Introduce a mechanism to have more pronounced negative changes
      const isNegative = Math.random() < 0.5; // 50% chance to decide the direction of the change
      let change = Math.random() * 10; // base change amount
      if (isNegative) {
        change *= -1; // make the change negative
        // Introduce occasional larger drops
        if (Math.random() < 0.1) {
          // 10% chance to make a larger drop
          change *= 2; // double the drop
        }
      }

      const value = (data[i - 1] ? data[i - 1].y : 50) + change;
      data.push({
        x: time, // Date as timestamp
        y: Math.round(value * 100) / 100, // Rounded value, ensures no long floating points
      });
    }
    return data;
  };
  const [pieChartData, setPieChartData] = useState({
    winPercentage: 20,
    lossPercentage: 50,
    evenPercentage: 30, // Assuming the rest is even outcomes
  });
  const [textEditorContent, setTextEditorContent] = useState("");

  const textSamples = [
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 1 },
          content: [{ type: "text", text: "Veckans Handelssammanfattning" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Denna vecka har varit fylld med hög volatilitet på marknaden, vilket gav både utmaningar och möjligheter. ",
            },
            { type: "text", text: "Teknikaktier", marks: [{ type: "bold" }] },
            { type: "text", text: " fortsatte att prestera väl, medan " },
            {
              type: "text",
              text: "energisektorn",
              marks: [{ type: "italic" }],
            },
            { type: "text", text: " visade tecken på svaghet." },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Övervägde positioner i förnybar energi och teknik.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Analyserade marknadstrender och anpassade strategier därefter.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "blockquote",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Tålamod är nyckeln till framgång i trading.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Daglig Reflektion" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Idag fokuserade jag på att bibehålla disciplinen trots frestelsen att överreagera på små marknadsrörelser. Lärde mig vikten av att hålla fast vid min ",
            },
            {
              type: "text",
              text: "långsiktiga strategi",
              marks: [{ type: "underline" }],
            },
            { type: "text", text: "." },
          ],
        },
        {
          type: "orderedList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Utvärderade och justerade stop-loss nivåer för att skydda kapital.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Följde upp och analyserade dagens trades för att identifiera förbättringsområden.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Måndagens Marknadsöversikt" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Morgonen började med optimism på marknaden, vilket avspeglades i en stark öppning. Dock blev eftermiddagen mer utmanande.",
            },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Noterade en oväntad vändning i konsumentbeteendet, vilket kan behöva ytterligare analys.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Energiprisernas fluktuationer erbjuder nya möjligheter.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "blockquote",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Ingen kan förutsäga marknaden till fullo, men väl förberedda beslut minimerar risken.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 1 },
          content: [{ type: "text", text: "Veckans Strategiska Justeringar" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Efter att ha analyserat föregående veckas prestationer, är det dags för några strategiska förändringar.",
            },
          ],
        },
        {
          type: "orderedList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Öka exponeringen mot tekniksektorn baserat på senaste trendanalysen.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Justera diversifieringsstrategin för att inkludera fler tillväxtmarknader.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Reflektioner över Riskhantering" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Dagens session gav mig värdefulla insikter om vikten av effektiv riskhantering.",
            },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Användning av trailing stop-loss för att maximera vinster samtidigt som riskerna minimeras.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Balansera portföljen för att motstå marknadsvolatilitet.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 1 },
          content: [{ type: "text", text: "Tisdagens Handelsstrategier" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Fokus på tisdag ligger på att identifiera och agera på kortvariga marknadstrender.",
            },
            { type: "text", marks: [{ type: "italic" }], text: "Flexibilitet" },
            { type: "text", text: " och " },
            { type: "text", marks: [{ type: "italic" }], text: "snabbhet" },
            { type: "text", text: " är avgörande." },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Övervaka tekniska indikatorer för ledande index.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Använda algoritmisk handel för att effektivisera utförandet.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Lärdomar från Veckans Handel" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Veckan har varit en berg-och-dalbana, men den har lämnat mig med flera viktiga lärdomar.",
            },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Ständig utbildning och marknadsanalys är oumbärligt för en framgångsrik trader.",
                    },
                  ],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "Psykologisk resiliens hjälper till att navigera genom tuffa tider.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  // Define new state variables and their setters
  const [randomResultat, setRandomResultat] = useState("6482 kr");
  const [randomTradeRating, setRandomTradeRating] = useState("3e bästa");
  const [randomAntalAvslut, setRandomAntalAvslut] = useState("31 st");
  const [randomMiddlecardLPValues, setRandomMiddlecardLPValues] = useState({
    StörstaProfit: 6373,
    StörstaStock: "Volvo AB",
    MinstaProfit: 1935,
    MinstaStock: "Tesla Inc.",
  });

  const handleImportDataClick = () => {
    // Update line chart data
    setLineChartData(generateSmoothRandomData());

    // Randomly select a radio option
    const randomRadioIndex = Math.floor(Math.random() * radioOptions.length);
    setSelectedOption(radioOptions[randomRadioIndex].index);

    // Randomly select an emoji ID from the known range (1 to 3)
    const randomEmojiId = Math.floor(Math.random() * 3) + 1; // +1 because your IDs start at 1, not 0
    setSelectedEmoji(randomEmojiId);

    // Assuming you want to persist the selected emoji in local storage
    localStorage.setItem("selectedEmoji", randomEmojiId.toString());

    const randomTextIndex = Math.floor(Math.random() * textSamples.length);
    const randomText = textSamples[randomTextIndex];

    setTextEditorContent(randomText);

    // Randomize 'Courtage'
    setCourtage(`${Math.floor(Math.random() * 500)} kr`);

    // Randomize 'Expectancy'
    setExpectancy(`${Math.floor(Math.random() * 3000)} kr`);

    // Randomize 'Längsta Trender'
    setLongestTrends({
      wins: Math.floor(Math.random() * 10),
      losses: Math.floor(Math.random() * 10),
    });

    // Randomize 'Avg win/loss trade'
    setAvgWinLossTrade({
      ratio: parseFloat(Math.random() * 3).toFixed(1), // Generates a number between 0 and 3, rounded to 1 decimal place
      averageLoss: Math.floor(Math.random() * 20),
      averageWin: Math.floor(Math.random() * 30),
    });

    const total = Math.floor(Math.random() * 100);
    const leftPercentage = total;
    const rightPercentage = 100 - total;

    setWinLossRatios({
      leftPercentage,
      rightPercentage,
    });
    const win = Math.floor(Math.random() * 100);
    const loss = Math.floor(Math.random() * (100 - win));
    const even = 100 - win - loss; // Ensure total is 100%

    setPieChartData({
      winPercentage: win,
      lossPercentage: loss,
      evenPercentage: even,
    });

    const randomResultat = `${Math.floor(Math.random() * 10000) - 5000} kr`; // Random value between -5000 and +5000 kr

    // Randomize 'Trade Rating'
    const randomTradeRating = `${Math.floor(Math.random() * 5) + 1}e bästa`; // Random rating between 1 and 5

    // Randomize 'Antal Avslut'
    const randomAntalAvslut = `${Math.floor(Math.random() * 100)}st`; // Random number of trades between 0 and 100

    // Set the state for each randomization (You would need to add state hooks for these if not already present)
    // New logic to update the randomized values
    setRandomResultat(`${Math.floor(Math.random() * 10000) - 5000} kr`);
    setRandomTradeRating(`${Math.floor(Math.random() * 5) + 1}e bästa`);
    setRandomAntalAvslut(`${Math.floor(Math.random() * 100)} st`);

    const stocks = [
      "Tech Innovations AB",
      "Old Energy Corp",
      "Green Solutions Inc",
      "Future Tech Ltd",
    ];
    setRandomMiddlecardLPValues({
      StörstaProfit: Math.floor(Math.random() * 20000),
      StörstaStock: stocks[Math.floor(Math.random() * stocks.length)],
      MinstaProfit: -Math.floor(Math.random() * 10000),
      MinstaStock: stocks[Math.floor(Math.random() * stocks.length)],
    });
  };

  const breakpoints = {
    base: "0em", // 0px
    sm: "30em", // ~480px.
    xl: "62em", // ~992px
    lg: "80em", // ~1280px
    xl: "96em", // ~1536px
  };

  const IconComponent = useBreakpointValue({
    base: MyIconTwo,
    md: MyIcon,
  });
  const iconDimensions = useBreakpointValue({
    base: { width: "40%", height: "10%" },
    md: { width: "100%", height: "60%" },
  });

  return (
    <ChakraProvider theme={theme}>
      <Flex
        position="relative"
        width="100vw"
        height="100vh"
        overflowX="hidden"
        flexDirection={{ base: "column", xl: "row" }} // Ensure Flex is responsive
      >
        <VStack flex={1} mb={100} className="custom-scrollbar">
          <HStack
            mt={10}
            bg="transparent"
            width={{ base: "80%", xl: "75%", lg: "75%" }} // Responsive width
            alignItems="center"
            justifyContent="space-between"
            color={colorWhite}
          >
            <HStack mr={{ base: 0, md: 10, xl: 10 }} align="center">
              <Box ml="0px">
                <IconComponent
                  width={iconDimensions.width}
                  height={iconDimensions.height}
                />
              </Box>
            </HStack>

            <HStack ml={{ base: 0, xl: 100 }} spacing={5}>
              {" "}
              <Button
                borderBottomWidth="0px"
                borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
                borderLeftWidth="0.5px"
                borderRightWidth="0.5px"
                borderStyle="solid"
                size={{ base: "sm", xl: "md" }}
                borderTopWidth="0.5px"
                backgroundColor="black"
                filter="hue-rotate(0deg)"
                opacity="1"
                color={"white"}
                _hover={{
                  backgroundColor: "rgba(29, 32, 35, 0.9)",
                }}
                boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
                onClick={() => navigate("/login")}
              >
                Logga in
              </Button>
              <Button
                _hover={{
                  bgGradient: "linear(to-l, #05D3A5 , #5DFED1, #35FAC8)",
                }}
                size={{ base: "xs", xl: "md" }}
                bgGradient="linear(to-l, #05D8A5 , #35FAC8, #5DFBD3)"
                onClick={() => navigate("/signup")}
              >
                <Box display={{ base: "block", md: "none" }}>Skapa konto</Box>
                <Box display={{ base: "none", md: "block" }}>
                  Skapa nytt konto
                </Box>
              </Button>
            </HStack>
          </HStack>

          <VStack justifyContent="center">
            <VStack>
              <Heading
                lineHeight={{ base: "20px", sm: "60px", xl: "80px" }}
                mt={{ base: 20, sm: 20, xl: 14 }}
                fontSize={{ base: "30px", sm: "48px", xl: "72px" }}
                fontWeight="medium"
                textAlign="center"
              >
                Tradalyze är den nya
              </Heading>
              <Heading
                lineHeight={{ base: "auto", sm: "60px", xl: "80px" }}
                fontSize={{ base: "26px", sm: "48px", xl: "72px" }}
                fontWeight="normal"
                textAlign="center"
                bgClip="text"
                bgGradient="linear(to-l, #05D8A5 , #35FAC8, #5DFBD3)"
              >
                standarden för handelsanalys
              </Heading>
            </VStack>
            <Text
              mt={6}
              fontSize={{ base: "14px", sm: "16px", xl: "18px" }}
              color={"#868686"}
              fontWeight="normal"
              textAlign="center"
            >
              Statistik, journal, playbook, trender och mer.
            </Text>
            {/* <UserRating users={users}></UserRating> */}

            {!isSignedUp ? (
              <HStack
                spacing={4}
                mt={6}
                align="center"
                flexDirection={{ base: "column", sm: "row" }}
                width={{ base: "40%", md: "80%", xl: "25%" }} // Responsive width
              >
                <Input
                  pr="4.5rem"
                  type="email"
                  placeholder="Din E-post Adress..."
                  backgroundColor={"transparent"}
                  _placeholder={{ opacity: 0.7, color: "white" }}
                  borderWidth="1px"
                  borderColor="#1C1E22"
                  focusBorderColor="0"
                  _focus={{ boxShadow: "none" }}
                  color="white"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  borderBottomWidth="0px"
                  backgroundColor={"rgba(29, 32, 35, 0.6)"}
                  filter="hue-rotate(0deg)"
                  opacity="1"
                  color={"white"}
                  boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
                  w={"100%"}
                  fontWeight={"medium"}
                  _hover={{
                    backgroundColor: "rgba(29, 32, 35, 0.9)",
                  }}
                  onClick={waitlistFetch}
                >
                  Ansök om nyckel!
                </Button>
              </HStack>
            ) : (
              <Badge
                p={2}
                pl={4}
                pr={4}
                fontSize={14}
                background="rgba(5, 216, 165, 0.15)"
                textColor="#05D8A5"
                textTransform={"none"}
                borderRadius={"md"}
              >
                Du har nu signat upp!
              </Badge>
            )}

            <HStack
              mt={20}
              align={{ base: "start", md: "center", xl: "start", lg: "start" }}
              spacing={4}
              justifyContent={"center"}
              flexDirection={{ base: "column", xl: "row" }} // Responsive flex direction
              display={{ base: "flex", md: "flex" }} // Hide on breakpoint base
            >
              <Box
                transform={{ base: "translateX(15%)", md: "translateX(0)" }}
                width={{ base: "auto", md: "auto", xl: "auto", lg: "auto" }}
              >
                <LinechartComponent
                  title={"Utveckling"}
                  secondTitle={"Cumulative PnL"}
                  data={lineChartData}
                  tooltip={
                    "Visar den samlade nettovinsten eller nettoförlusten över handelsdagen. Y-axeln är därmed resultat i kr, samt X-axeln antalet trades."
                  }
                  defaultChartType={"areaspline"}
                ></LinechartComponent>
              </Box>

              <VStack spacing={{ base: 4, md: 4, xl: 4 }} align={"start"}>
                <SimpleGrid
                  columns={{ base: 3, md: 3, xl: 3 }} // Responsive columns
                  mt={{ base: -2, md: -2, xl: 0, lg: 0 }}
                  spacing={4}
                  width={{ base: "auto", md: "auto", xl: "auto", lg: "auto" }} // Responsive width
                >
                  <Smallcardtwo
                    title="Resultat"
                    backgroundcolor="transparent"
                    content={randomResultat}
                    buttonText="Ladda upp CSV"
                    tooltip={"Ditt resultat för dagen."}
                    customWidth={{
                      base: "200px",
                      md: "232px",
                      xl: "200px",
                      lg: "200px",
                    }}
                    customHeight="100px"
                  />
                  <Smallcardtwo
                    title="Trade Rating"
                    backgroundcolor="transparent"
                    content={
                      <Badge
                        p={1}
                        pl={2}
                        pr={2}
                        fontSize={12}
                        background="rgba(5, 216, 165, 0.15)"
                        textColor="#05D8A5"
                        textTransform={"none"}
                        borderRadius={"md"}
                      >
                        {randomTradeRating}
                      </Badge>
                    }
                    buttonText="Ladda upp CSV"
                    tooltip={
                      "Hur var just denna playbook traden rankad gentemot dina andra?"
                    }
                    customWidth="200px"
                    customHeight="100px"
                  />
                  <Smallcardtwo
                    title="Antal Avslut"
                    backgroundcolor="transparent"
                    content={randomAntalAvslut}
                    buttonText="Ladda upp CSV"
                    tooltip={"Totalt antal trades för dagen."}
                    customWidth="200px"
                    customHeight="100px"
                  />
                </SimpleGrid>
                <HStack spacing={4} align={"start"}>
                  <PiechartComponentLP
                    title="Winrate %"
                    content={"...."}
                    customHeight={"240px"}
                    customWidth={{ base: "100%", md: "512px", xl: "416px" }} // Responsive width
                    buttonText=""
                    valueoneFormal={pieChartData.winPercentage}
                    valuetwoFormal={pieChartData.lossPercentage}
                    valuethreeFormal={pieChartData.evenPercentage}
                    labelone="vinster"
                    labeltwo="förluster"
                    labelthree="even"
                    valueone={`${pieChartData.winPercentage}%`}
                    valuetwo={`${pieChartData.lossPercentage}%`}
                    valuethree={`${pieChartData.evenPercentage}%`}
                    tooltip={
                      "Andelen vinnande affärer i förhållande till det totala antalet affärer. En hög winrate, till exempel över 50%, är generellt sett önskvärd eftersom det indikerar att fler affärer är vinnande än förlorande."
                    }
                  ></PiechartComponentLP>
                  <MiddlecardLP
                    title="Bästa Trades"
                    StörstaProfit={randomMiddlecardLPValues.StörstaProfit} // Use randomized value
                    StörstaStock={randomMiddlecardLPValues.StörstaStock} // Use randomized value
                    MinstaProfit={randomMiddlecardLPValues.MinstaProfit} // Use randomized value
                    MinstaStock={randomMiddlecardLPValues.MinstaStock} // Use randomized value
                    buttonText="Ladda upp CSV"
                    tooltip={"Bästa respektive sämsta trade för perioden."}
                  />
                </HStack>
              </VStack>
            </HStack>

            <Box
              bgGradient="linear(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 30%)"
              position={"static"}
              mt={-32}
              zIndex={2}
              width={"100vw"}
              display={{ base: "flex", md: "flex" }} // Hide on breakpoint base
              height={"200px"}
              overflow={"hidden"}
            ></Box>

            <VStack bg={"black"} width={"100%"}>
              <HStack
                mt={{ base: "-120px", md: "-120px", xl: "-20" }}
                spacing={5}
                wrap="wrap"
                overflow={"hidden"}
                mb={100}
                justifyContent="center"
                alignItems="center"
              ></HStack>
              <VStack>
                <Heading
                  fontSize={{ base: "24px", sm: "30px", xl: "36px" }}
                  fontWeight="medium"
                  textAlign="center"
                >
                  Sluta vara en Excel krigare.
                </Heading>
                <HStack
                  mb={100}
                  spacing={3}
                  overflow={"hidden"}
                  flexDirection={{ base: "column", sm: "row" }} // Responsive flex direction
                >
                  <Heading
                    fontSize={{ base: "20px", sm: "28px", xl: "32px" }}
                    fontWeight="normal"
                    textAlign="center"
                    bgClip="text"
                    color={"white"}
                  >
                    Snabbt, effektivt.
                  </Heading>
                  <Heading
                    fontSize={{ base: "20px", sm: "28px", xl: "32px" }}
                    fontWeight="normal"
                    textAlign="center"
                    bgClip="text"
                    bgGradient="linear(to-l, #05D8A5 , #35FAC8, #5DFBD3)"
                  >
                    För alla. Oavsett Mäklare.
                  </Heading>
                </HStack>

                <Flex
                  gap={{ base: 6, md: 6, "xl": 4 }}
                  mt={{ base: 0, md: 0, "xl": 50 }}
                  mb={75}
                  direction={{ base: "column", xl: "row", "2xl": "row" }} // Responsive flex direction
                  w={{
                    base: "70vw",
                    md: "80vw",
                    lg: "40vw",
                   "2xl": "70vw", 
                  }} // Responsive width
                  justifyContent="center"
                >
                  <Flex
                    gap={{ base: 6, md: 6,  xl: 4 }}
                    direction={"column"}
                    justifyContent="center"
                  >
                    <Flex
                      gap={{ base: 6, md: 6, xl: 4 }}
                      direction={{ base: "column", xl: "row" }}
                    >
                      <VStack gap={{ base: 6, md: 6, xl: 4 }}>
                        <InputBox
                          handleImportDataClick={handleImportDataClick}
                        />{" "}
                        // Ensure the correct function is passed
                        <ShareBox />
                      </VStack>
                      <StatisticsBox
                        courtage={courtage}
                        expectancy={expectancy}
                        longestTrends={
                          <>
                            <Badge
                              p={1}
                              fontSize={12}
                              mb={-2}
                              background="rgba(5, 216, 165, 0.15)"
                              textColor="#05D8A5"
                              textTransform={"none"}
                              borderRadius={"md"}
                            >
                              {longestTrends.wins} vinster
                            </Badge>
                            <Badge
                              p={1}
                              fontSize={12}
                              mb={-2}
                              ml={2}
                              background="rgba(229, 62, 62, 0.15)"
                              textColor="#E53E3E"
                              textTransform={"none"}
                              borderRadius={"md"}
                            >
                              {longestTrends.losses} förluster
                            </Badge>
                          </>
                        }
                        winLossRatios={winLossRatios}
                        avgWinLossTrade={avgWinLossTrade}
                      />
                    </Flex>
                    <TrendsBox lineChartData={lineChartData} />
                  </Flex>

                  <JournalBox
                    selectedEmoji={selectedEmoji}
                    setSelectedEmoji={setSelectedEmoji}
                    handleEmojiChange={handleEmojiChange}
                    textEditorContent={textEditorContent}
                    radioOptions={radioOptions}
                    selectedOption={selectedOption}
                    handleRadioChange={handleRadioChange}
                  />
                </Flex>
              </VStack>
            </VStack>

            <Box
              color={colorWhite}
              py={4}
              mb={{ base: 0, md: 0, "2xl": 50, lg: 50 }}
              left={0}
              width={{ base: "50%", md: "80%", xl: "80%", lg: "80%" }}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ base: "column-reverse", md: "row" }}
              >
                <Text fontSize="sm" color={"#868686"} mt={{ base: 4, md: 0 }}>
                  © {new Date().getFullYear()} Tradalyze
                </Text>
                <Flex
                  color={"#868686"}
                  flexDirection={{ base: "column", md: "row" }}
                  alignItems={{ base: "center", md: "flex-start" }}
                  gap={{ base: 4, md: 0 }}
                >
                  <Flex flexDirection={{ base: "row", md: "row" }}>
                    <Link
                      mx={2}
                      fontSize="sm"
                      href="mailto:tradalyze@gmail.com"
                    >
                      Kontakta oss
                    </Link>
                    <Link mx={2} fontSize="sm" as={RouterLink} to="/signup">
                      Skapa Konto
                    </Link>
                    <Link mx={2} fontSize="sm" as={RouterLink} to="/terms">
                      Terms & Policy
                    </Link>
                  </Flex>
                  <Flex mt={{ base: 0, md: 0 }}>
                    <Link
                      mx={2}
                      fontSize="sm"
                      href="https://twitter.com/tradalyze"
                    >
                      <span>
                        <Box
                          as={FaXTwitter}
                          w={6}
                          h={6}
                          color={"#868686"}
                          _hover={{
                            cursor: "pointer",
                            filter: "brightness(1.2)",
                          }}
                        />
                      </span>
                    </Link>
                    <Link
                      mx={2}
                      fontSize="sm"
                      href="https://discord.gg/U6Gcpuw9md"
                    >
                      <span>
                        <Box
                          as={FaDiscord}
                          w={6}
                          h={6}
                          color={"#868686"}
                          _hover={{
                            cursor: "pointer",
                            filter: "brightness(1.2)",
                          }}
                        />
                      </span>
                    </Link>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </VStack>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default Home;
