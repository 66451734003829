import { Badge, Box, Button, HStack, Heading, Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const colorWhite = "#fff";

const PiechartComponent = ({
	customWidth = "486px",
	valueoneName,
	valuetwoName,
	valuethreeName,
	customHeight,
	title,
	tooltip,
	valueone,
	valuetwo,
	valuethree,
	labelone,
	labeltwo,
	labelthree,
	valueoneFormal,
	valuetwoFormal,
	valuethreeFormal,
}) => {
	const navigate = useNavigate();

	const hasData = valueoneFormal > 0 || valuetwoFormal > 0 || valuethreeFormal > 0;

	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			backgroundColor: null,
			spacingBottom: 50,
			height: "300",
			width: "350",
			spacingTop: 50,
			spacingLeft: 50,
			spacingRight: 50,
		},
		exporting: {
			enabled: false,
		},
		title: {
			text: "",
			align: "center",
			verticalAlign: "middle",
			y: 60,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			pointFormat: "<b>{point.percentage:.1f}%</b>",
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		plotOptions: {
			pie: {
				dataLabels: {
					enabled: false, // Disable data labels
					distance: -50,
					style: {
						fontWeight: "bold",
						color: "white",
					},
				},
				startAngle: 0,
				endAngle: 360,
				center: ["50%", "75%"],
				size: "90%",
				slicedOffset: 10,
				borderWidth: 0, // Remove the border around pie slices
			},
		},
		series: [
			{
				type: "pie",
				name: "Percantage",
				innerSize: "60%",
				data: [
					{
						name: valueoneName,
						y: valueoneFormal,
						color: "#05D8A5",
					},
					{
						name: valuetwoName,
						y: valuetwoFormal,
						color: "#E53E3E",
					},
					{
						name: valuethreeName,
						y: valuethreeFormal,
						color: "#00B0ED",
					},
				], // Set the color for "Even" segment
			},
		],
	};
	const colorWhite = "#868686";
	return (
		<Box
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			maxW={"486px"}
			width={customWidth}
			Mheight="240px"
			height={customHeight}
			display="flex"
			justifyContent={!hasData ? "center" : "flex-start"} // Center only if no data
			alignItems="center" // Center the content vertically
			position="relative" // Position the main box relatively
		>
			{hasData ? (
				<>
					<Box width={"100%"} flex="1">
						<Box>
							<Box position="absolute" top={0} left={0} p={6}>
								<HStack justifyContent="space-between">
									<VStack align="flex-start" mt={1}>
										<Heading fontSize="18" ml={5} color="#fff" opacity={0.87} zIndex={2}>
											{title}{" "}
											<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={"fff"} padding={3} borderRadius={"2xl"}>
												<span>
													<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
												</span>
											</Tooltip>
										</Heading>

										<VStack ml={5} gap="5" align="flex-start">
											<Badge
												p={1}
												pl={2}
												pr={2}
												fontSize={12}
												background="rgba(5, 216, 165, 0.15)"
												textColor="#05D8A5"
												textTransform={"none"}
												borderRadius={"md"}
											>
												{valueone} {labelone}
											</Badge>
											<Badge
												p={1}
												pl={2}
												pr={2}
												fontSize={12}
												background="rgba(229, 62, 62, 0.15)"
												textColor="#E53E3E"
												textTransform={"none"}
												borderRadius={"md"}
											>
												{valuetwo} {labeltwo}
											</Badge>
											<Badge
												p={1}
												pl={2}
												pr={2}
												fontSize={12}
												background="rgba(0, 176, 237, 0.15)"
												textColor="rgba(0, 176, 237)"
												textTransform={"none"}
												borderRadius={"md"}
											>
												{valuethree} {labelthree}
											</Badge>
										</VStack>
									</VStack>
								</HStack>
							</Box>
						</Box>
					</Box>
					<Box width={"100%"} flex="1" display="flex" justifyContent="flex-end" mb={16} ml={28}>
						<PieChart highcharts={Highcharts} options={options} />
					</Box>
				</>
			) : (
				<VStack spacing={4} alignItems="center" textAlign="center" justifyContent={"center"} >
					<Heading fontSize="18" color="#fff" opacity={0.87}>
						..Ingen data att visa...
					</Heading>
					<Button
						background="rgb(213,63,140, 0.15)"
						textColor="#d53f8c"
						_hover={{ bg: "rgba(213,63,140, 0.3)" }}
						size={"sm"}
						onClick={() => navigate("/allatrades")}
					>
						Ladda upp trades
					</Button>
				</VStack>
			)}
		</Box>
	);
};

export default PiechartComponent;
