import { Box, Heading, Icon, Tooltip, VStack } from "@chakra-ui/react";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react"; // Import useEffect
import { AiOutlineInfoCircle } from "react-icons/ai";
import "./css/texteditorreadstyle.css";

const ReadOnlyTextEditor = ({ content, title, secondTitle, tooltip }) => {
	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Placeholder.configure({
				placeholder: "Skriv någonting…",
			}),
		],
		content: "", // Initialize with empty content or a placeholder
		editable: false, // Make the editor read-only
	});

	// Effect to update the editor's content whenever the `content` prop changes
	useEffect(() => {
		if (editor && content) {
			// Use the editor's `setContent` command to update the content
			editor.commands.setContent(content);
		}
	}, [content, editor]); // Depend on `content` and `editor`

	if (!editor) {
		return <div>Loading...</div>;
	}
	const colorWhite = "#fff";
	return (
		<Box
			bg="transparent"
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			width="100%"
			minHeight="100px"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<Box>
				<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
					{title}{" "}
					<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
						<span>
							<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
						</span>
					</Tooltip>
				</Heading>
				<Heading fontSize="14" ml={5} color="#868686" opacity={0.87}>
					{secondTitle}
				</Heading>
			</Box>
			<VStack align="flex-start" ml={3} mt={3} spacing={5}>
				<div className="editor-container" spellCheck="false" style={{ width: "100%", minHeight: "100px", overflowY: "auto" }}>
					<EditorContent editor={editor} />
				</div>
			</VStack>
		</Box>
	);
};

export default ReadOnlyTextEditor;
