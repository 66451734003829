import { Box, Button, ChakraProvider, Flex, HStack, Heading, Text, VStack, extendTheme, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import TaskTable from "../../components/pagerelated/newTableTest/tanstacktable";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Allatrades = () => {
	const [editedRows, setEditedRows] = useState({});
	const [rows, setRows] = useState([]);
	const [newRow, setNewRow] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const navigate = useNavigate();
	const [isAddingNewRow, setIsAddingNewRow] = useState(false);
	const [editingId, setEditingId] = useState(null); // State to track which row is being edited
	const toast = useToast();
	const [refreshTrades, setRefreshTrades] = useState(false);

	const saveTradesEdit = async (updatedRows) => {
		const updates = updatedRows.map((row) => ({
			tradeId: row.tradeId,
			updates: {
				Name: row.namn,
				Size: parseFloat(row.storlek),
				Courtage: parseFloat(row.courtage),
				Profit: parseFloat(row.resultat),
				Datum: row.datum,
			},
		}));

		try {
			await Promise.all(
				updates.map(async ({ tradeId, updates }) => {
					const response = await fetch(`https://backend-production-41c0.up.railway.app/trades/edit/${tradeId}`, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
						},
						credentials: "include",
						body: JSON.stringify(updates),
					});

					if (!response.ok) {
						throw new Error(`Network response was not ok for trade ID ${tradeId}`);
					}

					const responseData = await response.json();
					console.log("Trade updated: ", responseData);
				})
			);

			fetchTrades(); // Refresh trades after updating
		} catch (error) {
			console.error("Error updating trades:", error);
		}
	};

	const fetchTrades = async () => {
		try {
			const response = await fetch("https://backend-production-41c0.up.railway.app/trades/get", {
				credentials: "include",
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const trades = await response.json();

			setRows(
				trades.map((trade, index) => ({
					...trade,
					tradeId: trade._id,
					välj: false,
					vinstförlust: trade.Profit > 0 ? "Vinst" : "Förlust",
					datum: new Date(trade.Datum).toISOString().slice(0, 10),
					namn: trade.Name,
					storlek: trade.Size.toFixed(1),
					courtage: trade.Courtage.toFixed(1),
					resultat: trade.Profit.toFixed(1),
					resultatprocent: ((trade.Profit / trade.Size) * 100).toFixed(1),
					verktyg: "",
				}))
			);
		} catch (error) {
			console.error("Failed to fetch trades:", error);
		}
	};

	useEffect(() => {
		fetchTrades();
	}, []);

	const uploadPlaybook = async (requestBody) => {
		try {
			const response = await fetch("https://backend-production-41c0.up.railway.app/createPlaybook", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
				body: JSON.stringify(requestBody),
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error("Kunde inte ladda upp Playbook: " + errorText);
			}

			const data = await response.json();
			console.log("Successfully uploaded trade data: ", data);
			toast({
				title: "Trade uppladdad",
				description: "Din trade har lagts till i playbook.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error("Error uploading playbook:", error);
			toast({
				title: "Fel vid uppladdning",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const createNewTrades = async (newTrades) => {
		try {
			const responses = await Promise.all(
				newTrades.map((trade) =>
					fetch("https://backend-production-41c0.up.railway.app/trades/create", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						credentials: "include",
						body: JSON.stringify({
							Name: trade.namn,
							Datum: trade.datum,
							Profit: parseFloat(trade.resultat),
							Courtage: parseFloat(trade.courtage),
							Size: parseFloat(trade.storlek),
						}),
					})
				)
			);

			const data = await Promise.all(responses.map((res) => res.json()));
			fetchTrades(); // Refresh the list after adding new trades
			console.log("New trades created:", data);
		} catch (error) {
			console.error("Error creating new trades:", error);
		}
	};

	useEffect(() => {
		if (refreshTrades) {
			fetchTrades();
			setRefreshTrades(false); // Reset the flag
		}
	}, [refreshTrades]);

	const handleDelete = async (tradeId) => {
		console.log(`Försöker ta bort trade med ID: ${tradeId}`);
		try {
			const response = await fetch(`https://backend-production-41c0.up.railway.app/trades/delete/${tradeId}`, {
				method: "DELETE",
				credentials: "include",
			});

			if (!response.ok) {
				throw new Error("Failed to delete the trade.");
			}

			console.log(`Trade med ID: ${tradeId} har framgångsrikt tagits bort.`);

			// Sätt flaggan för att trigga en omhämtning av trades
			setRefreshTrades(true);
		} catch (error) {
			console.error("Error deleting trade:", error);
		}
	};

	const handleBatchDelete = async () => {
		// Map each selectedRow id to a delete request, creating an array of promises
		const deletePromises = selectedRows.map((tradeId) => handleDelete(tradeId));

		try {
			// Wait for all delete requests to complete
			await Promise.all(deletePromises);

			// Filter out the deleted trades from the rows state all at once
			const updatedRows = rows.filter((row) => !selectedRows.includes(row.id));
			setRows(updatedRows);

			// Reset selectedRows since they have been deleted
			setSelectedRows([]);
		} catch (error) {
			console.error("Error deleting trades:", error);
			// Handle error, such as showing an error message to the user
		}
	};

	const updateData = (newData) => {
		// Set the rows to the new data passed from TaskTable
		setRows(newData);
	};

	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={50} mr={50} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Alla trades
							</Text>
						</HStack>
						<MyMarqueeComponent></MyMarqueeComponent>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<HStack align={"center"} w="auto" spacing={10}>
						<Heading>Alla Trades</Heading>

						
					</HStack>

					<VStack align={"flex-start"} w="100%" > 
    <Box w="100%"> 
							<TaskTable
								uploadPlaybook={uploadPlaybook}
								data={rows}
								updateData={updateData}
								handleDelete={handleDelete}
								fetchTrades={fetchTrades}
								saveTradesEdit={saveTradesEdit}
								onSaveNewRow={createNewTrades}
							></TaskTable>
						</Box>
					</VStack>

					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Allatrades;
