import { Box, Checkbox, Divider, Flex, Heading, Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Imported = ({ title, tooltip, weekStatus, onJournalStatusChange, onCsvStatusChange }) => {
	const handleJournalChange = (date) => {
		const newStatus = {
			...weekStatus,
			[date]: {
				...weekStatus[date],
				journal: !weekStatus[date].journal,
			},
		};
		onJournalStatusChange(newStatus);
	};

	const handleCsvChange = (date) => {
		const newStatus = {
			...weekStatus,
			[date]: {
				...weekStatus[date],
				trade: !weekStatus[date].trade,
			},
		};
		onCsvStatusChange(newStatus);
	};

	const formatDate = (date) =>
		new Date(date).toLocaleDateString("sv-SE", {
			day: "numeric",
			month: "long",
			year: "numeric",
		});

	const formatDayOfWeek = (date) =>
		new Date(date).toLocaleDateString("sv-SE", {
			weekday: "long",
		});
	const isWeekend = (date) => {
		const dayOfWeek = new Date(date).getDay();
		return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Sunday, 6 = Saturday
	};
	const colorWhite = "#fff";

	return (
		<Box
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			minW="200px"
			display="flex"
			justifyContent="center"
		>
			<VStack align={"flex-start"} flex={"1"} mt={0}>
				<Heading fontSize="18" color="#fff" opacity={0.87}>
					{title}
					<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
						<span>
							<Icon mb={1} ml={2} as={AiOutlineInfoCircle} boxSize={18} color="#434A54" />
						</span>
					</Tooltip>
				</Heading>

				<VStack spacing={6}>
					{Object.keys(weekStatus)
						.filter((date) => !isWeekend(date)) // Filter out the weekends
						.map((date, index, filteredArray) => (
							<Box key={index} w={"100%"}>
								<VStack align={"flex-start"} spacing={1}>
									<Flex alignItems="center">
										<Checkbox
											isReadOnly
											sx={{
												".chakra-checkbox__control": {
													_checked: {
														bg: "#05D8A5",
														borderColor: "#05D8A5",
														color: "white",
														"> svg": { display: "block" }, // Ensures the checkmark is visible
													},
												},
											}}
											fontWeight={"medium"}
											mr={5}
											color={colorWhite}
											isChecked={weekStatus[date].journal}
											onChange={() => handleJournalChange(date)}
										>
											Journal
										</Checkbox>
									</Flex>
									<Flex alignItems="center">
										<Checkbox
											isReadOnly
											sx={{
												".chakra-checkbox__control": {
													_checked: {
														bg: "#434A54",
														borderColor: "#434A54",
														color: "white",
														"> svg": { display: "block" }, // Ensures the checkmark is visible
													},
												},
											}}
											fontWeight={"medium"}
											mr={5}
											isChecked={weekStatus[date].trade}
											onChange={() => handleCsvChange(date)}
											color={colorWhite}
										>
											Trades
										</Checkbox>
									</Flex>
									<Text fontSize={12} color="#868686" fontWeight={"medium"}>
										{formatDayOfWeek(date)} - {formatDate(date)}
									</Text>
									{index < filteredArray.length - 1 && (
										<Divider
											alignSelf={"flex-start"}
											borderWidth={2}
											borderRadius={"full"}
											height="20px"
											borderColor={"#fff"}
											orientation="vertical"
											ml={1}
											mt={0}
											mb={0}
										/>
									)}
								</VStack>
							</Box>
						))}
				</VStack>
			</VStack>
		</Box>
	);
};

export default Imported;
