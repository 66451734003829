import { Box, Heading, Icon, SimpleGrid, Tooltip, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
//import SelectButton from "../../buttons/selectbuttons";
import StatsSelected from "../../buttons/statsSelected";

const ImproveDisplay = ({ initialText, tooltip, title, secondTitle, isEditing }) => {
	const [better, setBetter] = useState([]);
	const colorWhite = "#fff";
	const activeColor = "rgba(5, 216, 165, 0.15)";
	const inactiveColor = "white";
	const customtitleColor = "#05D8A5";
	useEffect(() => {
		//console.log("Initial text:", initialText);
		if (initialText) {
			setBetter(initialText);
			localStorage.setItem("better", JSON.stringify(initialText));
		}
	}, [initialText]);

	const handleBetterClick = (buttonTitle) => {
		setBetter((prevBetter) => {
			if (prevBetter.includes(buttonTitle)) {
				return prevBetter.filter((t) => t !== buttonTitle);
			} else {
				return [...prevBetter, buttonTitle];
			}
		});

		localStorage.setItem("better", JSON.stringify([...better, buttonTitle]));
	};

	const buttonTitles = ["Exits", "Sizing", "Förberedelser", "Mentalt", "Greed & Fomo", "Stops", "Entries", "Orderboken"];

	return (
		<Box
			borderBottomWidth="0px"
			borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
			borderLeftWidth="0.5px"
			borderRightWidth="0.5px"
			borderStyle="solid"
			borderTopWidth="0.5px"
			backgroundColor="rgba(29, 32, 35, 0.6)"
			filter="hue-rotate(0deg)"
			opacity="1"
			borderRadius="12px"
			boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
			overflow="hidden"
			p={6}
			ml={0}
			mr={0}
			w={"100%"}
			minWidth="464px"
			height="100%"
			maxHeight={"482px"}
			minHeight="auto"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<Box position="absolute" top={0} left={0} p={6}>
				<VStack align="flex-start" mt={1}>
					<Box>
						<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
							{title}
							<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
								<span>
									<Icon as={AiOutlineInfoCircle} boxSize={18} color="#434A54" ml={2} />
								</span>
							</Tooltip>
						</Heading>
						<Heading fontSize="14" ml={5} fontWeight="medium" color="#868686" opacity={0.87}>
							{secondTitle}
						</Heading>
					</Box>
					<VStack ml={5} mt={5}>
						<SimpleGrid columns={2} columnGap={12} rowGap={14}>
							{buttonTitles.map((buttonTitle) => (
								<StatsSelected
									key={buttonTitle}
									customTitlecolor={better.includes(buttonTitle) ? customtitleColor : "#090A0B"}
									customcolor={better.includes(buttonTitle) ? activeColor : inactiveColor}
									title={buttonTitle}
									onClick={isEditing ? () => handleBetterClick(buttonTitle) : undefined}
									disabled={!isEditing}
								/>
							))}
						</SimpleGrid>
					</VStack>
				</VStack>
			</Box>
		</Box>
	);
};

export default ImproveDisplay;
