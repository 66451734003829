import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, HStack, Icon, Text, Input } from "@chakra-ui/react";
import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useState, useRef, useEffect } from "react";
import { LuChevronDown, LuChevronUp, LuFilter } from "react-icons/lu";
import CSVUpload from "../../../components/buttons/modalselectbrookerbutton";
import EditableCell from "./EditableCell";
import Filters from "./Filters";
import SelectCell from "./Selectcell";
import VerktygsCell from "./Verktygcell";
import styles from "./styles";
import WinLossCell from "./winloss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sv } from "date-fns/locale";

const TaskTable = ({ data, updateData, uploadPlaybook, handleDelete, fetchTrades, saveTradesEdit, onSaveNewRow }) => {
	const [isCsvUploadModalOpen, setIsCsvUploadModalOpen] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [isEditable, setIsEditable] = useState(false);
	const [sorting, setSorting] = useState([{ id: "datum", desc: true }]);
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
	});
	const [startDate, setStartDate] = useState(new Date('2023-01-01'));
	const [endDate, setEndDate] = useState(new Date());
	// BARA FÖR ATT ACCEPTERA NAV MED TAB
	const tableRef = useRef(null);
	useEffect(() => {
		// Define the function that manages the Tab key press
		const handleKeyDown = (event) => {
			if (event.key === "Tab" && !event.shiftKey) {
				event.preventDefault(); // Prevent the default Tab behavior

				// Find all the focusable elements in the table
				const focusableElements = tableRef.current.querySelectorAll("input, button, select, textarea");
				const focusableArray = Array.from(focusableElements);

				const currentElement = document.activeElement; // The currently focused element
				const currentIndex = focusableArray.indexOf(currentElement);

				const nextIndex = (currentIndex + 1) % focusableArray.length; // Increment index by 2
				const nextElement = focusableArray[nextIndex];

				if (nextElement) {
					nextElement.focus(); // Set the focus to the next element
				}
			}
		};

		// Check if the current ref is available to attach the event listener
		if (tableRef.current) {
			tableRef.current.addEventListener("keydown", handleKeyDown);
		}

		// Cleanup function to remove the event listener
		return () => {
			if (tableRef.current) {
				tableRef.current.removeEventListener("keydown", handleKeyDown);
			}
		};
	}, [tableRef.current]);

	const columns = [
		{
			accessorKey: "välj",
			header: "Välj",
			enableSorting: false,
			cell: (info) => <SelectCell {...info} selectedRowIds={selectedRowIds} toggleRowSelected={toggleRowSelected} />,
			width: "7%",
		},
		{
			accessorKey: "vinstförlust",
			header: "Status",
			cell: (info) => <WinLossCell resultat={info.row.original.resultat} />,
			enableSorting: false,
			width: "7%",
		},
		{
			accessorKey: "datum",
			header: "Datum",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "10%",
		},
		{
			accessorKey: "namn",
			header: "Namn",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "21%",
		},
		{
			accessorKey: "storlek",
			header: "Storlek",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "8%",
		},
		{
			accessorKey: "courtage",
			header: "Courtage",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "8%",
		},
		{
			accessorKey: "resultat",
			header: "Resultat (KR)",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "8%",
		},
		{
			accessorKey: "resultatprocent",
			header: "Resultat (%)",
			cell: (info) => <EditableCell {...info} isEditable={isEditable} />,
			width: "8%",
		},
		{
			accessorKey: "verktyg",
			header: "Verktyg",
			cell: (info) => <VerktygsCell {...info} isEditable={isEditable} uploadPlaybook={uploadPlaybook} handleSaveAllEdits={saveTradesEdit} />,
			enableSorting: false,
			width: "15%",
		},
	];

	const toggleEdit = () => {
		setIsEditable(!isEditable);
		if (isEditable) {
			// Separate new rows (without tradeId) from updated rows (with tradeId)
			const newRows = data.filter((row) => row.__isDirty && !row.tradeId);
			const updatedRows = data.filter((row) => row.__isDirty && row.tradeId);

			// If there are new rows, call onSaveNewRow to create them in the backend
			if (newRows.length > 0) {
				onSaveNewRow(newRows);
			}

			// If there are updated rows, call saveTradesEdit to update them in the backend
			if (updatedRows.length > 0) {
				saveTradesEdit(updatedRows);
			}
		}
	};

	const addNewRow = () => {
		const today = new Date().toISOString().slice(0, 10); // "YYYY-MM-DD" format
		const newRow = {
			välj: false,
			vinstförlust: "",
			datum: today,
			namn: "Instrument",
			storlek: "0",
			courtage: "0",
			resultat: "0",
			resultatprocent: "0",
			verktyg: "",
			__isDirty: true, // Mark new row as dirty for immediate edit
		};

		const newData = [newRow, ...data];
		updateData(newData); // Call the passed updateData to update the parent component's state
		setIsEditable(true);
	};

	const toggleRowSelected = (tradeId) => {
		setSelectedRowIds((prevSelected) => {
			// Kontrollera om tradeId redan är vald
			if (prevSelected.includes(tradeId)) {
				return prevSelected.filter((id) => id !== tradeId); // Ta bort om den redan är vald
			} else {
				return [...prevSelected, tradeId]; // Lägg till om den inte är vald
			}
		});
	};

	const [filteredData, setFilteredData] = useState(data);

	useEffect(() => {
		// Filter the data based on the date range
		const filtered = data.filter((row) => {
			const rowDate = new Date(row.datum);
			return rowDate >= startDate && rowDate <= endDate;
		});
		setFilteredData(filtered);
	}, [data, startDate, endDate]);
	const table = useReactTable({
		data: filteredData,
		columns,
		state: { columnFilters, pagination, sorting },
		getCoreRowModel: getCoreRowModel(),
		onPaginationChange: setPagination,
		getFilteredRowModel: getFilteredRowModel(),
		onSortingChange: setSorting,
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		columnResizeMode: "onChange",
		meta: {
			updateData: (rowIndex, columnId, value) => {
				const newData = data.map((row, index) => {
					if (index === rowIndex) {
						return { ...row, [columnId]: value, __isDirty: true };
					}
					return row;
				});
				updateData(newData); // Update the entire data set in the parent component
			},
		},
	});

	const handleBatchDelete = async () => {
		try {
			await Promise.all(selectedRowIds.map((tradeId) => handleDelete(tradeId)));

			// Uppdatera datan efter att rader har tagits bort
			const updatedData = data.filter((row) => !selectedRowIds.includes(row.tradeId));
			updateData(updatedData);
			setSelectedRowIds([]); // Rensa urvalet
		} catch (error) {
			console.error("Error deleting trades:", error);
		}
	};

	return (
		<Box w={"100%"} ref={tableRef} sx={styles.global}>
			<HStack mb={6} spacing={4} display={"flex"} align="center">
				<Filters columnFilters={columnFilters} setColumnFilters={setColumnFilters} />
				<Button size="xs" onClick={addNewRow}>
					Lägg till trade
				</Button>
				<Button size="xs" onClick={() => setIsCsvUploadModalOpen(true)}>
					Ladda upp CSV
				</Button>
				{isEditable ? (
					<Button
						backgroundColor={"rgba(5, 216, 165, 0.15)"}
						textColor="#05D8A5"
						_hover={{ backgroundColor: "rgba(5, 216, 165, 0.3)" }}
						cursor="pointer"
						onClick={toggleEdit}
						size={"xs"}
					>
						Spara
					</Button>
				) : (
					<Button
						background="rgba(0, 176, 237, 0.15)"
						textColor="rgba(0, 176, 237)"
						_hover={{ backgroundColor: "rgba(0, 176, 237, 0.3)" }}
						leftIcon={<EditIcon />}
						cursor="pointer"
						onClick={toggleEdit}
						size={"xs"}
					>
						Redigera Rader
					</Button>
				)}
				<DatePicker
					locale={sv}
					selected={startDate}
					onChange={(date) => setStartDate(date)}
					dateFormat="yyyy-MM-dd"
					customInput={
						<Input
							size="sm"
							type="text"
							backgroundColor={"transparent"}
							borderWidth="1px"
							borderColor="#1C1E22"
							_hover={{ bg: "transparent", color: "white" }}
							_focus={{ bg: "transparent", color: "white" , borderColor: "#868686"}}
							_active={{ bg: "transparent", color: "white", borderColor: "#868686" }}
							color={"#868686"}
							variant="filled"
							placeholder="Välj datum"
							borderRadius={5}
						/>
					}
				/>
				<DatePicker
					locale={sv}
					selected={endDate}
					onChange={(date) => setEndDate(date)}
					dateFormat="yyyy-MM-dd"
					customInput={
						<Input
							size="sm"
							type="text"
							backgroundColor={"transparent"}
							borderWidth="1px"
							borderColor="#1C1E22"
							_hover={{ bg: "transparent", color: "white" }}
							_focus={{ bg: "transparent", color: "white" , borderColor: "#868686"}}
							_active={{ bg: "transparent", color: "white", borderColor: "#868686" }}
							color={"#868686"}
							variant="filled"
							placeholder="Välj datum"
							borderRadius={5}
						/>
					}
				/>
				<CSVUpload
					onSuccess={() => {
						fetchTrades();
						setIsCsvUploadModalOpen(false);
					}}
					isOpen={isCsvUploadModalOpen}
					onClose={() => setIsCsvUploadModalOpen(false)}
				/>

				{selectedRowIds.length > 0 && (
					<Button onClick={handleBatchDelete} colorScheme="red" size="xs">
						Radera
					</Button>
				)}
			</HStack>
			<Box bg="transparent" w="full">
				{table.getHeaderGroups().map((headerGroup) => (
					<Box display="flex" w="full" bg="transparent" key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<Box
								position="relative"
								display="flex"
								w="full"
								alignItems="center"
								color="#868686"
								fontWeight="bold"
								fontSize="xs"
								ml={4}
								mb={4}
								key={header.id}
								style={{ minWidth: header.column.columnDef.width }}
							>
								{header.column.columnDef.header}
								{header.column.getCanSort() && <Icon as={LuFilter} mx={3} fontSize={14} onClick={header.column.getToggleSortingHandler()} />}
								{
									{
										asc: <Icon as={LuChevronDown} mx={3} color="#05d8a5" fontSize={14} />,
										desc: <Icon as={LuChevronUp} mx={3} color={"#05d8a5"} fontSize={14} />,
									}[header.column.getIsSorted()]
								}
							</Box>
						))}
					</Box>
				))}

				{table.getRowModel().rows.map((row) => (
					<Box
						display="flex"
						w="full"
						bg={selectedRowIds.includes(row.id) ? "rgba(255, 255, 255, 0.05)" : row.index % 2 === 0 ? "transparent" : "#050606"}
						key={row.id}
						pt={3}
						pb={3}
					>
						{row.getVisibleCells().map((cell) => (
							<Box
								ml={4}
								flex={`0 0 ${cell.column.columnDef.minWidth}px`}
								key={cell.id}
								display="flex"
								alignItems="center"
								fontWeight={"medium"}
								style={{ minWidth: cell.column.columnDef.width }}
								textAlign="left"
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext(), {
									toggleRowSelected,
								})}
							</Box>
						))}
					</Box>
				))}
			</Box>

			<br />
			<Text mb={2}>
				Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
			</Text>
			<ButtonGroup size="sm" isAttached variant="outline">
				<Button
					backgroundColor={"transparent"}
					borderWidth="1px"
					borderColor="#1C1E22"
					color={"#05d8a5"}
					onClick={() => table.previousPage()}
					isDisabled={!table.getCanPreviousPage()}
				>
					{"<"}
				</Button>
				<Button
					backgroundColor={"transparent"}
					borderWidth="1px"
					borderColor="#1C1E22"
					color={"#05d8a5"}
					onClick={() => table.nextPage()}
					isDisabled={!table.getCanNextPage()}
				>
					{">"}
				</Button>
			</ButtonGroup>
		</Box>
	);
};

export default TaskTable;
