import React, { useEffect, useState } from "react";
import {
	ChakraProvider,
	Box,
	Button,
	Flex,
	HStack,
	VStack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Heading,
	Text,
	Tooltip,
	Icon,
	Table,
	Tbody,
	Tr,
	Td,
	useBreakpointValue,
	extendTheme,
	Image,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { differenceInBusinessDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import Informationcard from "../../components/cards/informationcard";
import Smallcardtwo from "../../components/cards/smallcardtwo";
import Barchart from "../../components/charts/barchart";
import Mentalitetchart from "../../components/charts/mentalitetchart";
import LinechartComponent from "../../components/charts/linechart";
import PiechartComponent from "../../components/charts/piechart";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import Sidebar from "../Sidebar/sidebar";
import SpiderWebChart from "../../components/charts/spiderweb";
import { handleLogout } from "../../hooks/logout";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const calculateAverageWinLoss = (data) => {
	const tradesByDate = data.reduce((acc, item) => {
		const date = item.Datum.split("T")[0];
		if (!acc[date]) {
			acc[date] = [];
		}
		acc[date].push(item);
		return acc;
	}, {});

	const result = Object.keys(tradesByDate).map((date) => {
		const trades = tradesByDate[date];
		let winSum = 0,
			winCount = 0,
			lossSum = 0,
			lossCount = 0,
			totalProfit = 0;
		trades.forEach((trade) => {
			if (trade.Profit > 0) {
				winSum += trade.Profit;
				winCount++;
			} else if (trade.Profit < 0) {
				lossSum += Math.abs(trade.Profit);
				lossCount++;
			}
			totalProfit += trade.Profit;
		});
		const averageWin = winCount > 0 ? winSum / winCount : 0;
		const averageLoss = lossCount > 0 ? lossSum / lossCount : 0;

		const winRate = winCount / trades.length;
		const lossRate = lossCount / trades.length;
		const expectancyValue = winRate * averageWin + lossRate * -averageLoss;
		const profitFactor = lossCount > 0 ? winSum / lossSum : null; // Only calculate if there are losing trades

		return {
			Date: date,
			TotalProfit: totalProfit,
			AverageWinAmount: averageWin,
			AverageLossAmount: averageLoss,
			WinRate: winRate,
			LossRate: lossRate,
			ExpectancyValue: expectancyValue,
			ProfitFactor: profitFactor,
			LossCount: lossCount // Include lossCount for further filtering
		};
	});

	return result;
};

const Trender = () => {
	const navigate = useNavigate();
	const [tabIndex, setTabIndex] = useState(0);
	const [tradeMetrics, setTradeMetrics] = useState([]);
	const [totalWinrate, setTotalWinrate] = useState(0);
	const [averagemetrics, setAverageMetrics] = useState([]);
	const [totalLossrate, setTotalLossrate] = useState(0);
	const [totalEvenrate, setTotalEvenrate] = useState(0);
	const [cumulativePnLData, setCumulativePnLData] = useState([]);
	const [expectancyarrayData, setExpectancyData] = useState([]);
	const [profitFactorData, setProfitFactorData] = useState([]);
	const [profitData, setProfitData] = useState([]);
	const [activeInterval, setActiveInterval] = useState("");
	const [averageWin, setAverageWin] = useState(0);
	const [averageLoss, setAverageLoss] = useState(0);
	const [expectancy, setExpectancy] = useState(0);
	const [profitFactor, setProfitFactor] = useState(0);
	const [summedArray, setSummedArray] = useState([]);
	const [mentalityArray, setMentalityArray] = useState([]);
	const [betterCount, setBetterCount] = useState({});
	const [satisfiedChartData, setSatisfiedChartData] = useState({
		winValue: 0,
		lossValue: 0,
		evenValue: 0,
	});

	const [bestTrade, setBestTrade] = useState(null);
	const [worstTrade, setWorstTrade] = useState(null);
	const [SQN, setSQN] = useState(0);
	const [fullKelly, setFullKelly] = useState(0);
	const [journaler1w, setJournaler1w] = useState(0);
	const [journaler1m, setJournaler1m] = useState(0);
	const [journaler3m, setJournaler3m] = useState(0);
	const [showSeries, setShowSeries] = useState({
		oneWeek: false,
		oneMonth: false,
		threeMonth: false,
	});
	const [averageExpectancyValue, setAverageExpectancy] = useState(0);
	const [averageProfitFactorValue, setAverageProfitFactor] = useState(0);
	
	const [intervalData, setIntervalData] = useState({
		"1w": {},
		"1m": {},
		"3m": {},
	});

	const colorWhitetwo = "#868686";

	const fetchAndSetIntervalData = async (interval) => {
		try {
			const response = await fetch(`https://backend-production-41c0.up.railway.app/trender?interval=${interval}`, {
				method: "GET",
				credentials: "include",
			});
			if (response.ok) {
				const { data, journalData } = await response.json();
				const averageMetrics = calculateAverageWinLoss(data);

				const losingTrades = data.filter((trade) => trade.Profit < 0);
				const winningTrades = data.filter((trade) => trade.Profit > 0);

				const averageLoss = Math.abs(losingTrades.reduce((acc, trade) => acc + trade.Profit, 0) / losingTrades.length);
				const averageWin = winningTrades.reduce((acc, trade) => acc + trade.Profit, 0) / winningTrades.length;

				const profitFactorData = averageMetrics.map((metric) => ({
					x: new Date(metric.Date).getTime(),
					y: metric.ProfitFactor,
					z: metric.Date,
				}));
				const expectancyData = averageMetrics.map((metric) => ({
					x: new Date(metric.Date).getTime(),
					y: metric.ExpectancyValue,
					z: metric.Date,
				}));

				const expectancyValues = expectancyData.map((d) => d.y);
				const averageExpectancy = expectancyValues.reduce((sum, val) => sum + val, 0) / expectancyValues.length || 0;

				const profitFactorValues = profitFactorData.map((d) => d.y);
				const averageProfitFactor = profitFactorValues.reduce((sum, val) => sum + val, 0) / profitFactorValues.length || 0;

				const totalTrades = data.length;
				const winTrades = data.filter((trade) => trade.Profit > 0).length;
				const winrate = totalTrades > 0 ? winTrades / totalTrades : 0;

				const today = new Date();
				let workdaysInInterval = 0;

				switch (interval) {
					case "1w":
						workdaysInInterval = calculateBusinessDays(today, 5);
						break;
					case "1m":
						workdaysInInterval = calculateBusinessDays(today, 30);
						break;
					case "3m":
						workdaysInInterval = calculateBusinessDays(today, 90);
						break;
					default:
						workdaysInInterval = 0;
				}

				const journalCount = journalData.length;
				const journalPercentage = ((journalCount / workdaysInInterval) * 100).toFixed(2);

				setIntervalData((prevData) => ({
					...prevData,
					[interval]: {
						averageWin,
						averageLoss,
						expectancy: averageExpectancy,
						profitFactor: averageProfitFactor,
						journalPercentage,
						winrate,
					},
				}));

				console.log(`[Interval: ${interval}] Data:`, {
					averageWin,
					averageLoss,
					expectancy: averageExpectancy,
					profitFactor: averageProfitFactor,
					journalPercentage,
					winrate,
				});
			} else {
				throw new Error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		const fetchDataForIntervals = async () => {
			await fetchAndSetIntervalData("1w");
			await fetchAndSetIntervalData("1m");
			await fetchAndSetIntervalData("3m");
		};

		fetchDataForIntervals();
	}, []);

	useEffect(() => {
		const fetchDataForIntervals = async () => {
			await fetchAndSetIntervalData("1w");
			await fetchAndSetIntervalData("1m");
			await fetchAndSetIntervalData("3m");
		};

		fetchDataForIntervals();
	}, []);

	useEffect(() => {
		const fetchDataForIntervals = async () => {
			await fetchAndSetIntervalData("1w");
			await fetchAndSetIntervalData("1m");
			await fetchAndSetIntervalData("3m");
		};

		fetchDataForIntervals();
	}, []);

	const calculateBusinessDays = (startDate, daysBack) => {
		const endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() - daysBack);
		return differenceInBusinessDays(startDate, endDate);
	};

	const fetchTrades = async (interval) => {
		setActiveInterval(interval);
		setShowSeries({
			oneWeek: false,
			oneMonth: false,
			threeMonth: false,
		});
		try {
			const response = await fetch(`https://backend-production-41c0.up.railway.app/trender?interval=${interval}`, {
				method: "GET",
				credentials: "include",
			});
			if (response.ok) {
				const { data, journalData } = await response.json();

				data.sort((a, b) => new Date(a.Datum) - new Date(b.Datum));
				let cumulativeWins = 0;
				let cumulativeWinAmount = 0;
				let cumulativeLosses = 0;
				let cumulativePnL = 0;
				let cumulativeLossAmount = 0;
				let cumulativeEvens = 0;

				const today = new Date();
				let workdaysInInterval = 0;

				switch (interval) {
					case "1w":
						workdaysInInterval = calculateBusinessDays(today, 5);
						break;
					case "1m":
						workdaysInInterval = calculateBusinessDays(today, 30);
						break;
					case "3m":
						workdaysInInterval = calculateBusinessDays(today, 90);
						break;
					default:
						workdaysInInterval = 0;
				}

				const journalCount = journalData.length;
				const journalPercentage = ((journalCount / workdaysInInterval) * 100).toFixed(2);

				if (interval === "1w") setJournaler1w(journalPercentage);
				if (interval === "1m") setJournaler1m(journalPercentage);
				if (interval === "3m") setJournaler3m(journalPercentage);

				const averageMetrics = calculateAverageWinLoss(data);
				setAverageMetrics(averageMetrics);

				const profitFactorData = averageMetrics
				.filter((metric) => metric.LossCount > 0) // Filter out entries with less than 2 losing trades
				.map((metric) => ({
					x: new Date(metric.Date).getTime(),
					y: metric.ProfitFactor,
					z: metric.Date,
				}));
				setProfitFactorData(profitFactorData);

				const expectancyData = averageMetrics.map((metric) => {
					const baseDate = new Date(metric.Date).getTime();
					const expectancy = metric.ExpectancyValue;
					return {
						x: baseDate,
						y: expectancy,
						z: metric.Date,
					};
				});
				setExpectancyData(expectancyData);

				const lastExpectancyValue = expectancyData[expectancyData.length - 1]?.y || 0;

				let bestTrade = null;
				let worstTrade = null;

				averageMetrics.forEach((metric) => {
					if (!bestTrade || metric.TotalProfit > bestTrade.TotalProfit) {
						bestTrade = metric;
					}
					if (!worstTrade || metric.TotalProfit < worstTrade.TotalProfit) {
						worstTrade = metric;
					}
				});

				setBestTrade(bestTrade);
				setWorstTrade(worstTrade);

				const fetchedTradeMetrics = data.map((item, index) => {
					const profit = item.Profit;
					let isWin = profit > 0;
					let isLoss = profit < 0;
					let isEven = profit === 0;

					cumulativePnL += profit;

					if (isWin) {
						cumulativeWins++;
						cumulativeWinAmount += profit;
					} else if (isLoss) {
						cumulativeLosses++;
						cumulativeLossAmount += profit;
					} else if (isEven) {
						cumulativeEvens++;
					}

					let averageWin = cumulativeWins > 0 ? cumulativeWinAmount / cumulativeWins : 0;
					let averageLoss = cumulativeLosses > 0 ? Math.abs(cumulativeLossAmount) / cumulativeLosses : 0;
					let winRate = cumulativeWins / (index + 1);
					let lossRate = cumulativeLosses / (index + 1);

					return {
						tradeNumber: index + 1,
						profit: profit,
						averageWin: averageWin,
						averageLoss: averageLoss,
						cumulativePnL: cumulativePnL,
						date: item.Datum,
						winRate: winRate,
						lossRate: lossRate,
					};
				});
				//  R-multiples
				const calculateRMultiples = (trades, Risk = 0.05) => {
					return trades.map((data) => {
						if (data.Size * Risk !== 0) {
							return data.Profit / (data.Size * Risk);
						} else {
							return 0;
						}
					});
				};

				//  R-Expectancy
				const calculateRExpectancy = (rMultiples) => {
					const sum = rMultiples.reduce((acc, val) => acc + val, 0);
					return sum / rMultiples.length;
				};

				//  Squared Differences for each R-Multiple
				const calculateSquaredDifferences = (rMultiples, rExpectancy) => {
					return rMultiples.map((rMultiple) => {
						const difference = rMultiple - rExpectancy;
						return Math.pow(difference, 2);
					});
				};

				//  Average of squared differences
				const calculateAverageSquaredDifference = (squaredDifferences) => {
					const sum = squaredDifferences.reduce((acc, val) => acc + val, 0);
					return sum / squaredDifferences.length;
				};

				const rMultiples = calculateRMultiples(data);
				const rExpectancy = calculateRExpectancy(rMultiples);
				const squaredDifferences = calculateSquaredDifferences(rMultiples, rExpectancy);
				const averageSquaredDifference = calculateAverageSquaredDifference(squaredDifferences);
				const Standardavikelsen = Math.sqrt(averageSquaredDifference);
				const SQN = (rExpectancy / Standardavikelsen) * Math.sqrt(fetchedTradeMetrics.length);

				setSQN(SQN);

				const colors = ["#63B3ED", "#F6E05E", "#D53F8C", "#B794F4", "#05D8A5", "#E53E3E"];
				// Process 'better' values from journalData
				const betterFrequency = {};
				journalData.forEach((entry) => {
					entry.better.forEach((betterItem) => {
						betterFrequency[betterItem] = (betterFrequency[betterItem] || 0) + 1;
					});
				});

				// Convert the frequency map to an array of objects for Highcharts
				const betterChartData = Object.keys(betterFrequency).map((key, index) => ({
					name: key,
					y: betterFrequency[key],
					color: colors[index % colors.length], // Cycle through the colors array
				}));

				setBetterCount(betterChartData);

				// Process 'grade' values from journalData
				const gradeCounts = { Missnöjd: 0, Lagom: 0, Nöjd: 0 };
				journalData.forEach((entry) => {
					// Classify entries with no grade or a grade of '0' as 'Lagom'
					const grade = entry.grade && entry.grade !== "0" ? entry.grade : "Lagom";
					gradeCounts[grade] = (gradeCounts[grade] || 0) + 1;
				});

				// Calculate total number of entries
				const totalEntries = journalData.length;

				// Create an object for the Satisfiedchart
				const gradeChartData = {
					winValue: (gradeCounts["Nöjd"] / totalEntries) * 100,
					lossValue: (gradeCounts["Missnöjd"] / totalEntries) * 100,
					evenValue: (gradeCounts["Lagom"] / totalEntries) * 100,
				};
				setSatisfiedChartData(gradeChartData);

				// Create a map of trade and date
				const profitByDate = data.reduce((acc, item) => {
					const date = item.Datum.split("T")[0];
					acc[date] = item.Profit;
					return acc;
				}, {});

				const extractedMentalityArray = journalData.map((item) => ({
					x: Date.parse(item.date.split("T")[0]), // Convert the date string to a timestamp
					y: item.mentality ? parseInt(item.mentality, 10) : 1, // Use the mentality value or default to 1
					z: item.date.split("T")[0], // Extract the date part and assign it to z
				}));

				setMentalityArray(extractedMentalityArray);

				let totalWinrate = cumulativeWins / (cumulativeWins + cumulativeLosses);
				let totalLossrate = cumulativeLosses / (cumulativeWins + cumulativeLosses);
				let totalEvenrate = cumulativeEvens / (cumulativeWins + cumulativeLosses);

				// First, map the data to include all entries
				let allEntries = data.map((item, index) => {
					const date = new Date(item.Datum).getTime(); // Convert Datum string to Unix timestamp
					const cumulativePnL = fetchedTradeMetrics[index].cumulativePnL; // Retrieve corresponding cumulativePnL value
					return {
						x: date, // Use date as x-value
						y: cumulativePnL,
						z: item.Datum.split("T")[0], // Split to get date part for z-value
					};
				});

				// Then, reduce the mapped data to only keep the last entry for each date
				const cumulativePnLData = Object.values(
					allEntries.reduce((acc, item) => {
						acc[item.z] = item; // This will overwrite previous entries with the same date, leaving only the last one
						return acc;
					}, {})
				);

				const profitData = data.map((item, index) => {
					const baseDate = new Date(item.Datum).getTime(); // Basdatumet konverterat till Unix-tidstämpel
					const dateVariation = index * 1000; // Lägg till 1 sekund för varje efterföljande datapunkt
					const date = baseDate + dateVariation; // Skapa en unik tidsstämpel för varje datapunkt
					const profit = fetchedTradeMetrics[index].profit; // Hämta motsvarande profit värde
					return {
						x: date, // Använd det modifierade datumet som x-värde
						y: profit,
						z: item.Datum.split("T")[0], // Inkludera det ursprungliga datumet som en sträng i 'z'
					};
				});

				setProfitData(profitData);
				setTradeMetrics(fetchedTradeMetrics);
				setTotalWinrate(totalWinrate);
				setTotalLossrate(totalLossrate);
				setTotalEvenrate(totalEvenrate);
				setCumulativePnLData(cumulativePnLData);

				setProfitData(profitData);

				// Filter out winning and losing trades
				const winningTrades = fetchedTradeMetrics.filter((item) => item.profit > 0);
				const losingTrades = fetchedTradeMetrics.filter((item) => item.profit < 0);
				// Calculate average win and loss
				const averageWin = winningTrades.reduce((acc, trade) => acc + trade.profit, 0) / winningTrades.length;
				const averageLoss = Math.abs(losingTrades.reduce((acc, trade) => acc + trade.profit, 0) / losingTrades.length);
				// Ensure the average loss is not zero to avoid division by zero
				const riskRewardRatio = averageLoss !== 0 ? averageWin / averageLoss : 0;

				// Calculate the Kelly Percentage
				const kellyPercentage = totalWinrate - (1 - totalWinrate) / riskRewardRatio;
				// Calculate Fractional Kelly

				setFullKelly(kellyPercentage);

				// Update state with the calculated values
				setAverageWin(averageWin);
				setAverageLoss(averageLoss);
				// After setting averageLoss in fetchTrades

				setExpectancy(expectancyData[expectancyData.length - 1].y);
				setProfitFactor(profitFactorData[profitFactorData.length - 1].y);

				const averageExpectancyValue = expectancyData.reduce((sum, d) => sum + d.y, 0) / expectancyData.length || 0;
				const averageProfitFactorValue = profitFactorData.reduce((sum, d) => sum + d.y, 0) / profitFactorData.length || 0;

				setAverageExpectancy(averageExpectancyValue);
				setAverageProfitFactor(averageProfitFactorValue);
				{
					/*console.log("averageWin", averageWin);
				console.log("averageLoss", averageLoss);
				console.log("expectancy", expectancyData[expectancyData.length - 1].y);
				console.log("profitData", profitFactorData[profitFactorData.length - 1].y); 		*/
				}
			} else {
				throw new Error("Något gick fel vid hämtning av data");
			}
		} catch (error) {
			// //console.error("Fel vid hämtning av data:", error);
		}
	};

	useEffect(() => {
		// Extract dates from mentalityArray for comparison
		const mentalityDates = mentalityArray.map((item) => item.z);

		// Filter profitData to include only those entries that have a corresponding mentality value
		const filteredProfitData = profitData.filter((item) => mentalityDates.includes(item.z));

		// Proceed with the original summation logic, but using the filtered profit data
		const sumByZ = filteredProfitData.reduce((acc, item) => {
			if (acc[item.z]) {
				acc[item.z] += item.y;
			} else {
				acc[item.z] = item.y;
			}
			return acc;
		}, {});

		// Convert the result into an array of objects
		const summedArray = Object.entries(sumByZ).map(([z, y]) => ({
			x: Date.parse(z),
			y,
		}));

		// Use the summedArray as needed in your component
		setSummedArray(summedArray);
	}, [profitData, mentalityArray]); // Include mentalityArray in the dependency array

	useEffect(() => {
		const hash = window.location.hash;
		const hashToTab = {
			"#handel": 0,
			"#journal": 1,
			// Add more mappings as needed
		};
		setTabIndex(hashToTab[hash] ?? 0);
	}, []);

	const intervals = [
		{ min: 0, max: 1, label: "Svårt System" },
		{ min: 1.01, max: 2, label: "Average System" },
		{ min: 2.01, max: 3, label: "Bra System" },
		{ min: 3.01, max: 5, label: "Bättre System" },
		{ min: 5.01, max: 7, label: "Utmärkt System" },
		{ min: 7.01, max: 10, label: "Top Notch System" },
	];

	const findIntervalForSQN = (sqn) => {
		// Check if the SQN is above the range of the last defined interval
		if (sqn > intervals[intervals.length - 1].max) {
			return intervals.length - 1; // Return the index of the last interval
		}
		// Otherwise, find the interval as before
		return intervals.findIndex((interval) => sqn >= interval.min && sqn <= interval.max);
	};

	const hStackMaxWidth = useBreakpointValue({
		base: "100%", // 100% of the screen width on small devices
		md: "100%", // 100% of the screen width on medium devices
		lg: "100%", // 100% of the screen width on large devices
		xl: "100%", // 100% of the screen width on extra-large devices
	});

	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack w={"100%"} flex={1} alignItems="flex-start" minWidth="max-content" gap="7" ml={50} mr={50} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Trender
							</Text>
						</HStack>
						<MyMarqueeComponent></MyMarqueeComponent>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<Tabs w={"100%"} isFitted variant="soft-rounded" index={tabIndex} onChange={(index) => setTabIndex(index)}>
						<TabList mb="1em">
							<Tab color={"#868686"} _selected={{ color: "#fff" }}>
								Handel
							</Tab>

							{/* HÄMTNING CSV*/}
							<Button
								borderBottomWidth="0px"
								backgroundColor={activeInterval === "1w" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)"}
								filter="hue-rotate(0deg)"
								opacity="1"
								color={"white"}
								boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
								ml={8}
								onClick={() => fetchTrades("1w")}
								size={"sm"}
								fontWeight={"medium"}
								_hover={{
									backgroundColor: activeInterval === "1w" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)",
								}}
							>
								1 Vecka
							</Button>
							<Button
								borderBottomWidth="0px"
								backgroundColor={activeInterval === "1m" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)"}
								filter="hue-rotate(0deg)"
								opacity="1"
								color={"white"}
								boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
								ml={8}
								onClick={() => fetchTrades("1m")}
								size={"sm"}
								fontWeight={"medium"}
								_hover={{
									backgroundColor: activeInterval === "1m" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)",
								}}
							>
								1 Månad
							</Button>
							<Button
								borderBottomWidth="0px"
								backgroundColor={activeInterval === "3m" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)"}
								filter="hue-rotate(0deg)"
								opacity="1"
								color={"white"}
								boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
								ml={8}
								onClick={() => fetchTrades("3m")}
								size={"sm"}
								fontWeight={"medium"}
								_hover={{
									backgroundColor: activeInterval === "3m" ? "#05D8A5" : "rgba(29, 32, 35, 0.6)",
								}}
							>
								3 Månader
							</Button>
							<Tab _selected={{ color: "#fff" }} color={"#868686"} panelId="journal">
								Journal
							</Tab>
						</TabList>
						<TabPanels w={"100%"}>
							<TabPanel w={"100%"}>
								<VStack w={"100%"} columns={2} spacing={10}>
									<HStack w={"100%"} spacing={10}>
										<Box w={"100%"}>
											<LinechartComponent
												title={"Utveckling"}
												secondTitle={"Cumulative PnL / dag "}
												tooltip={
													"Visar den samlade nettovinsten eller nettoförlusten över perioden. Y-axeln är därmed resultat i kr, samt X-axeln antalet trades."
												}
												data={cumulativePnLData}
												prefix="kr"
												lastValueKey="y"
												roundLastValue={1}
												defaultChartType={"column"}
											></LinechartComponent>
										</Box>
										<Box w={"100%"}>
											<LinechartComponent
												title={"Profit Factor"}
												secondTitle={"Lönsamheten / trade"}
												data={profitFactorData}
												prefix=" "
												tooltip={
													"Mått på lönsamheten i en handelsstrategi och beräknas genom att dela den totala vinsten med den totala förlusten. En hög profit factor, till exempel över 1, anses vara önskvärd eftersom det indikerar att den totala vinsten är större än den totala förlusten. OBS! Dagar med 0 förlusttrades exluderar profitfactor för grafen då detta inte går att räkna ut."
												}
												lastValueKey="y"
												roundLastValue={2}
												defaultChartType={"areaspline"}
											></LinechartComponent>
										</Box>
									</HStack>

									<HStack w={"100%"} alignItems={"start"} spacing={10}>
										<Box w={"100%"}>
											<HStack spacing={19} w={"100%"} justifyContent={"space-between"}>
												<Box>
													<PiechartComponent
														title={"Winrate %"}
														valueoneFormal={totalWinrate}
														valuetwoFormal={totalLossrate}
														valueothreeFormal={totalEvenrate}
														valueone={(totalWinrate * 100).toFixed(2) + " %"}
														valuetwo={(totalLossrate * 100).toFixed(2) + " %"}
														valuethree={(totalEvenrate * 100).toFixed(2) + " %"}
														labelone={"vinster"}
														labeltwo={"förluster"}
														valueoneName={"Vinster"}
														valuetwoName={"Förluster"}
														valuethreeName={"Even"}
														labelthree={"even"}
														customHeight={"256px"}
														customWidth={"458px"}
														tooltip={
															"Andelen vinnande affärer i förhållande till det totala antalet affärer. En hög winrate, till exempel över 50%, är generellt sett önskvärd eftersom det indikerar att fler affärer är vinnande än förlorande."
														}
													></PiechartComponent>
												</Box>
												<VStack spacing={10} w={"100%"}>
													<Smallcardtwo
														title="Sämsta dag"
														content={
															worstTrade ? (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																		alignItems: "center",
																		width: "100%",
																	}}
																>
																	<span
																		style={{
																			fontSize: "20px",
																			fontWeight: "bold",
																		}}
																	>
																		{worstTrade.TotalProfit.toFixed(2)} kr
																	</span>
																	<span
																		style={{
																			fontSize: "12px",
																			fontWeight: "bold",
																			color: "#434A54",
																		}}
																	>
																		{worstTrade.Date}
																	</span>
																</div>
															) : (
																"Ingen data"
															)
														}
														buttonText="Ladda upp CSV"
														tooltip="Sämsta dagens resultat för perioden."
														customWidth="229px"
														customHeight="108px"
													/>
													<Smallcardtwo
														title="Bästa dag"
														content={
															bestTrade ? (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																		alignItems: "center",
																		width: "100%",
																	}}
																>
																	<span
																		style={{
																			fontSize: "20px",
																			fontWeight: "bold",
																		}}
																	>
																		{bestTrade.TotalProfit.toFixed(1)} kr
																	</span>
																	<span
																		style={{
																			fontSize: "12px",
																			fontWeight: "bold",
																			color: "#434A54",
																		}}
																	>
																		{bestTrade.Date}
																	</span>
																</div>
															) : (
																"Ingen data"
															)
														}
														buttonText="Ladda upp CSV"
														tooltip="Bästa dagens resultat för perioden."
														customWidth="229px"
														customHeight="108px"
													/>
												</VStack>
											</HStack>

											<Box w={"100%"} mt={10}>
												<LinechartComponent
													title={"Trades"}
													secondTitle={"PnL / trade"}
													groupDataByDate={false}
													data={profitData}
													customvalue={12}
													lastValueKey="y"
													prefix="kr"
													roundLastValue={1}
													tooltip={
														"Visar en sammanfattning av de affärer som genomförts under aktuell handelsdag. Det inkluderar information om både vinst (W) och förlust (L) för varje enskild trade. Används för att få en översiktlig bild av hur handeln har utvecklats över perioden."
													}
													defaultChartType={"column"}
												></LinechartComponent>
											</Box>
											<Box w={"100%"} mt={10}>
												<Box w={"100%"} mt={10}>
													<SpiderWebChart
														tooltip={"Spiderweb-grafen visar hur din handelsstrategi presterar på olika områden som vinstfrekvens, genomsnittlig vinst, genomsnittlig förlust, genomsnittlig förväntad avkastning och genomsnittlig profit-factor. Alla värden baseras på dina mål som du kan ändra i inputsen. Ju längre ut en punkt är, desto bättre presterar du. Använd grafen för att se styrkor och svagheter och jämföra olika perioder."}
														title={"Spiderweb Graf "}
														key={activeInterval}
														winratePulled={totalWinrate * 100}
														winrate1w={intervalData["1w"].winrate * 100} // Lägg till winrate1w
														winrate1m={intervalData["1m"].winrate * 100} // Lägg till winrate1m
														winrate3m={intervalData["3m"].winrate * 100}
														activeInterval={activeInterval}
														averageLossPulled={averageLoss}
														averageLoss1m={intervalData["1m"].averageLoss}
														averageLoss1w={intervalData["1w"].averageLoss}
														averageLoss3m={intervalData["3m"].averageLoss}
														averageWinPulled={averageWin}
														averageWin1w={intervalData["1w"].averageWin}
														averageWin1m={intervalData["1m"].averageWin}
														averageWin3m={intervalData["3m"].averageWin}
														expectancyPulled={averageExpectancyValue}
														expectancy1w={intervalData["1w"].expectancy}
														expectancy1m={intervalData["1m"].expectancy}
														expectancy3m={intervalData["3m"].expectancy}
														profitFactorPulled={averageProfitFactorValue}
														profitFactor1w={intervalData["1w"].profitFactor}
														profitFactor1m={intervalData["1m"].profitFactor}
														profitFactor3m={intervalData["3m"].profitFactor}
														journalerPulled={activeInterval === "1w" ? journaler1w : activeInterval === "1m" ? journaler1m : journaler3m}
														journaler1w={intervalData["1w"].journalPercentage}
														journaler1m={intervalData["1m"].journalPercentage}
														journaler3m={intervalData["3m"].journalPercentage}
														setShowSeries={setShowSeries}
													/>
												</Box>
											</Box>
										</Box>

										<VStack w={"100%"} align={"start"} spacing={10}>
											<Box w={"100%"}>
												<LinechartComponent
													title={"Expectancy"}
													secondTitle={"Förväntad avk. / trade "}
													data={expectancyarrayData}
													tooltip={
														"Visar genomsnittliga förväntade vinster eller förluster för varje handel. Det beräknas genom att kombinera sannolikheten för en vinnande handel med den genomsnittliga vinsten och dra av produkten av sannolikheten för en förlorande handel och den genomsnittliga förlusten."
													}
													lastValueKey="y"
													prefix="kr"
													roundLastValue={1}
													defaultChartType={"areaspline"}
												></LinechartComponent>
											</Box>
											<HStack w={"100%"} spacing={10}>
												<Smallcardtwo
													title="SQN värde"
													content={SQN ? SQN.toFixed(2) : ""}
													tooltip="Ditt systems framräknande SQN värde för perioden"
													customWidth="229px"
													customHeight="108px"
												/>
												<Smallcardtwo
													title="Full Kelly"
													content={
														fullKelly ? (
															<Text
																style={{
																	color: fullKelly < 0 ? "#E53E3E" : "inherit",
																	textDecoration: fullKelly < 0 ? "line-through" : "none",
																}}
															>
																{(fullKelly * 100).toFixed(2) + " %"}
															</Text>
														) : (
															""
														)
													}
													tooltip={
														"Om du skulle basera din riskhantering och sizing på full kelly, är detta din kelly-sizing för perioden. Vanligtvis är 1/4, 1/8 eller 1/16 av Full Kelly, det populäraste alternativet."
													}
													customWidth="229px"
													customHeight="108px"
												/>
												<Smallcardtwo
													title="Half Kelly"
													content={
														fullKelly / 2 ? (
															<Text
																style={{
																	color: fullKelly / 2 < 0 ? "#E53E3E" : "inherit",
																	textDecoration: fullKelly / 2 < 0 ? "line-through" : "none",
																}}
															>
																{((fullKelly / 2) * 100).toFixed(2) + " %"}
															</Text>
														) : (
															""
														)
													}
													tooltip={
														"Om du skulle basera din riskhantering och sizing på halv kelly, är detta halva din kelly-sizing för perioden. Detta är ett mer aggresiv alternativ gentemot 1/4 eller 1/8."
													}
													customWidth="210px"
													customHeight="108px"
												/>
											</HStack>
											<Box
												bg="#090A0B"
												borderWidth="1px"
												borderColor="#1C1E22"
												borderRadius="2xl"
												overflow="hidden"
												p={6}
												mb={2}
												ml={0}
												mr={0}
												width="100%"
												height="350px"
												maxHeight="350px"
												display="flex"
												justifyContent="center"
												alignItems="center"
												position="relative"
											>
												<Box position="absolute" top={0} left={0} p={6}>
													<HStack spacing={8} align={"flex-start"}>
														<VStack align="flex-start" mt={1}>
															<HStack justifyContent="space-between" w="100%">
																<Flex alignItems="center">
																	<Box>
																		<HStack>
																			<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
																				SQN Tabell
																			</Heading>
																			<Tooltip
																				label={<Image src="/equation 1.svg"></Image>}
																				placement="auto-start"
																				gutter={20}
																				bg="#090A0B"
																				color={colorWhite}
																				padding={3}
																				borderRadius={"2xl"}
																			>
																				<span>
																					<HStack mt={1} align={"flex-start"}>
																						<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} mr={-1} />
																						<Text color="#868686" fontWeight={"medium"} fontSize={12}>
																							Formel
																						</Text>
																					</HStack>
																				</span>
																			</Tooltip>
																		</HStack>
																		<Heading fontSize="14" ml={5} color="#868686" opacity={0.87}>
																			Vad är SQN?
																		</Heading>
																	</Box>
																</Flex>
															</HStack>
															<Text color={colorWhitetwo} width={"320px"} ml={5} fontSize={14}>
																Förhållandet mellan den förväntade avkastningen (R-expectancy) och dess standardavvikelse, multiplicerat med
																kvadratroten av antalet affärer. <br />
																<br /> Den förväntade avkastningen mäter systemets lönsamhet, medan standardavvikelsen mäter konsistensen. SQN används
																för att mäta kvaliteten på en handelsstrategi eller en portfölj. Ju högre SQN, desto bättre är kvaliteten på strategin
																eller portföljen.
															</Text>
														</VStack>
														<Table mt={-1} variant="striped" colorScheme="blackAlpha" size="md">
															<Tbody>
																{intervals.map((interval, index) => (
																	<Tr key={index}>
																		<Td>
																			{interval.min} - {interval.max}
																		</Td>
																		<Td fontWeight="medium" color={findIntervalForSQN(SQN) === index ? "#05D8A5" : "white"}>
																			{interval.label}
																		</Td>
																	</Tr>
																))}
															</Tbody>
														</Table>
													</HStack>
												</Box>
											</Box>
										</VStack>
									</HStack>
								</VStack>
							</TabPanel>

							<TabPanel>
								<VStack spacing={10}>
									<HStack alignItems={"start"} spacing={10}>
										<Barchart
											title={"Förbättringar"}
											secondTitle={"Vad ska utvecklas?"}
											tooltip={
												"Analysera hur vilka önskade förbättringar (knappar i ett av steget när du skriver din journal) som har återkommit för vald period."
											}
											data={betterCount}
										></Barchart>

										<Mentalitetchart
											title={"Mentalitet"}
											secondTitle={"Kontra resultat"}
											tooltip={
												"Visar hur din mentalitet såg ut innan marknaden öppnade, samt ett resultat för hur perioden faktiskt gick. Ser du några kopplingar mellan din mentalitet och resultat?"
											}
											data={mentalityArray}
											profitData={summedArray}
											defaultChartType={"column"}
										></Mentalitetchart>
									</HStack>
									<HStack alignItems={"start"} spacing={10}>
										<PiechartComponent
											title={"Nöjdhet"}
											tooltip={"Et diagram för att visa huruvida du var nöjd eller inte med dagarna under vald period. "}
											valueoneFormal={satisfiedChartData.winValue}
											valuetwoFormal={satisfiedChartData.lossValue}
											valuethreeFormal={satisfiedChartData.evenValue}
											labelone={"nöjda"}
											valueoneName={"Nöjda"}
											valuetwoName={"Missnöjda"}
											valuethreeName={"Lagom"}
											labeltwo={"missnöjda"}
											labelthree={"lagom"}
											valueone={satisfiedChartData.winValue.toFixed(1) + " %"}
											valuetwo={satisfiedChartData.lossValue.toFixed(1) + " %"}
											valuethree={satisfiedChartData.evenValue.toFixed(1) + " %"}
											customHeight={"256px"}
											customWidth={"458px"}
										></PiechartComponent>
										<Informationcard
											title={"Detta är bara början!"}
											content={
												"Vi kommer kontinuerligt lägga till funktioner så att du kan analysera dina trender enklare. Även AI kommer bli en del av våra verktyg, för att hitta mönster mellan journal - resultat."
											}
											tooltip={"Tooltip"}
											customwidth={"735px"}
										></Informationcard>
									</HStack>
								</VStack>
							</TabPanel>
						</TabPanels>
					</Tabs>

					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Trender;
