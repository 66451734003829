import { Button } from "@chakra-ui/react";
import React from "react";

function RadioComponent({ title, customColor, titleColor, isSelected, onChange }) {
	const handleButtonClick = () => {
		onChange(!isSelected);
	};

	return (
		<Button
			onClick={handleButtonClick}
			color={isSelected ? titleColor : "#090A0B"}
			backgroundColor={isSelected ? customColor : "#fff"}
			variant="solid"
			size="md"
			_hover={{ background: isSelected ? customColor : "#fff" }}
		>
			{title}
		</Button>
	);
}

export default RadioComponent;
