import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const SelectButton = ({ title, customcolor, customtitlecolor, onClick, disabled }) => {
	// Initialize isSelected based on localStorage
	const [isSelected, setIsSelected] = useState(() => {
		const saved = localStorage.getItem(title);
		return saved ? JSON.parse(saved) : false;
	});

	// Effect to set localStorage whenever isSelected changes
	useEffect(() => {
		localStorage.setItem(title, JSON.stringify(isSelected));
	}, [isSelected, title]);

	const handleButtonClick = () => {
		if (disabled) return;
		setIsSelected(!isSelected);
		onClick(title);
	};

	return (
		<Button
			color={isSelected ? customtitlecolor : "#090A0B"}
			onClick={handleButtonClick}
			backgroundColor={isSelected ? customcolor : "#fff"}
			variant="solid"
			size="md"
			_hover={{ background: isSelected ? customcolor : "#fff" }}
			disabled={disabled}
		>
			{title}
		</Button>
	);
};

export default SelectButton;
