import { Badge, Box, Heading, Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const MiddlecardLP = ({ title, array, StörstaStock, MinstaProfit, MinstaStock, tooltip, StörstaProfit }) => {
	// Truncate StörstaStock and MinstaStock if they exist and exceed 10 characters
	const truncatedStörstaStock = StörstaStock && StörstaStock.length > 10 ? StörstaStock.slice(0, 10) : StörstaStock;
	const truncatedMinstaStock = MinstaStock && MinstaStock.length > 10 ? MinstaStock.slice(0, 10) : MinstaStock;

	const colorWhite = "rgba(255, 255, 255, 0.87)";
	return (
		<Box
			borderBottomWidth="0px"
			borderColor="#1C1E22"
			borderLeftWidth="0.5px"
			borderRightWidth="0.5px"
			borderStyle="solid"
			borderTopWidth="0.5px"
			filter="hue-rotate(0deg)"
			opacity="1"
			borderRadius="12px"
			boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			w={"100%"}
			maxWidth="200px"
			minWidth={"200px"}
			height="240px"
			display="flex"
			borderWidth="1px"
			maxHeight="240px"
			justifyContent="center"
		>
			<Box flex="1" mt={0}>
				<Heading fontSize="12" mb={2} color="#868686" opacity={0.87}>
					{title}{" "}
					<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
						<span>
							<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
						</span>
					</Tooltip>
				</Heading>

				<Box mt={4}>
					
						<div>
							<VStack alignItems="flex-start" marginRight={4} mb={7}>
								<Text mb={0.5} fontSize="18" opacity={0.87} fontWeight="bold">
									{truncatedStörstaStock}
									{StörstaStock && StörstaStock.length > 10 && (
										<Text as="span" fontSize="14" color="#868686" ml={1}>
											...
										</Text>
									)}
								</Text>
								<Badge
									pt={1}
									pb={1}
									pl={2}
									pr={2}
									fontSize={12}
									mb={-2}
									background="rgba(5, 216, 165, 0.15)"
									textColor="#05D8A5"
									textTransform="none"
									borderRadius="md"
								>
									{Number(StörstaProfit).toFixed(0)} kr
								</Badge>
							</VStack>

							<VStack alignItems="flex-start">
								<Text mb={0.5} fontSize="18" opacity={0.87} fontWeight="bold">
									{truncatedMinstaStock}
									{MinstaStock && MinstaStock.length > 10 && (
										<Text as="span" fontSize="14" color="#868686" ml={1}>
											...
										</Text>
									)}
								</Text>
								<Badge
									pt={1}
									pb={1}
									pl={2}
									pr={2}
									fontSize={12}
									mb={-2}
									background="rgba(229, 62, 62, 0.15)"
									textColor="#E53E3E"
									textTransform="none"
									borderRadius="md"
								>
									{Number(MinstaProfit).toFixed(0)} kr
								</Badge>
							</VStack>
						</div>
				
				</Box>
			</Box>
		</Box>
	);
};

export default MiddlecardLP;
