import React from 'react';
import { Box, VStack, Text, Button } from "@chakra-ui/react";

const InputBox = ({ handleImportDataClick }) => {
  return (
    <Box
      bg="#090A0B"
      borderWidth="1px"
      borderColor="#1C1E22"
      borderRadius="2xl"
      overflow="hidden"
      p={{ base: 2, lg: 4 }}
      ml={0}
      mr={0}
      minW={{ base: "100%", lg: "384px", xl: "384px" }}
      h={"100%"}
      minH={{ base: "35vh", md: "20vh", lg: "220px", "2xl": "250px" }}
      display={"flex"}
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      width="100%"
       // Ensure the box takes full width on mobile
    >
      <Box
        mt={{ base: 22, md: 8, lg: 0, "2xl": 12 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex="1"
        width="100%" // Ensure full width on mobile
        alignItems="center"
      >
        <Button
          className="snake-border-animation"
          ml={{ base: 0, xl: 20, "2xl": 16 }}
          mb={{ base: 0, lg: -10, "2xl": 0 }}
          variant={"outline"}
          _hover={{
            background:
              "var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))",
          }}
          borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
          color={"#fff"}
          opacity="1"
          borderRadius="12px"
          overflow="hidden"
          onClick={handleImportDataClick}
          width={{ base: "auto", md: "50%", lg: "auto", xl: "auto" }}
        >
          {/* Use "Importera" for base size, and "Importera data" for larger sizes */}
          <Box display={{ base: 'block', md: 'none' }}>Importera</Box>
          <Box display={{ base: 'none', md: 'block' }}>Importera data</Box>
        </Button>
      </Box>
      <VStack
        justify={"center"}
        mb={4}
        align={"start"}
        ml={{ base: 10, lg: 10 }}
        spacing={{ base: 2, lg: 4 }}
        
      >
        <Text fontSize={{ base: "16", lg: "18" }} color="#fff" opacity={0.87}>
          Inmatning
        </Text>
        <Text
          width={{ base: "80%", lg: "80%" }}
          fontSize={{ base: "14", lg: "16" }}
          mt={{ base: -2, lg: -4 }}
          color="#868686"
          opacity={0.87}
        >
          Mata in och bokför din handel antingen via CSV fil
          eller på sikt genom API direkt och live mäklare.
        </Text>
      </VStack>
    </Box>
  );
};

export default InputBox;