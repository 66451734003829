import { Box, Text } from "@chakra-ui/react";
import React from "react";

const EmotionSelector = ({ isDisabled, selectedEmoji, setSelectedEmoji, onEmojiSelect }) => {
	const emojis = [
		{ id: 1, symbol: "😟", label: "Dåligt" },
		{ id: 2, symbol: "😀", label: "Okej" },
		{ id: 3, symbol: "🤩", label: "Djurig" },
	];

	// Update local storage when selectedEmoji changes

	const handleEmojiClick = (id) => {
		if (!isDisabled) {
			const newSelectedId = id === selectedEmoji ? null : id;
			setSelectedEmoji(newSelectedId);
			onEmojiSelect(newSelectedId); // Trigger the callback with the new selected ID
		}
	};

	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			{emojis.map((emoji) => (
				<Box
					key={emoji.id}
					textAlign="center"
					mx={10}
					cursor="pointer"
					opacity={selectedEmoji === emoji.id ? 1 : 0.4}
					onClick={() => handleEmojiClick(emoji.id)}
				>
					<Text fontSize="3xl">{emoji.symbol}</Text>
					<Text mt={2}>{emoji.label}</Text>
				</Box>
			))}
		</Box>
	);
};

export default EmotionSelector;
