import { Box, Text } from "@chakra-ui/react";
import React from "react";

class DualColorRectangle extends React.Component {
	state = {
		isAnimated: false,
	};

	componentDidMount() {
		// Trigger animation after component has mounted
		setTimeout(() => {
			this.setState({ isAnimated: true });
		}, 100);
	}

	render() {
		const { averageWin, averageLoss } = this.props;
		const { leftPercentage, rightPercentage } = this.props;
		const { isAnimated } = this.state;
		const width = 200;
		const height = 10;
		const leftColor = "#E53E3E";
		const rightColor = "#05D8A5";
		const borderRadius = "5px"; // Set border radius value here

		const leftWidth = (width * leftPercentage) / 100;
		const rightWidth = (width * rightPercentage) / 100;

		const gradient = `linear-gradient(to right, ${leftColor} 0%, ${leftColor} ${leftWidth}px, ${rightColor} ${leftWidth}px)`;

		const rectangleStyle = {
			// Animate the width and opacity
			width: `${isAnimated ? width : 0}px`,
			opacity: isAnimated ? 1 : 0,
			height: `${height}px`,
			backgroundImage: gradient,
			position: "relative",
			borderRadius: borderRadius, // Set border radius
			transition: "width 1s ease-in-out, opacity 1s ease-in-out",
		};

		const textContainerStyle = {
			// Animate the opacity
			position: "absolute",
			bottom: "-60px",
			left: "0",
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			padding: "10px",
			boxSizing: "border-box",
			opacity: isAnimated ? 1 : 0,
			transition: "opacity 1s ease-in-out",
		};

		const leftTextStyle = {
			color: leftColor,
			fontSize: "12px",
			fontWeight: "bold",
		};

		const rightTextStyle = {
			color: rightColor,
			fontSize: "12px",
			fontWeight: "bold",
		};

		return (
			<Box position="relative">
				<Box style={rectangleStyle}></Box>
				<Box style={textContainerStyle}>
					<Text style={leftTextStyle}>{`${Math.round(averageLoss)}kr`}</Text>
					<Text style={rightTextStyle}>{`${Math.round(averageWin)}kr`}</Text>
				</Box>
			</Box>
		);
	}
}

export default DualColorRectangle;
