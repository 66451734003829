export function processTrades(sortedTrades) {
	let totalCourtage = 0;
	let winningTrades = 0;
	let losingTrades = 0;
	let cumulativeProfit = 0;
	let maxProfit = -Infinity;
	let minProfit = Infinity;
	let cumulativeProfits = [];
	let profits = [];
	let stocks = [];
	let currentWinningStreak = 0;
	let currentLosingStreak = 0;
	let intraLow = Infinity;
	let intraHigh = -Infinity;
	let longestWinningStreak = 0;
	let longestLosingStreak = 0;
	let maxProfitValue = -Infinity;
	let minProfitValue = Infinity;
	let maxProfitName = "";
	let minProfitName = "";
	let totalGrossProfit = 0;
    let totalGrossLoss = 0; 

	sortedTrades.forEach((trade) => {
		const courtage = Number(trade.Courtage);
		const profit = Number(trade.Profit);

		totalCourtage += courtage;
		cumulativeProfit += profit;
		cumulativeProfits.push(cumulativeProfit);


		if (profit > 0) {
            totalGrossProfit += profit;
        } else if (profit < 0) {
            totalGrossLoss += Math.abs(profit);
        }
		
		if (profit > maxProfit) {
			maxProfit = profit;
			maxProfitName = trade.Name;
			maxProfitValue = profit;
		}
		if (profit < minProfit) {
			minProfit = profit;
			minProfitName = trade.Name;
			minProfitValue = profit;
		}

		profits.push(profit);
		stocks.push(trade.Name);

		if (profit > 0) {
			winningTrades++;
			currentWinningStreak++;
			currentLosingStreak = 0;
			longestWinningStreak = Math.max(longestWinningStreak, currentWinningStreak);
		} else if (profit < 0) {
			losingTrades++;
			currentLosingStreak++;
			currentWinningStreak = 0;
			longestLosingStreak = Math.max(longestLosingStreak, currentLosingStreak);
		} else {
			currentWinningStreak = 0;
			currentLosingStreak = 0;
			
		}

		if (cumulativeProfit < intraLow) {
			intraLow = cumulativeProfit;
		}
		if (cumulativeProfit > intraHigh) {
			intraHigh = cumulativeProfit;
		}
	});

	const totalProfit = cumulativeProfit;
	const winRate = winningTrades > 0 ? winningTrades / sortedTrades.length : 0;
	const lossRate = losingTrades > 0 ? losingTrades / sortedTrades.length : 0;
	const evenRate = 1 - winRate - lossRate;
	const averageWin = winningTrades > 0 ? profits.filter((p) => p > 0).reduce((a, b) => a + b, 0) / winningTrades : 0;
	const averageLoss = losingTrades > 0 ? profits.filter((p) => p < 0).reduce((a, b) => a + b, 0) / losingTrades : 0;
	const winLossRatio = losingTrades > 0 ? winningTrades / losingTrades : 0;
	const expectancy = averageWin * winRate + averageLoss * lossRate;
	const profitFactor = totalGrossLoss > 0 ? totalGrossProfit / totalGrossLoss : 0;

	return {
		totalProfit,
		totalCourtage,
		numberOfTrades: sortedTrades.length,
		winRate,
		lossRate,
		evenRate,
		averageWin,
		averageLoss,
		winLossRatio,
		expectancy,
		maxProfit,
		profitFactor,
		minProfit,
		cumulativeProfits,
		profits,
		stocks: [...new Set(stocks)],
		intraLow,
		intraHigh,
		longestWinningStreak,
		longestLosingStreak,
		maxProfitValue,
		minProfitValue,
		maxProfitName,
		minProfitName,
		winningTrades,
		losingTrades,
	};
}
