import { Box, HStack, Heading, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import DualColorRectangle from "./longcardprogress";
const colorWhite = "rgba(255, 255, 255, 0.87)";
const Longcard = ({ title, content, tooltip, leftPercentage, rightPercentage, averageLoss, averageWin }) => (
	<Box
		borderBottomWidth="0px"
		borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
		borderLeftWidth="0.5px"
		borderRightWidth="0.5px"
		borderStyle="solid"
		borderTopWidth="0.5px"
		backgroundColor="rgba(29, 32, 35, 0.6)"
		filter="hue-rotate(0deg)"
		opacity="1"
		borderRadius="12px"
		boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
		overflow="hidden"
		p={{ base: 5, md: 6, lg: 6 }}
		mb={2}
		ml={0}
		mr={0}
		minWidth="424px"
		w={"100%"}
		height="100px"
		display="flex"
		alignItems="center"
		maxWidth="464px"
		maxHeight="100px"
	>
		<HStack alignContent="center">
			<Box flex="1" justifyContent="center" mt={3}>
				<Heading fontSize={12} mb={2} color="#868686" opacity={0.87}>
					{title}{" "}
					<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={{ base: 3, md: 3, lg: 3 }} borderRadius="2xl">
						<span>
						<Icon as={AiOutlineInfoCircle} boxSize={4} color="#868686" ml={{ base: 2, md: 2, lg: 2 }} />
						</span>
					</Tooltip>
				</Heading>
				<Text fontSize={18} opacity={0.87} fontWeight="bold">
					{content}
				</Text>
			</Box>

			<Box ml={9} mt={-6}>
				<DualColorRectangle leftPercentage={leftPercentage} rightPercentage={rightPercentage} averageLoss={averageLoss} averageWin={averageWin} />
			</Box>
		</HStack>
	</Box>
);

export default Longcard;
