import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import PrivateRoute from "./hooks/proRoute";
import Dashboard from "./pages/DashboardPage/dashboard";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Stats from "./pages/StatsPage/statspage";

import Kontosida from "./pages/KontoPage/konto";
import Omoss from "./pages/OmossPage/omoss";
import Installningar from "./pages/SettingPage/installningar";
import Skapany from "./pages/SkapaNyPage/skapany";
import Terms from "./pages/TermsPage/terms";
import Trender from "./pages/TrenderPage/trendpage";

import Home from "./pages/HomePage/homepage";
import Playbook from "./pages/PlaybookPage/playbook";
import Screener from "./pages/ScreenerPage/screenerpage";
import Allatrades from "./pages/AllatradesPage/alltrades";
import Newschart from "./pages/ChartingPage/newschart";

const App = () => {
  return (
    <ChakraProvider>
      <>
        <Router>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/stats"
              element={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              }
            />
            <Route
              path="/trender"
              element={
                <PrivateRoute>
                  <Trender />
                </PrivateRoute>
              }
            />
            <Route
              path="/allatrades"
              element={
                <PrivateRoute>
                  <Allatrades />
                </PrivateRoute>
              }
            />
            <Route
              path="/newschart"
              element={
                <PrivateRoute>
                  <Newschart />
                </PrivateRoute>
              }
            />
            <Route
              path="/installningar"
              element={
                <PrivateRoute>
                  <Installningar />
                </PrivateRoute>
              }
            />
            <Route
              path="/konto"
              element={
                <PrivateRoute>
                  <Kontosida />
                </PrivateRoute>
              }
            />
            <Route
              path="/newpage"
              element={
                <PrivateRoute>
                  <Skapany></Skapany>
                </PrivateRoute>
              }
            />
            <Route
              path="/terms"
              element={
                <PrivateRoute>
                  <Terms></Terms>
                </PrivateRoute>
              }
            />
            <Route
              path="/omoss"
              element={
                <PrivateRoute>
                  <Omoss></Omoss>
                </PrivateRoute>
              }
            />
            <Route
              path="/playbook"
              element={
                <PrivateRoute>
                  <Playbook></Playbook>
                </PrivateRoute>
              }
            />
            <Route
              path="/screener"
              element={
                <PrivateRoute>
                  <Screener></Screener>
                </PrivateRoute>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Home></Home>} />
          </Routes>
        </Router>
      </>
    </ChakraProvider>
  );
};

export default App;
