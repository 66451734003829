import { Button, HStack, Input, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineTag } from "react-icons/ai";

const VerktygsCell = ({ getValue, row, uploadPlaybook, handleSaveAllEdits, isEditable }) => {
    const [value, setValue] = useState("");
    const [tags, setTags] = useState([]);
    const [showInput, setShowInput] = useState(false);

    // Assuming each row has a unique identifier 'tradeId'
    const tradeId = row.original.tradeId; 
    const localStorageKey = `tags-${tradeId}`; // Unique key based on the tradeId

    useEffect(() => {
        // Load tags from localStorage based on the unique tradeId
        const storedTags = localStorage.getItem(localStorageKey);
        if (storedTags) {
            setTags(JSON.parse(storedTags));
        } else {
            const initialValue = getValue() || "";
            const tagsFromValue = initialValue.split(", ").filter(tag => tag);
            setTags(tagsFromValue);
        }
    }, [getValue, localStorageKey, tradeId]);

    const handleInputChange = (e) => {
        setValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && value.trim() !== "" && tags.length === 0) {
            const newTags = [value.trim()];
            setTags(newTags);
            setValue("");
            setShowInput(false);
            // Save the new tags to localStorage using tradeId
            localStorage.setItem(localStorageKey, JSON.stringify(newTags));
            e.preventDefault();
        }
    };

    const handleUpload = () => {
        console.log("Row original data:", row.original);

        if (tags.length > 0) {
            const tradeData = {
                size: row.original.storlek,
                courtage: row.original.courtage,
                Profit: row.original.resultat,
                date: row.original.datum,
                Name: row.original.namn,
            };
            const tradeNotes = tags.join(", ");

            const requestBody = {
                avslut: 1,
                notes: tradeNotes,
                total_size: tradeData.size,
                trade: [
                    {
                        totalCourtage: tradeData.courtage,
                        totalProfit: tradeData.Profit,
                        totalProfitPercentage: ((tradeData.Profit / tradeData.size) * 100).toFixed(2),
                        totalSize: tradeData.size,
                        trades: [
                            {
                                courtage: tradeData.courtage,
                                date: tradeData.date,
                                profit: tradeData.Profit,
                                size: tradeData.size,
                                stock: tradeData.Name,
                                totalProfitPercentage: ((tradeData.Profit / tradeData.size) * 100).toFixed(2),
                            },
                        ],
                    },
                ],
            };

            console.log("Request Body being sent:", requestBody);
            uploadPlaybook(requestBody);
            // Save the tags to localStorage after upload
            localStorage.setItem(localStorageKey, JSON.stringify(tags));
        }
    };

    const removeTag = (index) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        // Update the localStorage after removing a tag
        localStorage.setItem(localStorageKey, JSON.stringify(newTags));
    };

    const toggleInput = () => {
        if (tags.length === 0) {
            setShowInput(!showInput);
        }
    };

    return (
        <HStack display="flex" alignItems="center">
            {showInput ? (
                <Input size="xs" value={value} onChange={handleInputChange} onKeyDown={handleKeyDown} autoFocus onBlur={() => setShowInput(false)} />
            ) : (
                !tags.length && (
                    <Button  background="rgb(213,63,140, 0.15)"
                    textColor="#d53f8c" leftIcon={<AiOutlineTag />} _hover={{ bg: "rgba(213,63,140, 0.3)" }} colorScheme="pink" size="xs" onClick={toggleInput}>
                        Playbook 
                    </Button>
                )
            )}
            {tags.map((tag, index) => (
                <HStack key={index}>
                    <Tag size="sm" colorScheme="blue">
                        <TagLabel>{tag.length > 10 ? `${tag.substring(0, 10)}...` : tag}</TagLabel>
                        <TagCloseButton onClick={() => removeTag(index)} />
                    </Tag>
                    <Button background="rgba(5, 216, 165, 0.15)"
										textColor="#05D8A5" _hover={{ bg: "rgba(5, 216, 165, 0.3)" }} cursor="pointer" size="xs" onClick={handleUpload}>
                        Ladda upp
                    </Button>
                </HStack>
            ))}
        </HStack>
    );
};

export default VerktygsCell;
