import { Flex, Heading, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const colorWhite = "#fff";

const Thincard = ({ title, content, tooltip }) => (
	<Flex
		borderBottomWidth="0px"
		borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
		borderLeftWidth="0.5px"
		borderRightWidth="0.5px"
		borderStyle="solid"
		borderTopWidth="0.5px"
		backgroundColor="rgba(29, 32, 35, 0.6)"
		filter="hue-rotate(0deg)"
		opacity="1"
		borderRadius="12px"
		boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
		overflow="hidden"
		p={6}
		mb={2}
		ml={0}
		mr={0}
		w={"100%"}
		minWidth="253px"
		height="60px"
		flexDirection="row" // Uppdatera flexDirection till "row" för att placera elementen bredvid varandra
		alignItems="center" // Centrera elementen vertikalt
		maxHeight="60px"
	>
		<Heading fontSize="12" mb={1} color="#868686" opacity={0.87}>
			{title}
			<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
				<span>
					<Icon as={AiOutlineInfoCircle} boxSize={4} color="#868686" ml={2} />
				</span>
			</Tooltip>
		</Heading>
		<Text fontSize="18" opacity={0.87} fontWeight={"bold"} ml={4} mb={1}>
			{content}
		</Text>
	</Flex>
);

export default Thincard;
