import { isWeekend, startOfWeek, today } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import React, { useState } from "react";
import { useCalendarGrid } from "react-aria";
import { CalendarCell, CalendarHeaderCell, RangeCalendar } from "react-aria-components";
import style from "../calendarsmall/adobeweekCalendar.css";

const CalendarView = ({ onDateChange }) => {
	const disabledRanges = [];
	const localeData = useLocale();
	const locale = localeData.locale;

	// Define startOfTheWeek here so it's accessible throughout CalendarView
	const startOfTheWeek = startOfWeek(today(), locale);

	const isDateUnavailable = (date) => {
		return (
			isWeekend(date, locale) ||
			disabledRanges.some((interval) => {
				return date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0;
			})
		);
	};

	function WeekCalendarGrid({ state, ...props }) {
		const { gridProps } = useCalendarGrid(props, state);
		const headers = ["M", "T", "O", "T", "F", "L", "S"];

		const getCurrentWeekNumber = () => {
			const todayDate = new Date();
			const firstDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
			const dayOfWeek = firstDayOfMonth.getDay(); // Day of week the month starts on (0-6, Sunday-Saturday)

			// Adjust so Monday is 0, Tuesday is 1, ..., Sunday is 6
			const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

			const currentDate = todayDate.getDate();

			// Calculate the week number, adjusting for the first week length
			const firstWeekLength = 7 - adjustedDayOfWeek;
			const weekNumber = Math.ceil((currentDate - firstWeekLength) / 7) + 1;

			return weekNumber - 1; // Adjust for 0-index to use in getDatesInWeek
		};

		const currentWeekNumber = getCurrentWeekNumber();
		//console.log("week", currentWeekNumber);

		return (
			<table {...gridProps}>
				<thead>
					<tr>
						{headers.map((header, index) => (
							<CalendarHeaderCell key={index} className="week-react-aria-CalendarHeaderCell">
								{header}
							</CalendarHeaderCell>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						{state.getDatesInWeek(currentWeekNumber).map((date, i) => (
							<CalendarCell key={i} date={date} className="week-react-aria-CalendarCell" />
						))}
					</tr>
				</tbody>
			</table>
		);
	}

	const [selectedRange, setSelectedRange] = useState({ start: null, end: null });

	const handleDateChange = (start, end) => {
		setSelectedRange({ start, end });

		// Använd getDateRangeArray för att generera en array med datumsträngar för det valda intervallet
		const selectedDates = getDateRangeArray(start, end);
		//console.log(`Selected Date Range = { ${selectedDates.join(", ")} }`);

		// Skicka den valda datumen till föräldrakomponenten om den behöver dem
		if (onDateChange) {
			onDateChange(selectedDates); // Skicka den genererade datumen som en array
		}
	};

	const getDateRangeArray = (startObj, endObj) => {
		const startDate = new Date(startObj.year, startObj.month - 1, startObj.day);
		const endDate = new Date(endObj.year, endObj.month - 1, endObj.day);
		const dateArray = [];

		while (startDate <= endDate) {
			// Format the date as YYYY-MM-DD without converting to UTC
			const localDateStr = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, "0")}-${String(startDate.getDate()).padStart(
				2,
				"0"
			)}`;
			dateArray.push(localDateStr);
			startDate.setDate(startDate.getDate() + 1); // Move to the next day
		}

		return dateArray;
	};

	return (
		<RangeCalendar
			aria-label="Trip dates"
			className={style["week-react-aria-Calendar"]}
			isDateUnavailable={isDateUnavailable}
			onChange={({ start, end }) => handleDateChange(start, end)}
			defaultValue={{
				start: startOfTheWeek, // This should work now as startOfTheWeek is defined in the right scope
				end: today(), // You might want to adjust this based on your needs
			}}
		>
			{({ state }) => (
				<div className="week">
					<WeekCalendarGrid state={state} className="week" />
				</div>
			)}
		</RangeCalendar>
	);
};

export default CalendarView;
