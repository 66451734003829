import { Box, Button, Flex, Table, Tag, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
//import { createChart, ColorType } from "lightweight-charts";

const colorWhite = "#fff";

const TradeCard = ({ tag, stock, size, onRemove, profit, rank, date, onClick, text, onTagColorChange }) => {
	const [tags, setTags] = useState([]);
	const [tagInputVisible, setTagInputVisible] = useState(false);
	const [tagInputValue, setTagInputValue] = useState("");
	const [tagColor, setTagColor] = useState("rgba(29, 32, 35, 0.6)");
	const colors = ["#d53f8c", "#05D8A5", "#d69e2e"]; // Array of colors to cycle through
	const [textAreaContent, setTextAreaContent] = useState("");

	const addTag = () => {
		if (tagInputValue) {
			setTags([...tags, tagInputValue]);
			setTagInputValue("");
		}
		setTagInputVisible(false);
	};
	const handleCardClick = () => {
		onClick({ tag, stock, date });
	};
	const handleButtonClick = (e) => {
		e.stopPropagation(); // Prevent the card click event from firing
		onRemove(tag); // Assuming 'tag' is a unique identifier for each card
	};
	const removeTag = (tagToRemove) => {
		setTags(tags.filter((tag) => tag !== tagToRemove));
	};

	useEffect(() => {
		if (onTagColorChange) {
			onTagColorChange(tagColor);
		}
	}, [tagColor, onTagColorChange]);

	// New useEffect for loading saved text content
	useEffect(() => {
		const savedContent = localStorage.getItem("textAreaContent");
		if (savedContent) {
			setTextAreaContent(savedContent);
		}
	}, []);

	const toggleTagColor = () => {
		const currentColorIndex = colors.indexOf(tagColor);
		const nextColorIndex = (currentColorIndex + 1) % colors.length;
		setTagColor(colors[nextColorIndex]);
	};

	return (
		<div onClick={handleCardClick} role="button" tabIndex="0" style={{ cursor: "pointer" }}>
			<Box
				borderBottomWidth="0px"
				borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
				borderLeftWidth="0.5px"
				borderRightWidth="0.5px"
				borderStyle="solid"
				borderTopWidth="0.5px"
				backgroundColor="rgba(29, 32, 35, 0.6)"
				filter="hue-rotate(0deg)"
				opacity="1"
				borderRadius="12px"
				boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
				overflow="hidden"
				p={6}
				mb={2}
				ml={0}
				mr={0}
				width="360px"
				display="flex"
				alignItems="center"
				//={() => onClick({ tag, stock, date })}
				justifyContent="center"
			>
				<Box flex="1">
					<Flex alignItems="flex-start" justifyContent="space-between">
					<Text fontSize="18" opacity={0.87} fontWeight="bold">
  {stock.length > 15 ? `${stock.slice(0, 15)}...` : stock}
</Text>


						<Button
							onClick={handleButtonClick}
							backgroundColor={"rgba(29, 32, 35, 0.6)"} // Custom background color
							color="#fff" // Custom text/icon color
							size="xs"
							filter="hue-rotate(0deg)"
							opacity="1"
							boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
							fontSize="10px"
							_hover={{
								backgroundColor: "#E53E3E", // Maintain the same background color on hover
								color: "#fff", // Maintain the same text color on hover
								boxShadow: "rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset", // Maintain the same box shadow on hover
								filter: "hue-rotate(0deg)",
								opacity: "1",
							}}
						>
							Radera
						</Button>
					</Flex>

					<Table variant="striped" colorScheme="blackAlpha" size="sm">
						<Tbody>
							<Tr>
								<Td fontWeight="bold">Datum</Td>
								<Td isNumeric>{date}</Td>
							</Tr>
							<Tr>
								<Td fontWeight="bold">Tag</Td>
								<Td>
									<Flex justifyContent="flex-end">
										{" "}
										{/* Flex container to align button */}
										<Tag size="sm" colorScheme="blue">
											{tag}
										</Tag>
									</Flex>
								</Td>
							</Tr>
							<Tr>
								<Td fontWeight="bold">Size</Td>
								<Td isNumeric>{`${size} kr`}</Td>
							</Tr>
							<Tr>
								<Td fontWeight="bold">Profit</Td>
								<Td isNumeric color={profit > 0 ? "#05D8A5" : profit < 0 ? "#E53E3E" : "white"}>
									{`${profit} kr`}
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</Box>
			</Box>
		</div>
	);
};

export default TradeCard;
