import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import {
    Box,
    Input,
    FormControl,
    FormLabel,
    VStack,
    Button,
    InputGroup,
    InputRightElement,
    HStack,
    Tooltip,
    Icon,
    Heading,
    SimpleGrid,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Initialize the highcharts-more module
highchartsMore(Highcharts);

const SpiderWebChart = ({
    title,
    tooltip,
    winratePulled,
    winrate1w,
    winrate1m,
    winrate3m,
    averageWinPulled,
    averageLossPulled,
    expectancyPulled,
    journalerPulled,
    profitFactorPulled,
    averageWin1w,
    averageLoss1w,
    expectancy1w,
    profitFactor1w,
    averageWin1m,
    averageLoss1m,
    expectancy1m,
    profitFactor1m,
    averageWin3m,
    averageLoss3m,
    expectancy3m,
    profitFactor3m,
    activeInterval,
    journaler1m,
    journaler1w,
    journaler3m,
    setShowSeries,
}) => {
    const showVs1wButton = activeInterval !== "1w";
    const showVs1mButton = activeInterval !== "1m";
    const showVs3mButton = activeInterval !== "3m";

    const importedValues = {
        winrate: winratePulled,
        averageLoss: averageLossPulled,
        averageWin: averageWinPulled,
        expectancy: expectancyPulled,
        journaler: journalerPulled,
        profitFactor: profitFactorPulled,
    };

    const oneWeekValues = {
        winrate: winrate1w, // Use winrate1w
        averageLoss: averageLoss1w,
        averageWin: averageWin1w,
        expectancy: expectancy1w,
        journaler: journaler1w,
        profitFactor: profitFactor1w,
    };

    const oneMonthValues = {
        winrate: winrate1m, // Use winrate1m
        averageLoss: averageLoss1m,
        averageWin: averageWin1m,
        expectancy: expectancy1m,
        journaler: journaler1m,
        profitFactor: profitFactor1m,
    };

    const threeMonthValues = {
        winrate: winrate3m, // Use winrate3m
        averageLoss: averageLoss3m,
        averageWin: averageWin3m,
        expectancy: expectancy3m,
        journaler: journaler3m,
        profitFactor: profitFactor3m,
    };
    

    const [normalValues, setNormalValues] = useState({
        winrate: 50,
        averageLoss: 200,
        averageWin: 400,
        expectancy: 300,
        journaler: 20,
        profitFactor: 2,
    });

    const [showInputs, setShowInputs] = useState(false);
    const [showSeries, setShowSeriesState] = useState({
        oneWeek: false,
        oneMonth: false,
        threeMonth: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedValues = {
            ...normalValues,
            [name]: value === "" ? "" : Number(value),
        };
        setNormalValues(updatedValues);
        localStorage.setItem('normalValues', JSON.stringify(updatedValues));
    };
    
    useEffect(() => {
        const savedValues = localStorage.getItem('normalValues');
        if (savedValues) {
            setNormalValues(JSON.parse(savedValues));
        }
    }, []);
    
    const calculateIndex = (value, normal, isLossMetric = false) => {
        const index = isLossMetric ? (normal / value) * 100 : (value / normal) * 100;
        return index > 100 ? 100 : index;
    };
    
    const calculateIndexedValues = (values) => {
        return {
            winrate: calculateIndex(values.winrate, normalValues.winrate),
            averageLoss: calculateIndex(values.averageLoss, normalValues.averageLoss, true),
            averageWin: calculateIndex(values.averageWin, normalValues.averageWin),
            profitFactor: calculateIndex(values.profitFactor, normalValues.profitFactor),
            expectancy: calculateIndex(values.expectancy, normalValues.expectancy),
            journaler: calculateIndex(values.journaler, normalValues.journaler),
            raw: values,
        };
    };
    
    

    const indexedValues = calculateIndexedValues(importedValues);
    const indexedOneWeekValues = calculateIndexedValues(oneWeekValues);
    const indexedOneMonthValues = calculateIndexedValues(oneMonthValues);
    const indexedThreeMonthValues = calculateIndexedValues(threeMonthValues);

    const data = !activeInterval ? [] :
        activeInterval === "1w" && (!averageWin1w && !averageLoss1w && !expectancy1w && !profitFactor1w) ? [] :
        activeInterval === "1m" && (!averageWin1m && !averageLoss1m && !expectancy1m && !profitFactor1m) ? [] :
        activeInterval === "3m" && (!averageWin3m && !averageLoss3m && !expectancy3m && !profitFactor3m) ? [] :
        [
            { y: indexedValues.winrate, raw: importedValues.winrate },
            { y: indexedValues.averageLoss, raw: importedValues.averageLoss },
            { y: indexedValues.averageWin, raw: importedValues.averageWin },
            { y: indexedValues.profitFactor, raw: importedValues.profitFactor },
            { y: indexedValues.expectancy, raw: importedValues.expectancy },
            { y: indexedValues.journaler, raw: importedValues.journaler },
        ];

    const oneWeekData = [
        { y: indexedOneWeekValues.winrate, raw: oneWeekValues.winrate },
        { y: indexedOneWeekValues.averageLoss, raw: oneWeekValues.averageLoss },
        { y: indexedOneWeekValues.averageWin, raw: oneWeekValues.averageWin },
        { y: indexedOneWeekValues.profitFactor, raw: oneWeekValues.profitFactor },
        { y: indexedOneWeekValues.expectancy, raw: oneWeekValues.expectancy },
        { y: indexedOneWeekValues.journaler, raw: oneWeekValues.journaler },
    ];

    const oneMonthData = [
        { y: indexedOneMonthValues.winrate, raw: oneMonthValues.winrate },
        { y: indexedOneMonthValues.averageLoss, raw: oneMonthValues.averageLoss },
        { y: indexedOneMonthValues.averageWin, raw: oneMonthValues.averageWin },
        { y: indexedOneMonthValues.profitFactor, raw: oneMonthValues.profitFactor },
        { y: indexedOneMonthValues.expectancy, raw: oneMonthValues.expectancy },
        { y: indexedOneMonthValues.journaler, raw: oneMonthValues.journaler },
    ];

    const threeMonthData = [
        { y: indexedThreeMonthValues.winrate, raw: threeMonthValues.winrate },
        { y: indexedThreeMonthValues.averageLoss, raw: threeMonthValues.averageLoss },
        { y: indexedThreeMonthValues.averageWin, raw: threeMonthValues.averageWin },
        { y: indexedThreeMonthValues.profitFactor, raw: threeMonthValues.profitFactor },
        { y: indexedThreeMonthValues.expectancy, raw: threeMonthValues.expectancy },
        { y: indexedThreeMonthValues.journaler, raw: threeMonthValues.journaler },
    ];

    const formatLabel = (key) => {
        return key
            .split(/(?=[A-Z])/)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const getUnit = (key) => {
        switch (key) {
            case "winrate":
            case "journaler":
                return "%";
            case "expectancy":
            case "averageLoss":
            case "averageWin":
                return "kr";
            default:
                return "";
        }
    };

    const toggleSeries = (series) => {
        setShowSeriesState((prev) => ({
            ...prev,
            [series]: !prev[series],
        }));
    };

    const resetSeries = () => {
        setShowSeriesState({
            oneWeek: false,
            oneMonth: false,
            threeMonth: false,
        });
    };

    useEffect(() => {
        resetSeries();
    }, [activeInterval]);

    const options = {
        chart: {
            polar: true,
            type: "area",
            backgroundColor: "transparent",
            width: 500,
            height: 300,
            marginLeft: 100,
            marginRight: 100,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            backgroundColor: "#090A0B",
            borderColor: "#1C1E22",
            style: {
                color: "#fff",
                fontSize: "12px",
            },
            useHTML: true,
            formatter: function () {
                const categories = ["Winrate", "Average Loss", "Average Win", "Profit Factor", "Expectancy", "Journaler"];
                const index = this.point.index;
                const category = categories[index];
                const rawValue = typeof this.point.raw === "number" ? this.point.raw.toFixed(1) : this.point.raw;
                const indexValue = this.point.y.toFixed(1);
                const units = {
                    Winrate: "%",
                    "Average Loss": "kr",
                    "Average Win": "kr",
                    "Profit Factor": "",
                    Expectancy: "kr",
                    Journaler: "%",
                };
                const unit = units[category];

                return `<div style="max-width: 175px; font-size: 12px;">
                    <b>${this.series.name}</b><br/>
                    <table style="margin-top: 3px; width: 100%;">
                        <tr>
                            <td style="color: ${this.color}; padding-right: 20px; padding-bottom: 2px;">${category}: </td>
                            <td style="text-align: right; padding-bottom: 2px;">
                                <b style="color: white;">${rawValue} ${unit}</b><br/>
                                <span style="color: #868686;">(${indexValue}%)</span>
                            </td>
                        </tr>
                    </table>
                </div>`;
            },
            shared: false,
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: ["Winrate", "Average Loss", "Average Win", "Profit Factor", "Expectancy", "Journaler"],
            tickmarkPlacement: "on",
            lineWidth: 0,
            gridLineWidth: 0,
            labels: {
                distance: 10,
                style: {
                    fontSize: "11px",
                    color: "#868686",
                    fontWeight: 500,
                },
            },
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            min: 0,
            max: 100,
            gridLineWidth: 0,
            plotBands: [
                {
                    from: 0,
                    to: 20,
                    color: "#151719",
                },
                {
                    from: 20,
                    to: 40,
                    color: "transparent",
                },
                {
                    from: 40,
                    to: 60,
                    color: "#151719",
                },
                {
                    from: 60,
                    to: 80,
                    color: "transparent",
                },
                {
                    from: 80,
                    to: 100,
                    color: "#151719",
                },
            ],
            labels: {
                enabled: false,
            },
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    radius: 3,
                    symbol: "circle",
                },
                stickyTracking: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },
        series: [
            {
                showInLegend: false,
                data: data,
                pointPlacement: "on",
                type: "area",
                name: "Valt Intervall",
                color: "#05D8A5",
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, Highcharts.color("rgba(80,227,181,0.8)").get("rgba")],
                        [1, Highcharts.color("rgba(80,227,181,0.05)").get("rgba")],
                    ],
                },
                zIndex: 2,
            },
            ...(showSeries.oneWeek
                ? [
                    {
                        showInLegend: false,
                        data: oneWeekData,
                        pointPlacement: "on",
                        type: "area",
                        name: "1 Vecka",
                        color: "#E53E3E",
                        fillColor: "none",
                        marker: {
                            radius: 0,
                        },
                        dashStyle: "Dash",
                        lineWidth: 2,
                        zIndex: 2,
                    },
                ]
                : []),
            ...(showSeries.oneMonth
                ? [
                    {
                        showInLegend: false,
                        data: oneMonthData,
                        pointPlacement: "on",
                        type: "area",
                        name: "1 Månad",
                        color: "#00B0ED",
                        fillColor: "none",
                        marker: {
                            radius: 0,
                        },
                        dashStyle: "Dash",
                        lineWidth: 2,
                        zIndex: 3,
                    },
                ]
                : []),
            ...(showSeries.threeMonth
                ? [
                    {
                        showInLegend: false,
                        data: threeMonthData,
                        pointPlacement: "on",
                        type: "area",
                        name: "3 Månader",
                        color: "#d53f8c",
                        fillColor: "none",
                        marker: {
                            radius: 0,
                        },
                        dashStyle: "Dash",
                        lineWidth: 2,
                        zIndex: 4,
                    },
                ]
                : []),
        ],
    };
    console.log("Options:", data);
    return (
        <Box
            borderWidth="1px"
            borderColor="#1C1E22"
            borderRadius="2xl"
            overflow="hidden"
            mb={2}
            ml={0}
            mr={0}
            minW="728px"
            height="auto"
            maxHeight="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
        >
            <HStack align="start" width="80%">
                <Box flex="1">
                    <Box position="absolute" top={0} left={0} p={6}>
                        <HStack justifyContent="space-between">
                            <VStack align="flex-start" mt={1}>
                                <Heading fontSize="18" ml={5} color="#fff" opacity={0.87} zIndex={2}>
                                    {title}{" "}
                                    <Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={"fff"} padding={3} borderRadius={"2xl"}>
                                        <span>
                                            <Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
                                        </span>
                                    </Tooltip>
                                </Heading>

                                <VStack ml={5} gap="5" align="flex-start">
                                    <Button
                                        background="rgba(5, 216, 165, 0.15)"
                                        textColor="#05D8A5"
                                        _hover={{ bg: "rgba(5, 216, 165, 0.3)", color: "#05D8A5" }}
                                        _active={{ bg: "rgba(5, 216, 165, 0.3)", color: "#05D8A5" }}
                                        size={"sm"}
                                        fontWeight={"medium"}
                                        onClick={() => setShowInputs(!showInputs)}
                                        colorScheme="teal"
                                    >
                                        Ändra mål
                                    </Button>
                                    {showVs1wButton && (
                                        <Button
                                            background={showSeries.oneWeek ? "rgba(229, 62, 62, 0.15)" : "rgba(225, 225, 255, 0.15)"}
                                            textColor={showSeries.oneWeek ? "#E53E3E" : "#868686"}
                                            _hover={{ bg: "rgba(229, 62, 62, 0.3)", color: "#E53E3E" }}
                                            _active={{ bg: "rgba(229, 62, 62, 0.3)", color: "#E53E3E" }}
                                            size={"sm"}
                                            fontWeight={"medium"}
                                            onClick={() => toggleSeries("oneWeek")}
                                            colorScheme="red"
                                        >
                                            Vs. 1W
                                        </Button>
                                    )}
                                    {showVs1mButton && (
                                        <Button
                                            background={showSeries.oneMonth ? "rgba(0, 176, 237, 0.15)" : "rgba(225, 225, 255, 0.15)"}
                                            textColor={showSeries.oneMonth ? "#00B0ED" : "#868686"}
                                            _hover={{ bg: "rgba(0, 176, 237, 0.3)", color: "#00B0ED" }}
                                            _active={{ bg: "rgba(0, 176, 237, 0.3)", color: "#00B0ED" }}
                                            size={"sm"}
                                            fontWeight={"medium"}
                                            onClick={() => toggleSeries("oneMonth")}
                                            colorScheme="red"
                                        >
                                            Vs. 1M
                                        </Button>
                                    )}
                                    {showVs3mButton && (
                                        <Button
                                            background={showSeries.threeMonth ? "rgb(213,63,140, 0.15)" : "rgba(225, 225, 255, 0.15)"}
                                            textColor={showSeries.threeMonth ? "#d53f8c" : "#868686"}
                                            _hover={{ bg: "rgba(213,63,140, 0.3)", color: "#d53f8c" }}
                                            _active={{ bg: "rgba(213,63,140, 0.3)", color: "#d53f8c" }}
                                            size={"sm"}
                                            fontWeight={"medium"}
                                            onClick={() => toggleSeries("threeMonth")}
                                            colorScheme="red"
                                        >
                                            Vs. 3M
                                        </Button>
                                    )}
                                </VStack>
                            </VStack>
                        </HStack>
                    </Box>
                </Box>
                <Box mb={4} mt={4}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Box>
            </HStack>
            {showInputs && (
                <SimpleGrid mt={2} mb={8} columns={2} spacing={4}>
                    {Object.keys(normalValues).map((key) => (
                        <FormControl key={key}>
                            <FormLabel color="white">{formatLabel(key)}</FormLabel>
                            <InputGroup size="sm">
                                <Input
                                    name={key}
                                    type="number"
                                    value={normalValues[key]}
                                    onChange={handleInputChange}
                                    backgroundColor={"transparent"}
                                    borderWidth="1px"
                                    borderColor="#1C1E22"
                                    _hover={{ bg: "transparent", color: "white" }}
                                    _focus={{ bg: "transparent", color: "white", borderColor: "#868686" }}
                                    _active={{ bg: "transparent", color: "white", borderColor: "#868686" }}
                                    color={"#868686"}
                                    variant="filled"
                                    placeholder="Välj målvärde"
                                    _placeholder={{ color: "#868686" }} // This line changes the placeholder color
                                    borderRadius={5}
                                />

                                <InputRightElement width="3rem">
                                    <Box color="white">{getUnit(key)}</Box>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    ))}
                </SimpleGrid>
            )}
        </Box>
    );
};

export default SpiderWebChart;
