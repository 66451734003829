import React from "react";
import Marquee from "react-fast-marquee";
import { Box, Button, Icon, HStack, Text, Center, Link } from "@chakra-ui/react";
import { AiFillQuestionCircle } from "react-icons/ai";

const MyMarqueeComponent = () => {
	const colorWhite = "#b6b7b7";
	return (
		<Box width={"1000px"}>
			<Marquee gradient={true} pauseOnHover={true} gradientColor="#090A0B">
				<HStack spacing={3} alignContent="center" justifyContent="center">
					<Center>
						<Icon mb={4} as={AiFillQuestionCircle} color="#05D8A5" boxSize={5} />
					</Center>
					<Text fontSize="16px" fontWeight="bold" color={colorWhite}>
						Hjälp att komma igång?
					</Text>
					<Text fontSize="14px" color={colorWhite}>
						Kika på vår{" "}
						<Link href="guide" isExternal color={colorWhite} _hover={{ textDecoration: "underline" }}>
							nybörjarguide
						</Link>
					</Text>
				</HStack>
			</Marquee>
		</Box>
	);
};

export default MyMarqueeComponent;
