import { CloseIcon } from "@chakra-ui/icons";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState,useRef  } from "react";
import { IoIosResize } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toPng } from 'html-to-image';
import download from 'downloadjs';
import Smallcardtwo from "../cards/smallcardtwo";
import TextEditor from "./texteditor";
import { LuDownload } from "react-icons/lu";

const TradeFullView = ({ trade, playbookId, rank, tagColor, onBack }) => {
  const [images, setImages] = useState([]);
  const [previewImageIndex, setPreviewImageIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const cancelRef = React.useRef();
  const [isHovering, setIsHovering] = useState(false);
  const [selectedImage, setSelectedImage] = useState(trade.images || null);
  const [screenshot, setScreenshot] = useState(null);
  const [content, setContent1] = useState("");
  const [playbookData, setPlaybookData] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const componentRef = useRef(null);  // Reference to the component

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl); // Uppdatera den visade bilden
      setScreenshot(file); // Uppdatera filen som ska laddas upp
      // Du kanske även vill nollställa imageSrc om du använder den för att visa den laddade bilden
      setImageSrc(imageUrl);
      setHasUnsavedChanges(true);
    }
  };

  const fetchImage = async (playbookId) => {
    try {
      const response = await fetch(
        `https://backend-production-41c0.up.railway.app/playbookSingle/${playbookId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Response Data:", data);
        setPlaybookData(data);
        setHasUnsavedChanges(false);
      } else {
        const errorData = await response.json();
        //console.error("Could not fetch playbook:", errorData.message);
      }
    } catch (error) {
      //console.error("Error fetching playbook:", error);
    }
  };

  useEffect(() => {
    fetchImage(playbookId);
  }, [playbookId]);

  function bufferToBase64(buf) {
    var binstr = Array.prototype.map
      .call(buf, function (ch) {
        return String.fromCharCode(ch);
      })
      .join("");
    return btoa(binstr);
  }

  useEffect(() => {
    if (playbookData && playbookData.images && playbookData.images.data) {
      // Konvertera buffer från responsen till en base64-sträng
      const base64 = bufferToBase64(playbookData.images.data.data);
      setImageSrc(`data:${playbookData.images.contentType};base64,${base64}`);
    } else {
      setImageSrc(null);
    }
  }, [playbookData]);

  const confirmOnBack = () => {
    if (hasUnsavedChanges) {
      setIsAlertOpen(true); // Show alert if there are unsaved changes
    } else {
      onBack(); // Directly invoke onBack if there are no unsaved changes
    }
  };

  const removeCard = async (tradeIdToRemove) => {
    try {
      const response = await fetch(
        `https://backend-production-41c0.up.railway.app/deletePlaybook/${tradeIdToRemove}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (response.ok) {
        //console.log("Sucesss deleted");
        window.location.reload();
      } else {
        const errorData = await response.json();
        //console.error("Error deleting trade:", errorData.message);
      }
    } catch (error) {
      //console.error("Exception when calling delete on playbook:", error);
    }
  };

  const handleSave = async () => {
    if (screenshot) {
      const formData = new FormData();
      formData.append("globalNotes", JSON.stringify(content));
      formData.append("image", screenshot);

      try {
        const response = await fetch(
          `https://backend-production-41c0.up.railway.app/editPlaybook/${playbookId}`,
          {
            method: "PATCH",
            credentials: "include",
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();

          // Efter att ha sparat, hämta om playbookData för att uppdatera bilden
          fetchImage(playbookId);
          alert("Playbook updated successfully!");
        } else {
          // Hantera fel från servern
          const error = await response.text();
          alert("Failed to update playbook");
        }
      } catch (error) {
        alert("Exception when trying to update playbook");
      }
    } else {
    }
  };

  const handleCapture = () => {
    if (componentRef.current === null) {
      return;
    }
  
    // Extract stock symbol and date from trade object
    const stockSymbol = trade.stock ? trade.stock.replace(/\s+/g, '-') : 'trade';
    const tradeDate = trade.date ? trade.date.replace(/\s+/g, '-') : new Date().toISOString().split('T')[0];
  
    // Create a filename using stock symbol and date
    const filename = `${stockSymbol}${tradeDate}.png`;
  
    toPng(componentRef.current, { cacheBust: true })
      .then((dataUrl) => {
        download(dataUrl, filename);
      })
      .catch((err) => {
        console.error('oops, something went wrong!', err);
      });
  };
  
  const formatNumber = (num) => {
    const roundedNum = parseFloat(num).toFixed(0);
    const [integerPart, decimalPart] = roundedNum.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };
  const colorWhite = "#fff";
  {
    return (
      <Box
      ref={componentRef} // Attach the ref to the component
        bg="#090A0B"
        borderRadius="2xl"
        p={6}
        color="white"
        width="100%"
        position="relative"
      >
        <VStack justifyContent="start" spacing={6}>
          <HStack
            width="100%"
            justifyContent="space-between"
            alignItems={"flex-start"}
          >
            <VStack align={"start"} spacing={6}>
              <HStack spacing={6}>
                <Button onClick={confirmOnBack}>Tillbaka till trades</Button>
                <Button
                  size={"sm"}
                  background="rgba(5, 216, 165, 0.15)"
                  textColor="#05D8A5"
                  _hover={{ bg: "rgba(5, 216, 165, 0.3)" }}
                  onClick={handleSave}
                >
                  Spara
                </Button>
                <Button
                  size={"sm"}
                  background="rgba(229, 62, 62, 0.15)"
                  textColor="#E53E3E"
                  _hover={{ bg: "rgba(229, 62, 62, 0.3)" }}
                  onClick={() => removeCard(playbookId)}
                >
                  Radera
                </Button>
                <Button  
                background="rgba(0, 176, 237, 0.15)"
                textColor="rgba(0, 176, 237)"
                 _hover={{ bg: "rgba(0, 176, 237, 0.3)" }}
                 _active={{ bg: "rgba(0, 176, 237, 0.15)" }}
                 leftIcon={<LuDownload />}
                size={"sm"} onClick={handleCapture}>Spara som bild</Button>
              </HStack>

              <HStack>
                <Heading as="h3" size="lg">
                  {trade.stock}
                </Heading>
                <Text
                  mt={3}
                  ml={3}
                  alignSelf="center"
                  fontSize="12"
                  color="#868686"
                ></Text>
              </HStack>
              <SimpleGrid columns={3} spacing={6}>
                <Smallcardtwo
                  title="Resultat"
                  content={
                    <Text color={trade.profit < 0 ? "#E53E3E" : "#05D8A5"}>
                      {trade.profit ? `${formatNumber(trade.profit)} kr` : "N/A"}
                    </Text>
                  }
                 
                  tooltip={
                    "Totala resultatet för traden. "
                  }
                  customWidth="200px"
                  customHeight="100px"
                />
                <Smallcardtwo
                  title="Datum"
                  content={trade.date ? trade.date : "N/A"}
                  buttonText="Ladda upp CSV"
                  tooltip={
                    "Datum för traden."
                  }
                  customWidth="200px"
                  customHeight="100px"
                />

                <Smallcardtwo
                  title="Tag"
                  content={
                    <Tag size="sm" colorScheme="blue">
                      {trade.tag ? trade.tag : "N/A"}
                    </Tag>
                  }
                  buttonText="Ladda upp CSV"
                  tooltip={
                    "Vald playbook-tag."
                  }
                  customWidth="200px"
                  customHeight="100px"
                />
                <Smallcardtwo
                  title="Size"
                  content={trade.size ? `${formatNumber(trade.size)} kr` : "N/A"}
                  buttonText="Ladda upp CSV"
                  tooltip={
                    "Totala sizen för traden."
                  }
                  customWidth="200px"
                  customHeight="100px"
                />

                <Smallcardtwo
                  title="Antal Avslut"
                  content={trade.avslut ? `${trade.avslut} ` : "N/A"}
                  buttonText="Ladda upp CSV"
                  tooltip={
                    "Totala antalet avslut i traden."
                  }
                  customWidth="200px"
                  customHeight="100px"
                />
                <Smallcardtwo
                  title="Trade Rating"
                  content={
                    <Badge
                      p={1}
                      pl={2}
                      pr={2}
                      fontSize={12}
                      background="rgba(5, 216, 165, 0.15)"
                      textColor="#05D8A5"
                      textTransform={"none"}
                      borderRadius={"md"}
                    >
                      Placering: {rank}
                    </Badge>
                  }
                  buttonText="Ladda upp CSV"
                  tooltip={
                    "Hur förhåller sig traden till dina andra playbook-trades (rankat)."
                  }
                  customWidth="200px"
                  customHeight="100px"
                />
              </SimpleGrid>
            </VStack>
            {selectedImage ? (
              <Box
                position="relative"
                width="808px"
                height="356px"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={onOpen} // Assuming `onOpen` is used to open a modal
              >
                <Image
                  src={imageSrc || selectedImage}
                  alt="Selected"
                  width="100%" // Fill the width of the container
                  height="100%" // Fill the height of the container
                  objectFit="cover" // Ensure the image covers the entire element
                  borderRadius="12px"
                />
                {isHovering && (
                  <IconButton
                    icon={<IoIosResize />}
                    position="absolute"
                    left="50%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="2"
                    aria-label="View larger"
                  />
                )}
                <IconButton
                  icon={<CloseIcon />}
                  position="absolute"
                  right="8px"
                  top="8px"
                  size="sm"
                  zIndex="2" // Make sure it's above the image
                  onClick={(event) => {
                    event.stopPropagation(); // This stops the click event from bubbling up
                    setSelectedImage(null);
                    setScreenshot(null); // Nollställer screenshot tillståndet
                    setImageSrc(null); // Nollställer imageSrc så att ingen bild visas
                  }}
                  aria-label="Remove image"
                />
              </Box>
            ) : (
              <Box
                width="808px"
                height="356px"
                p={6}
                bg="#090A0B"
                borderWidth="1px"
                borderColor="#1C1E22"
                borderRadius="2xl"
                color="white"
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer" // Make the cursor a pointer to indicate it's clickable
                _hover={{ textDecoration: "underline" }} // Change the hover style here
                onClick={() => document.getElementById("file-input").click()}
              >
                {/* Screenshot section */}
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  hidden
                  id="file-input"
                />
                <Text size="md" _hover={{ color: "lightblue" }}>
                  Bifoga bild
                </Text>
              </Box>
            )}

            <AlertDialog
              isOpen={isAlertOpen}
              leastDestructiveRef={cancelRef}
              onClose={() => setIsAlertOpen(false)}
            >
              <AlertDialogOverlay>
                <AlertDialogContent color="#090A0B">
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Spara ändringar
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Vill du gå tillbaka till trades utan att spara?
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={() => setIsAlertOpen(false)}
                    >
                      Nej
                    </Button>
                    <Button
                      backgroundColor={"#090A0B"}
                      color={"white"}
                      onClick={() => {
                        /* Save logic here */ setIsAlertOpen(false);
                        onBack();
                      }}
                      ml={3}
                    >
                      Bekräfta
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
              <ModalOverlay bg="blackAlpha.800" />
              <ModalContent backgroundColor="transparent" boxShadow="none">
                <ModalBody>
                  <Image
                    src={imageSrc || selectedImage}
                    alt="Selected"
                    maxWidth="100%" // Set the max width to 80% of the modal
                    maxHeight="100%" // Set the max height to 80% of the modal
                    margin="auto" // Center the image in the modal
                    display="block"
                    objectFit="contain" // Maintain aspect ratio
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </HStack>

          <TextEditor
            id={`textEditor_${playbookId}`} // Use playbookId as a unique identifier
            onChange={(content) => {
              setContent1(content);
            }}
            initialContent={trade.globalNotes}
          ></TextEditor>
        </VStack>
      </Box>
    );
  }
};

export default TradeFullView;

