import { Button, ChakraProvider, Flex, HStack, Heading, Text, VStack, extendTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Terms = () => {
	const navigate = useNavigate();
	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={75} mr={75} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Terms & Policy
							</Text>
						</HStack>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<Heading fontSize={24} mb={0} color={"#05D8A5"}>
						Användarvillkor
					</Heading>

					<Text fontSize="14" maxWidth="800px" color="#868686" opacity={0.87}>
						<strong>Allmänt</strong>
						<br />
						Materialet på denna hemsida tillhandahålls av vi Tradalyze ("vi", "oss" eller "vår") som driver www.tradalyze.com. Genom att besöka denna
						hemsida, lämnar du ditt samtycke till nedanstående villkor. Om du inte accepterar villkoren bör du inte använda dig av hemsidan samt avstå
						från att ladda ner någon information eller något material från den. Tradalyze tillåter att du besöker bolagets hemsida via din dator eller
						skriver ut kopior av utdrag från dessa sidor enbart för ditt personliga bruk och inte för spridning om inte detta först skriftligen
						godkänts av bolaget. Tillgång till enskilda dokument på Tradalyze hemsida kan vara föremål för ytterligare villkor som anges i dessa
						enskilda dokument. Användandet av denna hemsida och dess innehåll är tillåtet i privat, icke-kommersiellt syfte. Pressmeddelande och andra
						dokument som klassificeras som offentliga kan användas för offentlig kommunikation.
						<br />
						<br />
						<strong>Frånsägande av ansvar</strong>
						<br />
						All information på denna webbplats är endast för underhållnings- och utbildningssyften. Även om informationen anses vara korrekt bör ingen
						av uppgifterna på denna webbplats betraktas som helt pålitlig vid verkliga investeringsbeslut. Tradalyze är en distributör (och inte en
						utgivare) av innehåll som tillhandahålls av tredje parter. Ingen av informationen på denna webbplats garanteras vara korrekt, komplett,
						användbar eller aktuell. Tradalyze och dess tredje parts leverantörer garanterar inte eller säkerställer sådan noggrannhet,
						fullständighet, användbarhet eller aktualitet. Ingen av informationen på hemsidan skall heller ses som någon form av finansiell
						rådgivning. Tradalyze kan heller inte garantera att informationen är korrekt gällande exempelvis skrivfel, yttre påverkan och tekniska fel
						resultera i missledande information. Tradalyze garanterar inte att anslutningen till hemsidan är avbrottsfri eller felfri. Tradalyze
						förbehåller sig rätten att när som helst revidera hemsidan eller ta bort tillgången till den.
						<br />
						<br />
						Allt som laddas ner från hemsidan, eller någon annan hemsida som du länkats vidare till, sker efter eget gottfinnande och på egen risk. Du
						är ensam ansvarig för eventuell åverkan på ditt datasystem eller förlust av data som orsakats av en nedladdning.
						<br />
						<br />
						<strong>Användarens skyldigheter</strong>
						<br />
						Som användare garanterar och utfäster du, att du kommer använda denna hemsida på ett sätt som är förenligt med samtliga regler och
						bestämmelser i dessa villkor och följa alla befintliga och framtida uppdaterade riktlinjer och regler för hemsidan.
						<br />
						Du godkänner att inte använda hemsidan för att:
						<br />
						<ul>
							<br />
							<li>sprida spam eller oönskad e-post,</li>
							<li>utge dig för att representera Tradalyze eller vara någon annan,</li>
							<li>förfalska rubriker eller manipulera innehållet på hemsidan för att dölja dess ursprung,</li>
							<li>lämna falska uppgifter om din anknytning till en person eller enhet,</li>
							<li>agera på ett sätt som inverkar negativt på andras möjlighet att använda hemsidan,</li>
							<li>deltar i aktiviteter som förbjuds av gällande lagstiftning,</li>
							<li>
								skicka in material som kränker eller gör intrång i andras rättigheter, eller som är olagligt, kränkande, nedsättande, vulgärt eller på
								annat sätt anstötligt, eller som innehåller reklam eller erbjudanden om produkter eller tjänster, eller
							</li>
							<li>
								samla in eller spara en annan användares personuppgifter om du inte erhållit uttryckligt medgivande från användaren och Tradalyze.
							</li>
						</ul>
						Alla som använder hemsidan måste följa dessa villkor.
					</Text>

					<Heading fontSize={24} mb={0} color={"#05D8A5"}>
						Integritetspolicy
					</Heading>
					<Text fontSize="14" maxWidth="800px" color="#868686" opacity={0.87}>
						<strong>Denna integritetspolicy beskriver hur Tradalyze hanterar personlig information. </strong>
						<br />
						Tradalyze ("vi", "oss" eller "vår") driver www.tradalyze.com
						<br />
						Denna sida informerar dig om våra riktlinjer för insamling, användning och delning av personlig data när du använder vår tjänst samt de
						valmöjligheter du har i samband med denna information. Genom att använda tjänsten samtycker du till att vi samlar in och använder
						information enligt denna policy. Denna integritetspolicy gäller endast information som samlas in via denna webbplats. Den kommer att
						meddela dig följande:
						<br />
						<br />
						Vilken personligt identifierbar information som samlas in från dig via webbplatsen, hur den används och med vilka den kan delas. Vilka
						valmöjligheter som finns tillgängliga för dig angående användningen av din data. De säkerhetsåtgärder som vidtas för att skydda mot
						missbruk av din information. Hur du kan korrigera eventuella felaktigheter i informationen.
						<br />
						<br />
						<strong>Insamling, användning och delning av information</strong>
						<br />
						Vi är ensamma ägare av informationen som samlas in på denna webbplats. Vi har endast tillgång till och samlar in information som du
						frivilligt ger oss när du använder webbplatsen. Vi kommer inte att sälja eller hyra ut denna information till någon. Vi använder din
						information för att tillhandahålla tjänsterna på webbplatsen. Vi kommer inte att dela din information med någon tredje part utanför vår
						organisation, förutom när det är nödvändigt för att uppfylla din begäran. Specifikt: Om du uttryckligen delar en affär offentligt kommer
						viss information om din handel att vara tillgänglig på webbplatsen. Om du inte vill att denna information ska vara offentlig, vänligen
						dela inte affärer. Om du ej ber oss att inte göra detta, kan vi i framtiden kontakta dig via e-post för att informera dig om erbjudanden,
						nya produkter eller tjänster, eller förändringar i denna integritetspolicy. Vi samlar in statistisk & information om webbtrafik med hjälp
						av en tredjeparts webbanalystjänst; detta kan använda en cookie på din dator, men ingen användaridentifierbar information delas någonsin
						med denna tredje part utöver "vanlig" webbanalys som IP-adress, webbläsare, etc. I inget fall kommer dina individuella data att göras
						tillgängliga för någon, och ingen personligt identifierbar information kommer att göras tillgänglig eller associeras med någon av datan.
						<br />
						<br />
						<strong>Åtkomst till och kontroll över din information</strong>
						<br />
						Du kan när som helst välja att inte få framtida kontakter från oss. Du kan göra följande när som helst genom att kontakta oss, exempelivs
						genom e-post, och begära att vi tar bort alla data vi har om dig eller uttrycka eventuella farhågor du har angående vår användning av din
						data.
						<br />
						<br />
						<strong>Säkerhet</strong>
						<br />
						Vi vidtar försiktighetsåtgärder för att skydda din information. När du skickar känslig information via webbplatsen, skyddas din
						information både online och offline. Varje gång vi samlar in känslig information (som din handelsdata) krypteras denna information och
						skickas till oss på ett säkert sätt. Om du känner att vi inte följer denna integritetspolicy, vänligen kontakta oss omedelbart via e-post
						på tradalyze@gmail.com.
					</Text>

					<Heading fontSize={24} mb={0} color={"#05D8A5"}>
						Sekretessavtal
					</Heading>
					<Text fontSize="14" maxWidth="800px" color="#868686" opacity={0.87}>
						<strong>AVTAL OM BETATEST</strong>
						<br />
						<br />
						DU SOM ANVÄNDARE AV TRADALYZE HAR VID SKAPANDE AV KONTO, ELLER GENOM ATT LADDA NER, STARTA ELLER INSTALLERA BETAPRODUKTEN, GODKÄNT ATT
						BINDAS AV DETTA AVTAL (ELLER ANNAT VILLKOR I DETTA AVTAL).
						<br />
						<br />
						Detta <strong>AVTAL OM BETATEST</strong> (”avtalet”) tecknas av och sluts mellan Filip Bystedt, William Balksten och Oliver Ingeström
						(hädanefter refererat som ”grundarna”) och dig (”licenstagare” eller ”du”) då uppgifter av konfidentiellt slag kan komma att lämnas.
						<br />
						<br />
						<strong>1. Konfidentiell information</strong>
						<br />
						Konfidentiell information är information om företaget (eller kommande företag), oavsett slag, som kan komma att lämnas under partnernas
						samarbete som berör kunskap, affärshemligheter, eller annan information oavsett typ om vardera avtalsparten vars yppande har ett kommersiellt värde eller kan komma att
						skada Tradalyze. 
						<br />
						<br />
						<strong>2. Icke - konfidentiell information</strong>
						<br />
						Information som är allmänt veterlig, eller som avtalsparterna redan vet om sedan innan avtalet godkänns, anses inte vara konfidentiell
						information.
						<br />
						<br />
						<strong>3. Sekretess gäller</strong>
						<br />
						Detta avtal om sekretess innebär att ingen utav avtalsparterna får lämna ut sådan konfidentiell information om varandra till någon
						utomstående, såsom personer, företag, myndigheter och organisationer.
						<br />
						<br />
						<strong>4. Undantag från sekretess</strong>
						<br />
						Konfidentiell information kan dock ges ut om den ena avtalsparten tillåter den andra avtalsparten att göra detta, eller om myndighet
						bestlutat att sådan information skall lämnas ut.
						<br />
						<br />
						<strong>5. Återlämnande av konfidentiell information</strong>
						<br />
						Vid upphörandet av parternas samarbete skall konfidentiell information återlämnas. Återlämnande skall också ske om någon utan
						avtalspartnerna kräver detta.
						<br />
						<br />
						<strong>6. Vite vid avtalsförbrytelse</strong>
						<br />
						Om licenstagare utan godkännande kontaktar leverantörer/affärskontakter i syfte att göra affärer i eget namn med dessa skall vite utgå med 50 000 SEK.
						<br />
						<br />
						Detta <strong>AVTAL OM BETATEST</strong> (”avtalet”) tecknas av och sluts mellan Filip Bystedt, William Balksten, Oliver Ingeström
						(”grundarna”) och dig (”licenstagare” eller ”du”) då uppgifter av konfidentiellt slag kan komma att lämnas.
						<br />
						<br />
						Med ”betaprodukt” avses de produkter, programvaror, tjänster och/eller funktioner som betecknar betaversioner eller förhandsversioner som
						tillhandahålls dig under testet.
						<br />
						Med “Tradalyze” avses idén om företag av Filip Bystedt, William Balksten, Oliver Ingeström.
						<br />
						<br />
						<strong>Licens</strong>
						<br />
						Tradalyze ger dig en icke exklusiv, icke överföringsbar, icke underlicensierbar licens för att använda betaprodukten, enbart i syfte att
						testa, forska på och utvärdera den. Du får inte modifiera, demontera, dekompilera, bakåtutveckla, hyra ut, leasa, låna ut, överföra eller
						göra kopior av betaprodukten.
						<br />
						<br />
						<strong>Äganderätt</strong>
						<br />
						All rätt, intresse och äganderätt i och för betaprodukten och tillhörande dokumentation, inklusive alla förbättringar och modifikationer
						av dem, är och ska fortsatt vara grundarnas (Filip Bystedt, William Balksten, Oliver Ingeström). Undantaget är de rättigheter som
						uttryckligen ges här, men inga övriga rättigheter ges dig genom detta, varken uttryckligt eller underförstått.
						<br />
						<br />
						<strong>Sekretess</strong>
						<br />
						Betaprodukten, det faktum att produkten existerar, all medföljande dokumentation och all information som Tradalyze avslöjar för dig i
						enlighet med detta avtal eller på annat sätt i samband med betaprodukten, inklusive utan begränsning prestandadata, funktioner och annan
						information som rör eller erhålls från betaprodukten, är ”konfidentiell information” som tillhör Tradalyze. Du kommer inte att använda den
						konfidentiella informationen förutom vid behov i enlighet med detta avtal, och du kommer inte att avslöja någon del av den konfidentiella
						informationen för någon annan person eller enhet. Du kommer att vidta alla rimliga åtgärder för att skydda den konfidentiella
						informationen från att användas av eller avslöjas för obehöriga. Konfidentiell information omfattar inte information som: (1) rättmätigt
						kändes till av dig vid tidpunkten för avslöjande utan någon sekretessplikt, (2) är lagenligt erhållen av dig från en tredje part utan
						begränsning vad gäller användning eller avslöjande, eller (3) är eller blir allmänt känd för allmänheten utan att du gör något misstag
						eller överträder detta avtal.
						<br />
						<br />
						<strong>Data och feedback.</strong>
						<br />
						Du samtycker till att Tradalyze kan få information och data från dig i samband med din registrering, installation och användning av
						betaprodukten inklusive, utan begränsning, personlig information. Tradalyze kan också samla in och bearbeta teknisk information och
						prestandainformation om din användning av betaprodukten och använda denna information för att stödja och felsöka problem, tillhandahålla
						uppdateringar, analysera trender och förbättra Tradalyze-produkter eller -tjänster. Tradalyze ska ha rätt att använda sådana data och dela
						sådana data med Tradalyze-partners för programändamål. Du godkänner härmed att Tradalyze bearbetar och överför denna information,
						inklusive, i förekommande fall, personlig information, i överensstämmelse med sekretess.
					</Text>

					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Terms;
