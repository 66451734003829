import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Heading, Icon, IconButton, Tooltip, VStack, useEditableControls } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

import EmotionSelector from "../../inputs/emotionselector";

const MentalityDisplay = ({ mentalityvalue, title, tooltip, secondTitle, isEditing }) => {
	const colorWhite = "#fff";

	const [localMentalityValue, setLocalMentalityValue] = useState(mentalityvalue);

	const getEmojiIdFromMentality = (mentalityValue) => {
		const mapping = { 1: 1, 2: 2, 3: 3 }; // 0=
		return mapping[mentalityValue] || null;
	};
	const [selectedEmoji, setSelectedEmoji] = useState(getEmojiIdFromMentality(mentalityvalue));

	useEffect(() => {
		setSelectedEmoji(getEmojiIdFromMentality(mentalityvalue));
	}, [mentalityvalue]);

	return (
		<Box
			borderBottomWidth="0px"
			borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
			borderLeftWidth="0.5px"
			borderRightWidth="0.5px"
			borderStyle="solid"
			borderTopWidth="0.5px"
			backgroundColor="rgba(29, 32, 35, 0.6)"
			filter="hue-rotate(0deg)"
			opacity="1"
			borderRadius="12px"
			boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
			overflow="hidden"
			p={6}
		
			ml={0}
			mr={0}
			w={"100%"}
			minWidth="464px"
			height="250px"
			display="flex"
			alignItems="center"
			position="relative"
		>
			<Box position="absolute" top={0} left={0} p={6}>
				<VStack align="flex-start" mt={1}>
					<Box>
						<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
							{title}{" "}
							<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
								<span>
									<Icon as={AiOutlineInfoCircle} boxSize={18} color="#434A54" ml={2} />
								</span>
							</Tooltip>
						</Heading>
						<Heading fontSize="14" ml={5} fontWeight="medium" color="#868686" opacity={0.87}>
							{secondTitle}
						</Heading>
					</Box>

					<Box ml={-2} mt={5}>
						<EmotionSelector isDisabled={!isEditing} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
					</Box>
				</VStack>
			</Box>
		</Box>
	);
};

const EditableControls = () => {
	const colorWhite = "#fff";
	const { isEditing, getSubmitButtonProps, getEditButtonProps } = useEditableControls();

	return isEditing ? (
		<IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} mr={2} />
	) : (
		<IconButton background={"none"} color={colorWhite} size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
	);
};

export default MentalityDisplay;
