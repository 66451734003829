import React, { useEffect, useState } from "react";
import { Box, HStack, Icon, Spacer, Text, VStack } from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useLocation } from "react-router-dom";

export default function AccountBar({ icon, navSize, path }) {
  const location = useLocation();
  const active = location.pathname === path;
  const colorActive = "#05D8A5";
  const colorInactive = "#868686";
  const [email, setEmail] = useState("Loading...");

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await fetch(`https://backend-production-41c0.up.railway.app/email`, {
          // Updated endpoint
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            // Replace 'YOUR_TOKEN_HERE' with the actual token.
            // The token is typically stored in local storage or cookies after login.
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`, // Example of retrieving token from localStorage
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setEmail(data.email);
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
        setEmail("Error loading email");
      }
    };

    fetchUserEmail();
  }, []);

  const truncateEmail = (email) => {
    const maxLen = 12;
    return email.length > maxLen ? `${email.substring(0, maxLen)}...` : email;
  };

  return (
    <Box p={navSize === "small" ? 1 : 2}>
      <HStack lineHeight={0.1} align={"start"}>
        <Box
          w="30px"
          h="30px"
          borderRadius="8px"
          p={2}
          mt={-1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor={active ? colorActive : "#1C1E22"}
        >
          <Icon
            as={icon}
            fontSize={navSize === "small" ? "sm" : "lg"}
            color={active ? "#1C1E22" : colorInactive}
          />
        </Box>
        {navSize !== "small" && (
          <VStack ml={2} align={"start"} spacing={1}>
            <Text color="#fff" fontSize="sm">
              Alpha Tester
            </Text>
            <Text fontSize="xs" color={colorInactive}>
              {truncateEmail(email)}
            </Text>
          </VStack>
        )}
        <Spacer />
        <Icon
          as={FiMoreVertical}
          cursor="pointer"
          ml={-1}
          display={navSize === "small" ? "none" : "flex"}
        />
      </HStack>
    </Box>
  );
}
