import {
  Box,
  Button,
  FormControl,
  Heading,
  Highlight,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { MdBuild } from "react-icons/md";

function FeedbackButton() {
	const { isOpen, onOpen, onClose } = useDisclosure(); // Hook for managing modal state
	const colorWhite = "#fff"; // White color for text
	const initialRef = React.useRef(null); // Ref for initial focus
	const finalRef = React.useRef(null); // Ref for final focus

	return (
		<>
			{/* Feedback button */}
			<Button
				leftIcon={<MdBuild />}
				borderBottomWidth="0px"
				backgroundColor={"rgba(29, 32, 35, 0.6)"}
				filter="hue-rotate(0deg)"
				opacity="1"
				color={"white"}
				boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
				mr={2}
			
				size={"sm"}
				fontWeight={"medium"}
				_hover={{
					backgroundColor: "#05D8A5" 
				}}
				onClick={() => (window.location.href = "https://discord.gg/U6Gcpuw9md")}
				
			>
				Skicka Feedback
			</Button>

			{/* Modal window */}
			<Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
				{/* Modal overlay */}
				<ModalOverlay />

				{/* Modal content */}
				<ModalContent backgroundColor={""}>
					<VStack pl={8} pr={8} pt={8}>
						<Heading fontSize={24} color={"#090A0B"}>
							Feedback fönster
						</Heading>
						<Box color="#090A0B">
							{/* Highlighted text */}
							<Highlight query="uppskattar" styles={{ px: "1", py: "1", bg: "#edf2f7" }}>
								Vi uppskattar verkligen all typ av feedback, vare sig det gäller funktioner, design, önskningar osv.
							</Highlight>
							<Text>Skriv nedan så får vi info om det direkt!</Text>
						</Box>
					</VStack>
					<ModalCloseButton color={"#090A0B"} />

					{/* Modal body */}
					<ModalBody pb={2}>
						<FormControl>
							<Textarea placeholder="Skriv här..." color={"#090A0B"} />
						</FormControl>
					</ModalBody>

					{/* Modal footer */}
					<ModalFooter>
						<Button backgroundColor={"#131c23"} color={colorWhite} mr={3}>
							Skicka
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default FeedbackButton;
