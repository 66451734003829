import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import FilterPopover from "./FilterPopover";
import { LuSearch } from "react-icons/lu";

const Filters = ({ columnFilters, setColumnFilters }) => {
  const taskName = columnFilters.find((f) => f.id === "namn")?.value || "";

  const onFilterChange = (id, value) =>
    setColumnFilters((prev) =>
      prev
        .filter((f) => f.id !== id)
        .concat({
          id,
          value,
        })
    );

  return (
    <HStack  spacing={3}>
      <InputGroup size="sm" maxW="12rem">
        <InputLeftElement pointerEvents="none">
          <Icon as={LuSearch} />
        </InputLeftElement>
        <Input
          type="text"
          backgroundColor={"transparent"}
          borderWidth="1px"
          borderColor="#1C1E22"
          sx={{
            "::placeholder": {
              color: "#868686",
              _hover: { color: "white" }, // Change this to your desired placeholder color
            },
          }}
          _hover={{ bg: "transparent", color: "white",  }}
          _focus={{ bg: "transparent", color: "white" , borderColor: "#868686"}}
          _active={{ bg: "transparent", color: "white", borderColor: "#868686" }}
         
          variant="filled"
          placeholder="Aktie namn"
          borderRadius={5}
          value={taskName}
          onChange={(e) => onFilterChange("namn", e.target.value)}
        />
      </InputGroup>
      <FilterPopover
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </HStack>
  );
};
export default Filters;