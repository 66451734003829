import { Box, Button, ButtonGroup, VStack } from "@chakra-ui/react";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { LuHeading1, LuHeading2 } from "react-icons/lu";
import { MdCode, MdFormatBold, MdFormatItalic, MdFormatListNumbered, MdFormatQuote, MdFormatUnderlined } from "react-icons/md";
import "../css/texteditorstyle.css";

const MenuBar = ({ editor }) => {
	if (!editor) {
		return null;
	}

	return (
		<ButtonGroup
			size="sm"
			isAttached
			sx={{
				button: {
					backgroundColor: "transparent",
					border: "1px solid",
					color: "white",
					size: "md",
					fontWeight: "bold",
					borderColor: "#1C1E22",
					_hover: {
						backgroundColor: "#1C1E22",
					},
					fontSize: "20px",
					marginRight: 4,
					padding: 4,
				},
			}}
		>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
			>
				<LuHeading1 />
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
			>
				<LuHeading2 />
			</Button>
			<Button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive("blockquote") ? "is-active" : ""}>
				<MdFormatQuote />
			</Button>

			<Button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive("orderedList") ? "is-active" : ""}>
				<MdFormatListNumbered />
			</Button>
			<Button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive("bulletList") ? "is-active" : ""}>
				<AiOutlineUnorderedList />
			</Button>
			<Button onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive("codeBlock") ? "is-active" : ""}>
				<MdCode />
			</Button>

			<Button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive("bold") ? "is-active" : ""}>
				<MdFormatBold />
			</Button>
			<Button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive("italic") ? "is-active" : ""}>
				<MdFormatItalic />
			</Button>
			<Button onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive("underline") ? "is-active" : ""}>
				<MdFormatUnderlined />
			</Button>
		</ButtonGroup>
	);
};

const TextEditorLP = ({ id, onChange, initialContent }) => {
	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Placeholder.configure({
				placeholder: "Skriv någonting…",
			}),
		],
		content: initialContent || "",
		onUpdate: ({ editor }) => {
			const content = editor.getJSON();
			localStorage.setItem(`editorContent_${id}`, JSON.stringify(content)); // Use id in key
			onChange(content);
		},
	});
	
	useEffect(() => {
		if (editor && initialContent) {
		  try {
			// Attempt to parse the initialContent as JSON first
			const doc = JSON.parse(initialContent);
			editor.commands.setContent(doc);
		  } catch (error) {
			// If parsing fails, assume it's HTML or plain text
			// Note: Handling of HTML directly might require additional setup or extensions in TipTap
			editor.commands.setContent(initialContent, { html: true });
		  }
		}
	  }, [editor, initialContent]);
	  
	const saveContent = () => {
		if (editor) {
			const content = editor.getJSON(); // Get editor content in JSON format
			//console.log(content); // Log content to //console

			// Here, you would typically send 'content' to your backend server for storage
		}
	};

	useEffect(() => {
		const savedContent = JSON.parse(localStorage.getItem(`editorContent_${id}`)); // Use id in key
		if (editor && savedContent) {
			editor.commands.setContent(savedContent, false);
		}
	}, [editor, id]);

	return (
		<Box
			bg="transparent"
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			p={6}
			mb={2}
			ml={0}
			mr={0}
			width={"100%"}
			minHeight="100px"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			<VStack align="flex-start" spacing={5}>
				<MenuBar editor={editor} />
				<div className="editor-container" spellCheck="false" style={{ width: "100%", minHeight: "100px", overflowY: "auto" }}>
					<EditorContent editor={editor}>{/* TipTap Editor content */}</EditorContent>
				</div>
			</VStack>
		</Box>
	);
};

export default TextEditorLP;
