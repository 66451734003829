import { Button, ChakraProvider, Flex, HStack, Heading, Text, VStack, extendTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import NonEditableInput from "../../components/inputs/changeInput";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";

import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Installningar = () => {
	// Test data array
	const testData = [
		{ Trade: 1, Profit: 300, Size: 500 },
		{ Trade: 2, Profit: -200, Size: 1000 },
		{ Trade: 3, Profit: 400, Size: 1500 },
		{ Trade: 4, Profit: 700, Size: 2000 },
		{ Trade: 5, Profit: -500, Size: 2500 },
	];

	const navigate = useNavigate();
	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={75} mr={75} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Inställningar
							</Text>
						</HStack>
						<MyMarqueeComponent></MyMarqueeComponent>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<Heading fontSize={24} mb={0} color={"#05D8A5"}>
						Inställningar
					</Heading>

					<VStack align={"flex-start"} spacing={5}>
						<Heading fontSize="18" color="#fff" opacity={0.87}>
							Kontodetaljer:
						</Heading>
						<NonEditableInput placeholderValue={"E-post"}></NonEditableInput>
						<NonEditableInput placeholderValue={"Lösenord"}></NonEditableInput>
					</VStack>

					<Footer></Footer>
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Installningar;
