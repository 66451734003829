import { Box, Flex, Icon, Menu, MenuButton, Text } from "@chakra-ui/react";
import React, { useState } from "react"; // Import useState
import { Link as RouterLink, useLocation } from "react-router-dom";

export default function ComingSoon({ icon, title, navSize, path }) {
	const location = useLocation();
	const active = location.pathname === path;

	const boxStyle = {
		borderWidth: "1px",
		borderColor: "#1C1E22",
		backgroundColor: "#090A0B",
		borderRadius: "8px",
	};
	// Hover state
	const [isHovered, setIsHovered] = useState(false);
	return (
		<Flex mt={0} flexDir="column" w="100%" alignItems={navSize === "small" ? "center" : "flex-start"}>
			<Menu placement="right">
				<RouterLink to={path}>
					<Box
						borderRadius={navSize === "small" ? "6px" : 6} // Reduced border radius for a thinner look
						w={navSize === "large" && "190px"} // Optional: Reduced width for a thinner look
						minHeight="35px" // Reduced min-height
						ml={navSize === "small" ? 4 : 0}
						maxHeight="35px" // Reduced max-height
						{...(active ? boxStyle : {})}
						_hover={{ ...boxStyle }}
						onMouseEnter={() => setIsHovered(true)} // Set hover state to true
						onMouseLeave={() => setIsHovered(false)} // Set hover state to false
						alignItems="center"
						justifyContent={navSize === "small" ? "center" : "flex-start"}
					>
						<MenuButton w="100%">
							<Flex ml={navSize === "small" ? "small" : 2}
								justifyContent={navSize === "center" ? "center" : "flex-start"} // Centrera innehållet när sidebar är kollapsad
								mt={navSize === 2 ? 4 : 0.5}>
								<Box
										w={navSize === "20px" ? "none" : "30px"}
										h={navSize === "auto" ? "none" : "30px"}
										borderRadius={navSize === "small" ? "6px" : "8px"} // Reduced icon box border radius
									p={0.5} // Reduced padding inside icon box
									display="inline-flex"
									alignItems="center"
									justifyContent="center"
									backgroundColor={"transparent"}
								>
									<Icon
										as={icon}
										fontSize={navSize === "small" ? "sm" : "lg"} // Adjusted font size for icon
										color={active ? "#05D8A5" : "#282828"}
										_focus={{
											color: "#05D8A5",
										}}
									/>
								</Box>
								<Text
									ml={1} // Reduced margin-left for text
									mt={1} // Reduced margin-top for text
									color={active ? "#fff" : "#282828"}
									display={navSize === "small" ? "none" : "flex"}
									fontSize="sm" // Optional: Reduced font size for a thinner look
									textDecoration={"line-through"}
								>
									{isHovered ? "Kommer snart..." : title} {/* Change text based on hover state */}
								</Text>
							</Flex>
						</MenuButton>
					</Box>
				</RouterLink>
			</Menu>
		</Flex>
	);
}
