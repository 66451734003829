import { Box, Flex, Text } from "@chakra-ui/react";
import Carousel from "nuka-carousel";
import React from "react";

const CombinedCarousel = () => {
	const slides = [
		"“As a professional trader and managing partner of a trading firm, I cannot take you seriously as a trader if you do not journal. To me this would be like a football player not lifting weights.”\n— Mike Bellafiore SMB Capital Partner",
		"“Review your past trades to find your strengths and weaknesses. Use this knowledge to adjust your strategy, improve your trading and increase your profitability.”\n— Yvan Byeajee",
		"“Emotions are like a flame to a trader’s account, if not controlled they will burn it to the ground.”\n— Alexander Elder",
		"“Without data, you’re just another person with an opinion.”\n— W. Edwards Deming",
		"“The disciplined trader consistently follows his trading plan, and the undisciplined trader consistently deviates from it.”\n— Brett Steenbarger",
		"“Most traders overvalue the importance of the trading day – their trading. The least important part of your trading day is your actual trading. The work you do after to improve and prepare for future trading opportunities is most important.”\n— Mike Bellafiore",
		"“Recording trades is important. It helps in tracking progress, monitoring setups, and pinpointing areas for improvement. With Good Hundreds of trades recorded, you gain insights into your trading style. Consider it your ultimate book, worthy of frequent revisits, offering improvement, and generating great ideas.”\n— @VCPSwing",
	];
	
	return (
		<Box borderWidth="1px" borderColor="#1C1E22" borderRadius="2xl" overflow="hidden" p={6} mb={2} ml={0} mr={0} minWidth={"500px"} width="500px" height="250px">
			<Carousel
				autoplayInterval={6000}
				withoutControls={true}
				wrapAround={true}
				autoplay={true}
				adaptiveHeightAnimation={true}
				// ... any other carousel properties you need
			>
				{slides.map((slide, index) => {
					const [quote, author] = slide.split("\n");
					return (
						<Flex key={index} height="100%" alignItems="center" justifyContent="center" textAlign="center">
							<Text color={"#868686"} mt={4} px={2}>
								{quote}
								<br />
								<Text as="span" fontWeight="bold">
									{author}
								</Text>
							</Text>
						</Flex>
					);
				})}
			</Carousel>
		</Box>
	);
};

export default CombinedCarousel;
