import {
  Box,
  Button,
  CSSReset,
  ChakraProvider,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  extendTheme,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AvtalModal from "../../components/pagerelated/avtal";
import TermsModal from "../../components/pagerelated/termsmodal";
import { ReactComponent as MyIcon } from "./MyIcon.svg";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#090A0B",
        color: "white",
      },
    },
  },
  colors: {
    white: "#ffffff",
    teal: "#05D8A5",
    tealer: "02C497",
    customColor: {
      500: "#868686",
      800: "02C497",
    },
    gray: {
      600: "#808080",
    },
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          borderColor: "gray.500",
          _focus: {
            borderColor: "gray.500",
            boxShadow: "0 0 1px #808080",
          },
          _placeholder: {
            // Add this block to change placeholder color
            color: "#868686", // Change this to your desired color
          },
        },
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          backgroundColor: "teal",
          _disabled: {
            backgroundColor: "teal",
          },
        },
      },
    },
  },
});

export default function Signup() {
  const [betaKey, setBetaKey] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAvtalModalOpen, setIsAvtalModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ betaKey, email, password }),
        credentials: "include",
      });

      const data = await response.json();

      if (!response.ok) {
        //console.error(`status ${response.status}, text: ${response.statusText}`);
        throw new Error(
          "BETA-Nyckel existerar inte, eller så finns redan kontot." ||
            data.message
        );
      }

      if (data.success) {
        navigate("/stats");
      }
    } catch (error) {
      setError(error.message);
      //console.error("An error occurred during signup", error.message);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box
        w={["full", "lg"]}
        p={[8, 10]}
        mt={[20, "10vh"]}
        mx="auto"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        height="70vh"
      >
        <VStack spacing={5} align="flex-start" w="full">
          <Box ml="0px" mb={4}>
            <MyIcon width="100%" height="%100" />
          </Box>
          <VStack align="flex-start" w="full" spacing={6}>
            <Heading color="white" alignSelf="flex-start" fontSize="36">
              Skapa konto
            </Heading>
            <Text color="#868686" alignSelf="flex-start" fontSize="16">
              Välkommen! Fyll i dina detaljer
            </Text>
          </VStack>
          <FormControl>
            <FormLabel color="white">BETA-Nyckel</FormLabel>
            <Input
              value={betaKey}
              onChange={(e) => setBetaKey(e.target.value)}
              variant="outline"
              borderColor="#868686"
              borderWidth="1px"
              borderRadius="lg"
              p={2}
              _focus={{
                outline: "none",
                inline: "none",
                boxShadow: "0 0 0 2px gray",
              }}
              _hover={{
                borderColor: "gray",
              }}
              focusBorderColor="none"
              placeholder="BETA-Nyckel"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="white">Email</FormLabel>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outline"
              borderColor="#868686"
              borderWidth="1px"
              borderRadius="lg"
              p={2}
              _focus={{
                outline: "none",
                inline: "none",
                boxShadow: "0 0 0 2px gray",
              }}
              _hover={{
                borderColor: "gray",
              }}
              focusBorderColor="none"
              placeholder="Email"
            />
          </FormControl>
          <FormControl>
            <FormLabel color="white">Lösenord</FormLabel>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outline"
              borderColor="#868686"
              borderWidth="1px"
              borderRadius="lg"
              p={2}
              type="password"
              _focus={{
                outline: "none",
                boxShadow: "0 0 0 2px gray",
              }}
              _placeholder={{
                _focus: {
                  color: "transparent",
                },
              }}
              focusBorderColor="none"
              placeholder="......"
            />
          </FormControl>
          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <VStack w={"full"} spacing={2} alignItems="flex-start" mb={3}>
              <Checkbox
                colorScheme="customColor"
                color="#868686"
                borderColor="#868686"
              >
                Kom ihåg mig
              </Checkbox>
              <Text mb={-2} color="#868686">
                Genom att registrera dig hos Tradalyze godkänner du{" "}
              </Text>
              <Link
                onClick={() => setIsAvtalModalOpen(true)}
                style={{ textDecoration: "underline", color: "#868686 " }}
                color="#868686"
              >
                Sekretessavtal,
              </Link>{" "}
              <Link
                onClick={() => setIsTermsModalOpen(true)}
                style={{
                  textDecoration: "underline",
                  color: "#868686 ",
                  lineHeight: "15px",
                }}
              >
                Användarvillkor och Integritetspolicy.
              </Link>
            </VStack>
          </Flex>
          {error && (
            <Text color="red" mt={2}>
              BETA-Nyckel existerar inte, eller så finns redan kontot.
            </Text>
          )}
          <Button
            color="white"
            bg="teal"
            w="full"
            alignSelf="end"
            _hover={{ bg: "#02A881" }}
            onClick={handleSignup}
          >
            Skapa konto
          </Button>
          <Flex alignItems="center" mb={3}>
            <Text color="customColor.500" mr={2}>
              Har du redan ett konto?
            </Text>
            <Button variant="link" color="teal" mt="-4" as={Link} to="/login">
              Logga in
            </Button>
          </Flex>
        </VStack>
      </Box>

      <AvtalModal
        isOpen={isAvtalModalOpen}
        onClose={() => setIsAvtalModalOpen(false)}
        onAccept={() => {
          // Handle acceptance logic for AvtalModal
          setIsAvtalModalOpen(false);
        }}
      />

      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onAccept={() => {
          // Handle acceptance logic for TermsModal
          setIsTermsModalOpen(false);
        }}
      />
    </ChakraProvider>
  );
}
