import { Input, Text, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const EditableCell = ({ getValue, row, column, table, isEditable }) => {
	const initialValue = getValue();
	const [value, setValue] = useState(initialValue);
	const [textColor, setTextColor] = useState("white"); // State to manage text color
	const [editing, setEditing] = useState(false);
	// Determine the input type based on column ID
	const inputType = ["resultat", "courtage", "storlek", "resultatprocent"].includes(column.id) ? "number" : "text";

	const onBlur = () => {
		// Directly update the data without formatting only if editing
		if (editing) {
			table.options.meta?.updateData(row.index, column.id, value);
			setEditing(false); // Exit editing mode
		}
	};
	


	useEffect(() => {
		// Update text color based on value, only for columns "resultat" and "resultatprocent"
		if (["resultat", "resultatprocent"].includes(column.id)) {
			if (parseFloat(value) > 0) {
				setTextColor("#05d8a5");
			} else {
				setTextColor("#E53E3E");
			}
		} else {
			setTextColor("white"); // Reset to default color for other columns
		}
	}, [value, column.id]);

	const handleChange = (e) => {
		const newValue = e.target.value;
		// Update the state without formatting
		setValue(newValue);
		setEditing(true); // Start editing
	};
	

	const renderInput = () => (
		<Input
			type={inputType}
			color="white"
			bg="transparent"
			fontWeight={"medium"}
			value={value}
			onChange={handleChange}
			onBlur={onBlur}
			disabled={!isEditable}
			onClick={(e) => e.stopPropagation()}
			variant="unstyled"
			_hover={{ bg: "transparent" }}
		/>
	);

	const renderText = () => {
		let suffix = "";
		if (column.id === "resultatprocent") {
			suffix = " %";
		} else if (column.id === "resultat" || column.id === "courtage" || column.id === "storlek") {
			suffix = " kr";
		}
		return <Input variant="unstyled" isReadOnly={true} value={value + suffix} color={textColor} fontWeight={"medium"} bg="transparent"></Input>;
	};

	// Render the Input or Text based on the editability
	return (
		<Box display="flex" width="100%" alignItems="center">
			{isEditable ? renderInput() : renderText()}
		</Box>
	);
};

export default EditableCell;
