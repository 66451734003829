import { Badge, Box } from "@chakra-ui/react";

const WinLossCell = ({ resultat }) => {
    const isLoss = Number(resultat) < 0;  // Check if the result is negative

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Badge
                style={{
                    backgroundColor: isLoss ? "rgba(229, 62, 62, 0.15)" : "rgba(5, 216, 165, 0.15)",
                    color: isLoss ? "#E53E3E" : "#05D8A5",
                }}
            >
                {isLoss ? "Förlust" : "Vinst"}
            </Badge>
        </Box>
    );
};

export default WinLossCell;
