import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  Switch,
  Table,
  TableContainer,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineTag } from "react-icons/ai";
const colorWhite = "#fff";

const Tradetablecard = ({
  title,
  tooltip,
  secondTitle,
  trades,
  antalTrades,
  userId,
}) => {
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState({});
  const toast = useToast();

  const uploadPlaybook = async (tradeData, notes, date) => {
    try {
      const totalSize = tradeData.trades.reduce(
        (acc, curr) => acc + parseInt(curr.size, 10),
        0
      );

      const avslut = tradeData.trades.length;

      const requestBody = {
        trade: tradeData,
        totalSize,
        avslut,
        notes,
        date,
      };

      // //console.log("Skickar request med följande body:", requestBody);

      const response = await fetch("https://backend-production-41c0.up.railway.app/createPlaybook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      ////console.log("Serverns svarstatus:", response.status);

      if (response.ok) {
        const data = await response.json();
        ////console.log("Playbook uppladdad:", data);

        toast({
          title: "Laddads upp korrekt.",
          description: "Din trade finns nu sparad i Playbook.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errorText = await response.text();
        //console.error("Serverns svarstext:", errorText);
        throw new Error("Kunde inte ladda upp Playbook" + errorText);
      }
    } catch (error) {
      //console.error(error);

      toast({
        title: "Failade att ladda upp.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUploadClick = (trade, date) => {
    const notes = tags.map((tag) => tag.label).join(", ");

    // Check if there are tags associated with the trade
    if (notes.trim() === "") {
      toast({
        title: "Failade att ladda upp.",
        description: "Välj en tagg innan du kan ladda upp.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Prevent upload if there are no tags
    }

    uploadPlaybook(trade, notes, date);
  };

  const addTag = (stock, tagText) => {
    if (tagText) {
      // Check if there's already a tag for this stock and replace it if so
      const existingIndex = tags.findIndex((tag) => tag.stock === stock);
      let newTags;
      if (existingIndex > -1) {
        newTags = tags.map((tag, index) =>
          index === existingIndex ? { ...tag, label: tagText } : tag
        );
      } else {
        // If there's no existing tag for this stock, add the new tag
        newTags = [...tags, { stock, label: tagText }];
      }
      setTags(newTags);
    }
    setTagInput((prev) => ({ ...prev, [stock]: false }));
  };

  const removeTag = (stock, e) => {
    e.stopPropagation(); // Prevent click event from bubbling up to the parent elements
    setTags((prevTags) => prevTags.filter((tag) => tag.stock !== stock));
  };

  const handleTagInputChange = (e, stock) => {
    if (e.key === "Enter") {
      addTag(stock, e.target.value);
    } else if (e.key === "Escape") {
      setTagInput((prev) => ({ ...prev, [stock]: false }));
    }
  };

  const showTagInput = (stock) => {
    setTagInput((prev) => ({ ...prev, [stock]: true }));
  };

  const groupTradesByStock = (trades) => {
    const groups = {};
    trades.forEach((trade) => {
      if (!groups[trade.stock]) {
        groups[trade.stock] = {
          trades: [],
          totalSize: 0,
          totalProfit: 0,
          totalAvslut: 0,
          totalProfitPercentage: 0, // This will be recalculated
          totalCourtage: 0,
        };
      }
      groups[trade.stock].trades.push(trade);
      groups[trade.stock].totalAvslut += parseFloat(trade.avslut);
      groups[trade.stock].totalSize += parseFloat(trade.size);
      groups[trade.stock].totalProfit += parseFloat(trade.profit);
      groups[trade.stock].totalCourtage += parseFloat(trade.courtage);
    });

    // Recalculate the totalProfitPercentage for each group
    Object.keys(groups).forEach((stock) => {
      if (groups[stock].totalSize !== 0) {
        groups[stock].totalProfitPercentage =
          (groups[stock].totalProfit / groups[stock].totalSize) * 100;
      } else {
        groups[stock].totalProfitPercentage = 0;
      }
    });

    return groups;
  };

  const [groupedTrades, setGroupedTrades] = useState({});
  const [groupVisibility, setGroupVisibility] = useState({});
  const [showGrouped, setShowGrouped] = useState(false);

  useEffect(() => {
    handleGroupTrades(trades);
  }, [trades]);

  const handleGroupTrades = (trades) => {
    const grouped = groupTradesByStock(trades);
    setGroupedTrades(grouped);

    const visibility = {};
    Object.keys(grouped).forEach((stock) => {
      visibility[stock] = false;
    });
    setGroupVisibility(visibility);
  };

  const handleToggleGrouped = () => {
    setShowGrouped(!showGrouped);
  };

  const toggleGroupVisibility = (stock) => {
    setGroupVisibility((prev) => ({
      ...prev,
      [stock]: !prev[stock],
    }));
  };
  const logTrade = (trade) => {
    ////console.log([trade]);
  };

  const renderGroupedTradeRow = (group, stock) => (
    <Tr
      onClick={() => toggleGroupVisibility(stock)}
      key={stock}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        color: "#05D8A5",
        fontSize: "18px",
      }}
    >
      <Td colSpan={2}>{stock}</Td>
      <Td color={"white"}>{group.totalSize} kr</Td>
      <Td style={{ color: group.totalProfit >= 0 ? "#05D8A5" : "#E53E3E" }}>
        {group.totalProfit} Kr
      </Td>
      <Td style={{ color: group.totalProfit >= 0 ? "#05D8A5" : "#E53E3E" }}>
        {group.totalProfitPercentage.toFixed(2)} %
      </Td>
      <Td color={"white"}>{group.totalCourtage.toFixed(0)} kr</Td>
      <Td>
        <HStack spacing={2}>
          {tags
            .filter((tag) => tag.stock === stock)
            .map((tag) => (
              <Tag size="sm" colorScheme="blue" key={tag.label}>
                <TagLabel>{tag.label}</TagLabel>
                <TagCloseButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from closing the tag input
                    removeTag(stock, e);
                  }}
                />
              </Tag>
            ))}

          {/* Show input if 'Playbook' button is clicked or tagInput state for the stock is true */}
          {tagInput[stock] ? (
            <Input
              size="sm"
              width="100px"
              onKeyPress={(e) => handleTagInputChange(e, stock)}
              onKeyDown={(e) => handleTagInputChange(e, stock)}
              autoFocus
              onBlur={() =>
                setTagInput((prev) => ({ ...prev, [stock]: false }))
              }
            />
          ) : (
            !tags.some((tag) => tag.stock === stock) && (
              <Button
                leftIcon={<AiOutlineTag />}
                colorScheme="pink"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click from propagating
                  showTagInput(stock);
                }}
              >
                Playbook
              </Button>
            )
          )}
          <Button
            size="xs"
            ml={5}
            backgroundColor={"#05D8A5"}
            fontWeight={"bold"} // Eller vilken färgsättning du vill använda
            onClick={(e) => {
              e.stopPropagation(); // Prevent the event from propagating to the parent Tr element
              handleUploadClick(group);
            }}
          >
            Ladda upp
          </Button>
        </HStack>
      </Td>
    </Tr>
  );

  const renderTableHeaders = () => {
    // Check if any group is expanded
    const isAnyGroupExpanded = Object.values(groupVisibility).some((v) => v);

    if (showGrouped && !isAnyGroupExpanded) {
      // Grouped view and no group is expanded
      return (
        <>
          <Th>Bolag</Th>
          <Th></Th>
          <Th>Size</Th>
          <Th>Resultat Kr</Th>
          <Th>Resultat %</Th>
          <Th>Courtage</Th>
        </>
      );
    } else {
      // Not grouped view or grouped view with an expanded group
      return (
        <>
          <Th>Trade Nr.</Th>
          <Th>Bolag</Th>
          <Th>Size</Th>
          <Th>Resultat Kr</Th>
          <Th>Resultat %</Th>
          <Th>Courtage</Th>
        </>
      );
    }
  };

  const renderTradeRow = (trade, index) => (
    <Tr key={index}>
      <Td>{index + 1}</Td>
      <Td>{trade.stock}</Td>
      <Td>{trade.size} kr</Td>
      <Td style={{ color: trade.profit >= 0 ? "#05D8A5" : "#E53E3E" }}>
        {trade.profit} Kr
      </Td>
      <Td style={{ color: trade.profit >= 0 ? "#05D8A5" : "#E53E3E" }}>
        {trade.totalProfitPercentage} %
      </Td>
      <Td>{trade.courtage.toFixed(0)} kr</Td>
      <Td></Td>
    </Tr>
  );

  return (
    <Box
      borderBottomWidth="0px"
      borderColor="var(--token-2b080329-29ba-481c-ac92-832881069b36, rgba(255, 255, 255, 0.05))"
      borderLeftWidth="0.5px"
      borderRightWidth="0.5px"
      borderStyle="solid"
      borderTopWidth="0.5px"
      backgroundColor="rgba(29, 32, 35, 0.6)"
      filter="hue-rotate(0deg)"
      opacity="1"
      borderRadius="12px"
      boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
      overflow="hidden"
      p={6}
      mb={2}
      ml={0}
      mr={0}
      width="100%"
      display="flex"
      position="relative"
    >
      <VStack width="100%">
        <HStack justifyContent="space-between" w="100%">
          <Box>
            <Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
              {title}{" "}
              <Tooltip
                label={tooltip}
                placement="auto-start"
                gutter={20}
                bg="#090A0B"
                color={colorWhite}
                padding={3}
                borderRadius={"2xl"}
              >
                <span>
                  <Icon
                    as={AiOutlineInfoCircle}
                    boxSize={18}
                    color="#868686"
                    ml={2}
                  />
                </span>
              </Tooltip>
            </Heading>
            <Heading fontSize="14" ml={5} color="#868686" opacity={0.87}>
              {secondTitle}
            </Heading>
          </Box>
          <HStack mr={12} justifyContent="center">
            <Heading fontSize="14" mr={2} color="#868686" opacity={0.87}>
              Klumpa ihop Tickers:
            </Heading>
            <Switch
              colorScheme="pink"
              mb={1}
              onChange={handleToggleGrouped}
              isChecked={showGrouped}
            ></Switch>
          </HStack>
        </HStack>

        <TableContainer
          ml={5}
          mt={3}
          mb={3}
          maxH={`${trades.length * 100}px`}
          width="100%"
        >
          <Table variant="striped" colorScheme="blackAlpha" width="100%">
            <Thead>
              <Tr>{renderTableHeaders()}</Tr>
            </Thead>
            <Tbody fontWeight={"medium"}>
              {showGrouped
                ? Object.keys(groupedTrades).map((stock) => (
                    <React.Fragment key={stock}>
                      {renderGroupedTradeRow(groupedTrades[stock], stock)}
                      {groupVisibility[stock] &&
                        groupedTrades[stock].trades.map((trade, index) =>
                          renderTradeRow(trade, index)
                        )}
                    </React.Fragment>
                  ))
                : trades.map((trade, index) => renderTradeRow(trade, index))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Box>
  );
};

export default Tradetablecard;
