import React from 'react';
import { Box, VStack, Text } from "@chakra-ui/react";
import LinechartComponentLP from "../pagerelated/landingpage/linechartlanding";

const TrendsBox = ({ lineChartData }) => {
  return (
    <Box
      bg="#090A0B"
      borderWidth="1px"
      borderColor="#1C1E22"
      borderRadius="2xl"
      overflow="hidden"
      p={1}
      mb={2}
      ml={0}
      mr={0}
      minW={{ base: "100%", "2xl": "1000px" }}
      minH="400px"
      display={"flex"}
      flexDirection={"column"}
      justifyContent="space-between"
      position="relative"
    >
      <Box >
        <LinechartComponentLP
          
          title={"Utveckling"}
          secondTitle={"Cumulative PnL"}
          data={lineChartData}
          tooltip={
            "Visar den samlade nettovinsten eller nettoförlusten över handelsdagen. Y-axeln är därmed resultat i kr, samt X-axeln antalet trades."
          }
          defaultChartType={"areaspline"}
        />
      </Box>
      <VStack ml={10} mb={4} mt={{base: -4, md: 0}} align={"start"}>
        <Text fontSize="18" color="#fff" opacity={0.87}>
          Trender
        </Text>
        <Text
          fontSize="16"
          mt={-4}
          width={"80%"}
          color="#868686"
          opacity={0.87}
        >
          Grafer för alla trender du behöver analysera.
          Cumulative PnL, Expectancy, Profit Factor med mera.
        </Text>
      </VStack>
    </Box>
  );
};

export default TrendsBox;