import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import RadioComponent from "./radiobuttons";
import "../../components/pagerelated/css/loading.css";
const CSVUpload = ({ isOpen, onClose, onSuccess }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Ny state för loading popup
  const fileInputRef = useRef(null);
  const toast = useToast();

  const handleRadioChange = (index) => {
    setSelectedOption(index);
  };

  const radioOptions = [
    {
      title: "Avanza",
      index: 0,
      customColor: "#05d8a5",
      customTitleColor: "#fff",
    },
    {
      title: "Nordnet",
      index: 1,
      customColor: "#090A0B",
      customTitleColor: "#fff",
    },
    {
      title: "Pareto",
      index: 2,
      customColor: "#1d335a",
      customTitleColor: "#fff",
    },
  ];

  const renderModalButton = () =>
    selectedOption !== null && (
      <Button
        backgroundColor="#090A0B"
        color="#FFFFFF"
        onClick={() => fileInputRef.current.click()}
        mr={3} // Adjusted marginRight to be in 'ch' unit
      >
        Ladda upp
      </Button>
    );

  const uploadCSV = async () => {
    if (!fileInputRef.current.files[0] || selectedOption === null) {
      toast({
        title: "Fel",
        description: "Vänligen välj en fil och en mäklare",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true); // Aktivera loading popup

    const formData = new FormData();
    formData.append("file", fileInputRef.current.files[0]);
    formData.append("source", radioOptions[selectedOption].title);

    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/newpage/csv", {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      setIsLoading(false); // Dölj loading popup

      if (response.ok) {
        toast({
          title: "Success",
          description: "CSV-filen har laddats upp",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        onSuccess();
      } else {
        throw new Error("Problem med att skicka filen");
      }
    } catch (error) {
      setIsLoading(false); // Dölj loading popup vid fel
      toast({
        title: "Fel",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderSaveCSV = () =>
    selectedOption !== null && (
      <Button backgroundColor="#05D8A5" color="#FFFFFF" onClick={uploadCSV}>
        Spara
      </Button>
    );


    const LoadingAnimation = () => (
      <span >
      
        <span className="dot1"> . </span>
        <span className="dot2">. </span>
        <span className="dot3">. </span>
      </span>
    );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent backgroundColor="">
        <VStack pl={8} pr={8} pt={8}>
          <Heading fontSize={24} color="#090A0B">
            Välj Mäklare
          </Heading>
          <Box color="#090A0B">{/* Explanation text */}</Box>
        </VStack>
        <ModalCloseButton color="#090A0B" />
        <ModalBody pb={2} mb={5}>
          <VStack justifyContent="center" spacing={5} mt={5}>
            {radioOptions.map((option) => (
              <RadioComponent
                key={option.index}
                title={option.title}
                customColor={option.customColor}
                titleColor={option.customTitleColor}
                isSelected={selectedOption === option.index}
                onChange={() => handleRadioChange(option.index)}
              />
            ))}
            <Input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={() => {}}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          {renderModalButton()}
          {renderSaveCSV()}
        </ModalFooter>
      </ModalContent>

      {/* Loading Popup */}
      <Modal isOpen={isLoading} isCentered>
        <ModalOverlay />
        <ModalContent height="100px">
          <ModalBody  backgroundColor="#05D8A5" alignContent={"center"} textAlign={"center"} color="black" justifyContent={"center"}>
            
            Laddar upp data<LoadingAnimation /> <br></br>
            Detta kan ta upp till 5 minuter beroende filens storlek
          </ModalBody>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default CSVUpload;
