import { Box, Button, ChakraProvider, Flex, HStack, Heading, Text, VStack, extendTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";
import TaskTable from "../../components/pagerelated/newTableTest/tanstacktable";

const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "#090A0B",
				color: "white",
			},
		},
	},
});

const colorWhite = "#fff";

const Omoss = () => {
	const navigate = useNavigate();
	return (
		<ChakraProvider theme={theme}>
			<Flex>
				<Sidebar />

				<VStack flex={1} alignItems="flex-start" minWidth="max-content" gap="7" pl={75} mr={75} pt={0} className="custom-scrollbar">
					<HStack justifyContent="space-between" w="100%" mt={9}>
						<HStack alignSelf="self-start">
							<Text alignSelf="center" fontSize="12" color="#868686">
								Fönster
							</Text>
							<Text alignSelf="center" fontSize="16" color={colorWhite}>
								/ Om oss
							</Text>
						</HStack>
						<HStack mr={5} alignItems="center" alignSelf="self-end">
							<FeedbackButton></FeedbackButton>
							<Button onClick={() => handleLogout(navigate)} fontWeight={"medium"}>
								Logga ut
							</Button>
						</HStack>
					</HStack>

					<Heading fontSize={24} mb={0} color={"#05D8A5"}>
						Om oss
					</Heading>

					<Text fontSize="14" maxWidth="800px" color="#868686" opacity={0.87}>
						Det är fantastiskt roligt att du nu är med oss på Tradalyze. Vi är här för att ge dig som investerare och trader, alla de verktyg du behöver för att effektivisera din handel. Som ett "intranät" hur klyschigt det än låter.
						<br />
						Vi vet att det kan vara en utmaning att hålla sig till en handelsstrategi. Därför
						erbjuder vi verktyg för att analysera och journalisera dina affärer, så att du kan fatta bättre investeringsbeslut och öka dina chanser
						till lönsamma affärer. Tidigare har sådana analyser ofta gjorts manuellt och tidsödande via exempelvis kalkylark, olika sorters
						anteckningstjänster eller med fysiska böcker.
						<br />
						<br />
						Tranalyze är lösningen på dessa utmaningar. Genom att ladda upp din handelsdata på vår plattform, hanterar vi allt det tunga arbetet. Vi
						ger dig tillgång till detaljerad statistik och viktiga nyckeltal som tar din handel till professionell nivå. Med vår hjälp får du en
						smidigare handelsupplevelse, och mer tid över till det viktiga - själva handeln.
						<br />
						<br />
						Vår vision är tydlig: att göra trading enklare, roligare och mer lönsamt för alla investerare och trader, särskilt på den nordiska marknaden. Vi tre grabbar
						från Göteborg har en passion för handel och teknik, och har personlig erfarenhet av vikten av gedigen backtesting och
						resultatjournal. 
						<br />
						<br />
						Som en av de första användarna får du nu tillgång till vår tidiga Alpha-version av Tradalyze. Det är viktigt att förstå att detta är en
						initial version av programmet, vilket innebär att vissa planerade funktioner kanske ännu inte är implementerade. Utseendet och
						användargränssnittet kan också skilja sig från den slutgiltiga versionen. Syftet med denna tidiga version är att testa kärnfunktioner och
						samla feedback för att säkerställa att vårt verktyg verkligen möter dina behov som trader. Vi uppskattar din förståelse för att saker kan bugga, och bli fel. 
						Vi har många funktioner av värde för dig som trader som hade kunnat integreras, däremot kräver detta investeringar vilket vi inte vill hetsa oss igenom under en MVPn/Alphan.
						<br />
						<br />
						Innan vi fortsätter, är det även viktigt att påpeka betydelsen av att förstå våra{" "}
						<strong>sekretessavtal, Integritetspolicy och Användarvillkor</strong> för denna Alpha-version. Det kan låte löjligt, men vi vill följa standarden och dessa dokument innehåller viktig
						information om hur dina data hanteras, vilka rättigheter och skyldigheter du har som användare, samt hur vi säkerställer din integritet
						och dataskydd. För att säkerställa en transparent och trygg användarupplevelse, hittar du dessa dokument längst ner på vår hemsida via en
						länk. Vi rekommenderar starkt att du tar dig tid att läsa igenom dessa innan du börjar använda Tradalyze, för att fullt ut förstå
						villkoren för ditt deltagande i vår Alpha-version. 
						<br />
						<br />						
						Vi vill även poängtera att dessa sekretessavtal, integritetspolicy och användarvillkoren kan sakna viktiga detaljerer. Om det är något ni undrar, kontakta via mail, Discord eller Twitter.
						<br />
						<br />
						Vi hoppas plattformen kommer till någlunda värde och att ni tror på oss: att ersätta dagens oldschool - krångliga system... 
						<br />
						<br />
						Tack!
						<br />
						<br />
						<strong>// Filip, William, Oliver</strong>
					</Text>

					<Footer></Footer>

					
				</VStack>
			</Flex>
		</ChakraProvider>
	);
};

export default Omoss;
