import React from 'react';
import { Box, VStack, Text, SimpleGrid } from "@chakra-ui/react";
import EmotionSelector from "../inputs/emotionselector";
import TextEditorLP from "../pagerelated/landingpage/texteditorlanding";
import RadioComponent from "../buttons/radiobuttons";

const JournalBox = ({ selectedEmoji, setSelectedEmoji, handleEmojiChange, textEditorContent, radioOptions, selectedOption, handleRadioChange }) => {
  return (
    <Box
      bg="#090A0B"
      borderWidth="1px"
      borderColor="#1C1E22"
      borderRadius="2xl"
      overflow="hidden"
      p={1}
      mb={2}
      ml={0}
      mr={0}
      minW={{ base: "100%", lg: "384px" }}
      minH={{base: "50vh", md: "550px"}}
      display="flex"
      justifyContent="flex-start"
      alignItems="end"
      position="relative"
    >
      <VStack justify={"center"} mb={4} align={"start"} ml={10}>
        <Box transform={{base: "translateX(-40%)", md: "translateX(0%)"}} ml={{base: 0, md: 20, lg: 0}} mt={{base: 10, md: 10, lg: 0}} mb={{base: 5, md: 5, lg:50}}>
          <EmotionSelector
            selectedEmoji={selectedEmoji}
            setSelectedEmoji={setSelectedEmoji}
            onEmojiSelect={handleEmojiChange}
          />
        </Box>
        <Box  mb={{base:10, md: 10, lg: 100}}>
          <TextEditorLP
            id="editor6"
            initialContent={textEditorContent}
            onChange={(content) => {}}
          />
        </Box>
        <SimpleGrid
        ml={{base: 0, md: 20, lg: 0}}
          transform="translateX(5%)"
          mb={{base: 10, md: 10, lg: 20}}
          columns={3}
          spacing={10}
        >
          {radioOptions.map((option) => (
            <RadioComponent
              key={option.index}
              title={option.title}
              customColor={option.customColor}
              titleColor={option.customTitleColor}
              isSelected={selectedOption === option.index}
              onChange={() =>
                handleRadioChange(option.index, option.title)
              }
            />
          ))}
        </SimpleGrid>
        <Text fontSize="18" color="#fff" opacity={0.87}>
          Journal
        </Text>
        <Text
          width={{base: "40%", md: "59%", lg: "40%"}}
          fontSize="16"
          mt={-4}
          color="#868686"
          opacity={0.87}
        >
          För journal dagligen på ett smidigare och roligare sätt.
          Gå sedan tillbaka för att analysera och gränsa trenderna.
        </Text>
      </VStack>
    </Box>
  );
};

export default JournalBox;