import { Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

function AvtalModal({ isOpen, onClose, onAccept }) {
	const [isChecked, setIsChecked] = useState(false);
	const modalContentRef = useRef(null);

	useEffect(() => {
		if (isOpen && modalContentRef.current) {
			// Scroll to the top of the modal content when it becomes visible
			modalContentRef.current.scrollTop = 0;
		}
	}, [isOpen]);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleAccept = () => {
		if (isChecked) {
			// Perform the acceptance action here
			onAccept();
		} else {
			// Display an error message or handle the case where the checkbox is not checked
		}
	};
	return (
		<Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropFilter="blur(3px) " />
			<ModalContent maxH="80vh" overflowY="auto" minW="700px" background="#090A0B" ref={modalContentRef}>
				<ModalHeader>
					<Heading fontSize={24} mb={0} color="#05D8A5">
						Sekretessavtal
					</Heading>
				</ModalHeader>
				<ModalBody>
					<Text fontSize="14px" color="#868686" opacity={0.87}>
						<strong>AVTAL OM TEST</strong>
						<br />
						<br />
						DU SOM ANVÄNDARE AV TRADALYZE HAR VID SKAPANDE AV KONTO, ELLER GENOM ATT LADDA NER, STARTA ELLER INSTALLERA BETAPRODUKTEN, GODKÄNT ATT
						BINDAS AV DETTA AVTAL (ELLER ANNAT VILLKOR I DETTA AVTAL).
						<br />
						<br />
						Detta <strong>AVTAL OM BETATEST</strong> (”avtalet”) tecknas av och sluts mellan Tradalyze AB och dig (”licenstagare” eller ”du”) då
						uppgifter av konfidentiellt slag kan komma att lämnas.
						<br />
						<br />
						<strong>1. Konfidentiell information</strong>
						<br />
						Konfidentiell information är information om företaget (eller kommande företag), oavsett slag, som kan komma att lämnas under partnernas
						samarbete som berör kunskap, affärshemligheter, eller annan information oavsett typ om vardera avtalsparten vars yppande kan komma att
						skada företaget (eller kommande företag).
						<br />
						<br />
						<strong>2. Icke - konfidentiell information</strong>
						<br />
						Information som är allmänt veterlig, eller som avtalsparterna redan vet om sedan innan avtalet godkänns, anses inte vara konfidentiell
						information.
						<br />
						<br />
						<strong>3. Sekretess gäller</strong>
						<br />
						Detta avtal om sekretess innebär att ingen utav avtalsparterna får lämna ut sådan konfidentiell information om varandra till någon
						utomstående, såsom personer, företag, myndigheter och organisationer.
						<br />
						<br />
						<strong>4. Undantag från sekretess</strong>
						<br />
						Konfidentiell information kan dock ges ut om den ena avtalsparten tillåter den andra avtalsparten att göra detta, eller om myndighet
						bestlutat att sådan information skall lämnas ut.
						<br />
						<br />
						<strong>5. Återlämnande av konfidentiell information</strong>
						<br />
						Vid upphörandet av parternas samarbete skall konfidentiell information återlämnas. Återlämnande skall också ske om någon utan
						avtalspartnerna kräver detta.
						<br />
						<br />
						<strong>6. Vite vid avtalsförbrytelse</strong>
						<br />
						Om licenstagare utan godkännande kontaktar leverantörer/affärskontakter i syfte att göra affärer i eget namn med dessa skall vite utgå med 50 000 SEK.
						<br />
						<br />
						Detta <strong>AVTAL OM BETATEST</strong> (”avtalet”) tecknas av och sluts mellan Filip Bystedt, William Balksten, Oliver Ingeström (Alt
						Tradalyze AB) och dig (”licenstagare” eller ”du”) då uppgifter av konfidentiellt slag kan komma att lämnas.
						<br />
						<br />
						Med ”betaprodukt” avses de produkter, programvaror, tjänster och/eller funktioner som betecknar betaversioner eller förhandsversioner som
						tillhandahålls dig under testet.
						<br />
						Med “Tradalyze” avses idén om företag av Filip Bystedt, William Balksten, Oliver Ingeström.
						<br />
						<br />
						<strong>Licens</strong>
						<br />
						Tradalyze ger dig en icke exklusiv, icke överföringsbar, icke underlicensierbar licens för att använda betaprodukten, enbart i syfte att
						testa, forska på och utvärdera den. Du får inte modifiera, demontera, dekompilera, bakåtutveckla, hyra ut, leasa, låna ut, överföra eller
						göra kopior av betaprodukten.
						<br />
						<br />
						<strong>Äganderätt</strong>
						<br />
						All rätt, intresse och äganderätt i och för betaprodukten och tillhörande dokumentation, inklusive alla förbättringar och modifikationer
						av dem, är och ska fortsatt vara grundarnas (Filip Bystedt, William Balksten, Oliver Ingeström). Undantaget är de rättigheter som
						uttryckligen ges här, men inga övriga rättigheter ges dig genom detta, varken uttryckligt eller underförstått.
						<br />
						<br />
						<strong>Sekretess</strong>
						<br />
						Betaprodukten, det faktum att produkten existerar, all medföljande dokumentation och all information som Tradalyze avslöjar för dig i
						enlighet med detta avtal eller på annat sätt i samband med betaprodukten, inklusive utan begränsning prestandadata, funktioner och annan
						information som rör eller erhålls från betaprodukten, är ”konfidentiell information” som tillhör Tradalyze. Du kommer inte att använda den
						konfidentiella informationen förutom vid behov i enlighet med detta avtal, och du kommer inte att avslöja någon del av den konfidentiella
						informationen för någon annan person eller enhet. Du kommer att vidta alla rimliga åtgärder för att skydda den konfidentiella
						informationen från att användas av eller avslöjas för obehöriga. Konfidentiell information omfattar inte information som: (1) rättmätigt
						kändes till av dig vid tidpunkten för avslöjande utan någon sekretessplikt, (2) är lagenligt erhållen av dig från en tredje part utan
						begränsning vad gäller användning eller avslöjande, eller (3) är eller blir allmänt känd för allmänheten utan att du gör något misstag
						eller överträder detta avtal.
						<br />
						<br />
						<strong>Data och feedback.</strong>
						<br />
						Du samtycker till att Tradalyze kan få information och data från dig i samband med din registrering, installation och användning av
						betaprodukten inklusive, utan begränsning, personlig information. Tradalyze kan också samla in och bearbeta teknisk information och
						prestandainformation om din användning av betaprodukten och använda denna information för att stödja och felsöka problem, tillhandahålla
						uppdateringar, analysera trender och förbättra Tradalyze-produkter eller -tjänster. Tradalyze ska ha rätt att använda sådana data och dela
						sådana data med Tradalyze-partners för programändamål. Du godkänner härmed att Tradalyze bearbetar och överför denna information,
						inklusive, i förekommande fall, personlig information, i överensstämmelse med sekretess.
					</Text>
					<Text></Text>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default AvtalModal;
