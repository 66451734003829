import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
const Footer = () => {
	const colorWhite = "#fff";


	return (
		<Box color={colorWhite} py={4} bottom={0} left={0} width="100%">
			<Flex justifyContent="space-between" alignItems="center">
				<Text fontSize="sm" color={"#868686"}>
					© {new Date().getFullYear()} Tradalyze 
				</Text>
				<Flex color={"#868686"}>
					<Link mx={2} fontSize="sm" as={RouterLink} to="/omoss">
						Om oss
					</Link>
					<Link mx={2} fontSize="sm" as={RouterLink} to="/terms">
						{" "}
						{/* Use RouterLink */}
						Terms & Policy
					</Link>
					<Link mx={2} fontSize="sm" href="https://twitter.com/tradalyze">
						Twitter
					</Link>
					<Link mx={2} fontSize="sm" href="https://discord.gg/U6Gcpuw9md">
						Discord
					</Link>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Footer;
