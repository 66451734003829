import { Box, Flex, HStack, Heading, Icon, Skeleton, Text, Tooltip, VStack, Button } from "@chakra-ui/react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HSIndicators from "highcharts/indicators/indicators";
import React, { useEffect, useRef, useState } from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdBarChart, MdTrendingUp } from "react-icons/md";
import HighchartsBoost from 'highcharts/modules/boost';

import { useNavigate } from "react-router-dom";

HSIndicators(Highcharts);
HighchartsBoost(Highcharts); 
const colorWhite = "#fff";

const LinechartComponent = ({ title, tooltip, secondTitle, data, prefix,   showDayInTooltip = true, defaultChartType, lastValueKey, roundLastValue, showXAxis = true }) => {
	const [chartType, setChartType] = useState(defaultChartType);
	const [isZoomed, setIsZoomed] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const lastDataPoint = data && data.length > 0 ? data[data.length - 1] : null;
	const navigate = useNavigate();
	let lastValue;
	if (lastDataPoint !== null) {
		if (typeof lastDataPoint === "object" && lastDataPoint !== null) {
			lastValue = typeof lastDataPoint[lastValueKey] === "number" ? lastDataPoint[lastValueKey] : "N/A";
		} else if (typeof lastDataPoint === "number") {
			lastValue = lastDataPoint;
		} else {
			lastValue = "N/A";
		}
	} else {
		lastValue = "N/A";
	}

	if (typeof roundLastValue === "number" && !isNaN(lastValue)) {
		lastValue = lastValue.toFixed(roundLastValue);
	}

	useEffect(() => {
		if (data && data.length > 0) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
		if (data && data.length === 1) {
			setChartType("column");
		} else {
			setChartType(defaultChartType);
		}
	}, [data, defaultChartType]);

	const options = {
		title: null,
		yAxis: {
			gridLineColor: "#868686",
			gridLineWidth: 0.5,
			gridLineDashStyle: "longdash",
			opposite: false,
			labels: {
				style: {
					color: "#868686",
					fontWeight: 500,
				},
				align: "right",
			},
			title: {
				enabled: false,
			},
		},
		boost: {
			useGPUTranslations: true, // Enable GPU translations for improved performance
			seriesThreshold: 1,
		},
		tooltip: {
            backgroundColor: "#090A0B",
            borderColor: "#1C1E22",
            style: {
                color: "#fff",
            },
            useHTML: true,
            formatter: function () {
                let tooltipHtml = `<div style="max-width: 120px; font-size: 12px;">`;

                // Conditionally add day and date to the tooltip
                if (showDayInTooltip) {
                    const weekdays = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
                    const dayOfWeek = weekdays[new Date(this.x).getDay()];
                    tooltipHtml += `<b>${dayOfWeek}, ${Highcharts.dateFormat("%b %e, %Y", this.x)}</b>`;
                }

                tooltipHtml += `<table style="margin-top: 3px; width: 100%;">`;

                this.points.forEach((point) => {
                    const roundedValue = Math.round(point.y * 100) / 100;
                    tooltipHtml +=
                        `<tr><td style="color: ${point.series.color}; padding-right: 4px; padding-bottom: 2px;">${point.series.name}: </td>` +
                        `<td style="text-align: right; padding-bottom: 2px;"><b>${roundedValue} ${point.series.userOptions.prefix || ""}</b></td></tr>`;
                });

                tooltipHtml += "</table></div>";
                return tooltipHtml;
            },
            shared: true,
        },

		rangeSelector: {
			inputEnabled: false,

			buttonPosition: {
				y: -40,
				align: "right",
			},
			labelStyle: {
				display: "none",
			},
			buttonTheme: {
				// styles for the buttons
				fill: "none",
				stroke: "#1C1E22",
				"stroke-width": 1,
				r: 8,
				padding: 3,
				fontSize: "3px",
				style: {
					color: "#282828",
					fontWeight: "medium",
					fontSize: "12px",
				},

				states: {
					hover: {},
					disabled: {
						// Add this block to style the disabled state
						// Background color for disabled buttons
						style: {
							color: "#282828", // Text color for disabled buttons
						},
					},
					select: {
						fill: "#090A0B",
						style: {
							color: "white",
						},
					},
					// disabled: { ... }
				},
			},
			floating: true,
			buttons: [
				{
					type: "month",
					count: 1,
					text: "1m",
					title: "View 1 month",
				},
				{
					type: "month",
					count: 3,
					text: "3m",
					title: "View 3 months",
				},
				{
					type: "ytd",
					text: "YTD",
					title: "View year to date",
				},
				{
					type: "all",
					text: "All",
					title: "View all",
				},
			],
		},
		chart: {
			type: chartType,
			backgroundColor: null,
			animation: false,
			spacingBottom: 70,
			height: "320",
			ignoreHiddenSeries: false,

			zoomType: "x",
			resetZoomButton: {
				theme: {
					style: {
						display: "none",
					},
				},
			},
			events: {
				redraw: function () {
					setIsZoomed(!!this.resetZoomButton);
				},
			},
			
			selectionMarkerFill: "rgba(29, 32, 35, 0.2)",
			spacingTop: 50,
			spacingLeft: 50,
			spacingRight: 50,
		},
		exporting: {
			enabled: false,
		},
		scrollbar: {
			enabled: false,
		},

		xAxis: {
			type: "datetime",

			dateTimeLabelFormats: {
				day: "%m-%d",
			},
			labels: {
				enabled: showXAxis,
			},
			visible: showXAxis,
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			series: {},
		},
		navigator: {
			enabled: false,
		},
		legend: {
			enabled: true, // Enable the legend
			y: -80,
			align: "center", // Optional: Position the legend (left, center, or right)
			verticalAlign: "top", // Optional: Position the legend vertically (top, middle, or bottom)
			floating: true,
		},

		series: [
			{
				animation: {
					duration: 1000,
				},
				showInNavigator: false,
				showInLegend: false,
				data: data,
				name: "Värde",
				id: "main-series",
				lineWidth: 2,
				turboThreshold: 0,
				boostThreshold: 10,
				linecap: "round",
				marker: {
					enabled: false,
				},
				legend: {
					enabled: true,
				},
				states: {
					hover: {
						enabled: true,
						marker: {
							enabled: true,
							symbol: "circle",
							radius: 4,
						},
					},
				},
				color:
					chartType === "column"
						? {
								linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
								stops: [
									[0, "#05D8A5"], // Start color (top of the column)
									[1, "rgba(5, 216, 165, 0.8)"], // End color (bottom of the column)
								],
						  }
						: "#05D8A5", // default positive color for non-column types
				// Apply a different gradient for negative values if desired
				negativeColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(229, 62, 62, 0)"],
						[1, "rgba(229, 62, 62, 0.3)"], // Start color for negative area
						// End color, fades out for negative area
					],
				},
				negativeColor:
					chartType === "column"
						? {
								linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
								stops: [
									[0, "#E53E3E"], // Start color (top of the column)
									[1, "rgba(229, 62, 62, 0.8)"], // End color (bottom of the column)
								],
						  }
						: "#E53E3E",
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(5, 216, 165, 0.3)"], // Start color for area
						[1, "rgba(5, 216, 165, 0)"], // End color, fades out for area
					],
				},
				// Apply a different gradient for negative values if desired
				negativeFillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, "rgba(229, 62, 62, 0)"],
						[1, "rgba(229, 62, 62, 0.3)"], // Start color for negative area
						// End color, fades out for negative area
					],
				},
			},
			{
				type: "ema",
				linkedTo: "main-series", // Make sure this ID matches the ID of your main series
				params: {
					period: 10, // Adjust the period according to your needs
				},
				lineWidth: 1,
				color: "orange",
				marker: {
					enabled: false, // Use state to control marker visibility
				},
				name: "EMA (10)", // This will be displayed in the legend
				showInLegend: true, // Ensure the series is shown in the legend
			},
			{
				type: "sma",
				linkedTo: "main-series", // Make sure this ID matches the ID of your main series
				params: {
					period: 20, // Adjust the period according to your needs
				},
				zIndex: 1,
				color: "white",
				lineWidth: 1,
				visible: false,
				marker: {
					enabled: false, // Use state to control marker visibility
				},
				name: "SMA (20)", // This will be displayed in the legend
				showInLegend: true, // Ensure the series is shown in the legend
			},
		].filter(Boolean),
	};

	const chartRef = useRef(null);
	const handleChartTypeChange = () => {
		setChartType(chartType === "areaspline" ? "column" : "areaspline");
	};

	const resetZoom = () => {
		if (chartRef.current) {
			chartRef.current.chart.zoomOut();
			setIsZoomed(false);
		}
	};

	return (
		<Box
			borderWidth="1px"
			borderColor="#1C1E22"
			borderRadius="2xl"
			overflow="hidden"
			mb={2}
			ml={0}
			mr={0}
			minW="728px"
		
			height="350px"
			maxHeight="350px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="relative"
		>
			{isLoading ? (
				 data && data.length === 0 ? (	
					<VStack spacing={4} alignItems="center" textAlign="center" justifyContent={"center"} >
					<Heading fontSize="18" color="#fff" opacity={0.87}>
						..Ingen data att visa...
					</Heading>
					<Button
						background="rgb(213,63,140, 0.15)"
						textColor="#d53f8c"
						_hover={{ bg: "rgba(213,63,140, 0.3)" }}
						size={"sm"}
						onClick={() => navigate("/allatrades")}
					>
						Ladda upp trades
					</Button>
				</VStack>
                ) : (
                    <Skeleton height="300px" isLoaded={true} width="700px" startColor="#050606" endColor="#090A0B" borderRadius="2xl" />
                )
			) : (
				<Box width={"100%"} position="absolute" top={0} left={0} p={6}>
					<VStack align="flex-start" mt={1}>
						<HStack justifyContent="space-between" w="100%">
							<Flex alignItems="center">
								<Box>
									<Heading fontSize="18" ml={5} color="#fff" opacity={0.87}>
										{title}
										<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
											<span>
												<Icon as={AiOutlineInfoCircle} boxSize={18} color="#868686" ml={2} />
											</span>
										</Tooltip>
									</Heading>
									<Heading fontSize="14" ml={5} color="#868686" fontWeight="medium" opacity={0.87}>
										{secondTitle}
									</Heading>
								</Box>
								<Box ml={100} alignSelf={"flex-end"} mt={2}>
									{isZoomed && <Button onClick={resetZoom}>Reset Zoom</Button>}
								</Box>
							</Flex>
							<Box alignSelf="flex-end" mb={-3} zIndex={10}>
								{chartType === "column" ? (
									<Icon
										as={MdTrendingUp}
										boxSize={7}
										_hover={{ color: "#05D8A5" }}
										cursor="pointer"
										onClick={handleChartTypeChange}
										color={chartType === "area" ? "#05D8A5" : "#282828"}
									/>
								) : (
									<Icon
										as={MdBarChart}
										boxSize={7}
										_hover={{ color: "#05D8A5" }}
										cursor="pointer"
										onClick={handleChartTypeChange}
										color={chartType === "column" ? "#05D8A5" : "#282828"}
									/>
								)}
							</Box>
						</HStack>
					</VStack>
					<Box  mt={-6}>
						<HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} ref={chartRef} options={options} />
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default LinechartComponent;
