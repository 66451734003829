import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PrivateRoute({ children }) {
  let navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    fetch("https://backend-production-41c0.up.railway.app/verifySession", {
      method: "POST",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Session not valid");
        }
        return res.json();
      })
      .then((data) => {
        if (data.valid) {
          setAuthenticated(true);
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  }, [navigate]);

  return authenticated ? children : null;
}

export default PrivateRoute;
