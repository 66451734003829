import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  chakra,
  extendTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "../../components/buttons/feedbackbutton";
import CalendarweekView from "../../components/calendarsmall/adobweekCalendar";
import CombinedCarousel from "../../components/cards/carouselcard/dashboardcarouselcard";
import Dashboardcard from "../../components/cards/dashboardcard";
import Imported from "../../components/cards/importedcard";
import LinechartComponent from "../../components/charts/linechart";
import PiechartComponent from "../../components/charts/piechart";
import "../../components/pagerelated/css/customScrollbar.css";
import Footer from "../../components/pagerelated/footer";
import MyMarqueeComponent from "../../components/pagerelated/marqueeslider";
import { handleLogout } from "../../hooks/logout";
import Sidebar from "../Sidebar/sidebar";
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#090A0B",
        color: "white",
      },
    },
  },
});
const Dashboard = () => {
  const colorWhite = "#868686";
  const navigate = useNavigate();
  const [winRate, setWinRate] = useState(0);
  const [lossRate, setLossRate] = useState(0);
  const [evenRate, setEvenRate] = useState(0);
  const [journalStatus, setJournalStatus] = useState({});
  const [csvStatus, setCsvStatus] = useState({});
  const [profits, setProfits] = useState([]);
  const [chartData, setChartData] = useState([]);
  const handleJournalStatusChange = (newStatus) => {
    setJournalStatus(newStatus);
  };
  const handleCsvStatusChange = (newStatus) => {
    setCsvStatus(newStatus);
  };
  const StyledButton = chakra(Button, {
    baseStyle: {
      // Apply hover styles when parent Box is hovered
      _groupHover: {
        background: "rgba(29, 32, 35, 0.8)",
      },
    },
  });
  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const days = [
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
      "Söndag",
    ];
    return days[date.getDay()];
  };
  const fetchData = async () => {
    try {
      const response = await fetch("https://backend-production-41c0.up.railway.app/dashboard", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        const formattedProfits = data.profits.map((item) => ({
          ...item,
          date: item.date.split("T")[0], // Dela strängen vid 'T' och ta första delen (datumet)
        }));
        setProfits(
          formattedProfits.sort((a, b) => new Date(a.date) - new Date(b.date))
        );
        const weekStatus = data.weekStatus.week;
        const newJournalStatus = {};
        const newCsvStatus = {};
        Object.keys(weekStatus).forEach((date) => {
          const dayOfWeek = getDayOfWeek(date);
          newJournalStatus[dayOfWeek] = weekStatus[date].journal;
          newCsvStatus[dayOfWeek] = weekStatus[date].trade;
        });
        setJournalStatus(data.weekStatus.week);
        // Convert win, loss, and even rates from string to number
        const win = parseFloat(data.win.replace("%", ""));
        const loss = parseFloat(data.loss.replace("%", ""));
        const even = parseFloat(data.even.replace("%", ""));
        // Log types for debugging
        setWinRate(win);
        setLossRate(loss);
        setEvenRate(even);
      } else {
        throw new Error("Något gick fel vid hämtning av data");
      }
    } catch (error) {
      //console.error("Fel vid hämtning av data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [selectedDateRange, setSelectedDateRange] = useState(() => {
    // Try to load the saved date range from local storage
    const savedRange = localStorage.getItem("selectedDateRange");
    return savedRange ? JSON.parse(savedRange) : [];
  });
  const handleDateRangeSelect = (dateRange) => {
    if (Array.isArray(dateRange)) {
      setSelectedDateRange(dateRange);
      // Save the selected date range to local storage
      localStorage.setItem("selectedDateRange", JSON.stringify(dateRange));
    } else {
      //console.error("Expected an array for dateRange, received:", dateRange);
    }
  };

  useEffect(() => {
    const filteredProfits = profits.filter((profit) =>
      selectedDateRange.includes(profit.date.split("T")[0])
    );
    // Aggregate profits by date
    const aggregatedProfits = filteredProfits.reduce(
      (acc, { date, profit }) => {
        // Convert date to a format that can be used as a key (e.g., 'YYYY-MM-DD')
        const dateKey = date.split("T")[0];
        // If this date isn't in the accumulator yet, add it with initial profit
        if (!acc[dateKey]) {
          acc[dateKey] = { date: dateKey, profit: 0 };
        }
        // Add this item's profit to the total for its date
        acc[dateKey].profit += profit;
        return acc;
      },
      {}
    );
    // Convert aggregated data into chart format
    const newChartData = Object.values(aggregatedProfits).map(
      ({ date, profit }) => ({
        x: Date.parse(date),
        y: profit,
      })
    );
    setChartData(newChartData);
  }, [selectedDateRange, profits]);

  const hStackMaxWidth = useBreakpointValue({
    base: "100%", // 100% of the screen width on small devices
    md: "100%", // 100% of the screen width on medium devices
    lg: "100%", // 100% of the screen width on large devices
    xl: "100%", // 100% of the screen width on extra-large devices
  });

  return (
    <ChakraProvider theme={theme}>
      <Flex>
        <Sidebar />

        <VStack
          maxW={hStackMaxWidth}
          flex={1}
          alignItems="flex-start"
          gap="7"
          ml={50}
          mr={50}
          pt={0}
          className="custom-scrollbar"
        >
          <HStack justifyContent="space-between" w="100%" mt={9}>
            <HStack alignSelf="self-start">
              <Text alignSelf="center" fontSize="12" color="#868686">
                Fönster
              </Text>
              <Text alignSelf="center" fontSize="16" color={"#fff"}>
                / Dashboard
              </Text>
            </HStack>
            <MyMarqueeComponent></MyMarqueeComponent>
            <HStack mr={0} alignItems="center" alignSelf="self-end">
              <FeedbackButton></FeedbackButton>
              <Button
                onClick={() => handleLogout(navigate)}
                fontWeight={"medium"}
              >
                Logga ut
              </Button>
            </HStack>
          </HStack>
          <HStack
            alignItems={"flex-start"}
            justifyContent="space-between"
            spacing={12}
            maxW={hStackMaxWidth}
          >
            <VStack alignItems="start">
              <HStack spacing={12}>
                <Box>
                  <Heading fontSize={24} mb={0} color={"#05D8A5"}>
                    Välkommen tillbaka!
                  </Heading>
                </Box>
                <Box>
                  <HStack>
                    <CalendarweekView
                      onDateChange={handleDateRangeSelect}
                    ></CalendarweekView>
                    <Button
                      borderBottomWidth="0px"
                      backgroundColor={"rgba(29, 32, 35, 0.6)"}
                      filter="hue-rotate(0deg)"
                      opacity="1"
                      color={"white"}
                      boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
                      ml={8}
                      size={"sm"}
                      fontWeight={"medium"}
                      _hover={{
                        backgroundColor: "rgba(29, 32, 35, 0.9)",
                      }}
                      onClick={() => navigate("/trender")}
                    >
                      Veckoresultat
                    </Button>
                  </HStack>
                </Box>
              </HStack>

              <VStack spacing={10} alignItems="start">
                <HStack spacing={12} width={"100%"}>
                  <Box width={"100%"}>
                    <LinechartComponent
                      title={"Utveckling"}
                      data={chartData}
                      tooltip={
                        "Visar en sammanfattning av de affärer som genomförts under vald veckoperiod, markera över för att ändra perioden. Grafen inkluderar information om både vinst (W) och förlust (L) för varje enskild trade. Används för att få en översiktlig bild av hur handeln har utvecklats över dagen."
                      }
                      secondTitle={"Vald dag PnL"}
                      defaultChartType={"areaspline"}
                      prefix={"kr"}
                    ></LinechartComponent>
                  </Box>

                  <VStack alignItems="end" width={"auto"} spacing={10} mt={-16}>
                    <Box
                      borderWidth="2px"
                      borderColor="#1C1E22"
                      borderRadius="2xl"
                      boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
                      p={6}
                      ml={0}
                      mr={0}
                      w={"100%"}
                      maxW={"506px"}
                      borderStyle={"dashed"}
                      display="flex"
                      _hover={{
                        borderColor: "#05D8A5",
                        transition: "border-color 0.5s ease-in-out",
                      }}
                      alignItems="center"
                      height={"130px"}
                      justifyContent="center"
                      role="group"
                    >
                      <VStack>
                        <HStack align="center" mt={3}>
                          <Heading
                            fontSize="18"
                            ml={5}
                            color="#fff"
                            opacity={0.87}
                          >
                            Anslut Din Mäklare
                          </Heading>
                          <StyledButton
                            as={Button}
                            background="none"
                            color="#fff"
                            backgroundColor="rgba(29, 32, 35, 0.6)"
                            filter="hue-rotate(0deg)"
                            opacity="1"
                            boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
                            fontSize="10px"
                            mt={-2}
                            size="xs"
                          >
                            kommer snart
                          </StyledButton>
                        </HStack>
                        <Text fontSize={14} lineHeight={6} color={colorWhite}>
                          Importera all din handel automatiskt. Enkelt och
                          tryggt.
                        </Text>
                      </VStack>
                    </Box>

                    <Box>
                      <PiechartComponent
                        title={"Winrate %"}
                        secondTitle={"Vinner du?"}
                        tooltip={
                          "Andelen vinnande affärer i förhållande till det totala antalet affärer. En hög winrate, till exempel över 50%, är generellt sett önskvärd eftersom det indikerar att fler affärer är vinnande än förlorande."
                        }
                        customWidth={"506px"}
                        valueoneFormal={winRate}
                        valuetwoFormal={lossRate}
                        valuethreeFormal={evenRate}
                        valueoneName={"Vinster"}
                        valuetwoName={"Förluster"}
                        valuethreeName={"Even"}
                        labelone={"vinster"}
                        labeltwo={"förluster"}
                        labelthree={"even"}
                        valueone={winRate + "%"}
                        valuetwo={lossRate + "%"}
                        valuethree={evenRate + "%"}
                        customHeight={"240px"}
                      >
                        {/* HÄMTNING CSV*/}
                      </PiechartComponent>
                    </Box>
                  </VStack>
                </HStack>

                <HStack spacing={12}>
                  <CombinedCarousel
                    tooltip={"Tooltip"}
                    customwidth={"500px"}
                  ></CombinedCarousel>
                  <Dashboardcard
                    title={"Vilka och varför?"}
                    content={
                      "Tre Göteborgare vill göra din handelsupplevelse bättre. Tradalyze ska bli din coach och plattform, där du lätt kan analysera handelsdag, trender och strategier. Ladda bara upp dina data, så fixar vi resten med skarp statistik och nyckeltal för att du ska kunna handla som ett proffs. Vi är här för att göra trading lätt, kul och lönsamt för alla! 🚀📈"
                    }
                    tooltip={"Tooltip"}
                    customwidth={"735px"}
                  ></Dashboardcard>
                </HStack>
              </VStack>
            </VStack>

            <Imported
              title={"Importering"}
              tooltip={
                "Se över ifall du har importerat din trades samt skrivit din journal för veckans handelsdagar."
              }
              onJournalStatusChange={handleJournalStatusChange}
              onCsvStatusChange={handleCsvStatusChange}
              weekStatus={journalStatus}
            >
              {" "}
            </Imported>
          </HStack>
          <Footer></Footer>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};
export default Dashboard;
