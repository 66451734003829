import { Box, Heading, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const colorWhite = "#fff";

const Smallcardtwo = ({ backgroundcolor="#090A0B", title, showIcon = true, content, tooltip, customWidth = "200px", customHeight = "100px" }) => (
	<Box
		bg= {backgroundcolor}
		borderWidth="1px"
		borderColor="#1C1E22"
		borderRadius="2xl"
		overflow="hidden"
		boxShadow="rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset"
		p={6}
		ml={0}
		mr={0}
		width="100%"
		
		minWidth={customWidth}
		height={customHeight}
		display="flex"
		alignItems="center"
		justifyContent="center"
	>
		<Box flex="1" mt={3}>
			<Heading fontSize="12" mb={2} color="#868686" opacity={0.87}>
				{title}{" "}
				{showIcon && ( // Conditionally render the Tooltip with Icon
					<Tooltip label={tooltip} placement="auto-start" gutter={20} bg="#090A0B" color={colorWhite} padding={3} borderRadius={"2xl"}>
						<span>
							<Icon as={AiOutlineInfoCircle} boxSize={4} color="#434A54" ml={2} />
						</span>
					</Tooltip>
				)}
			</Heading>
			<Text fontSize="18" fontWeight="bold">
				{content}
			</Text>
		</Box>
	</Box>
);

export default Smallcardtwo;
