import { Button } from "@chakra-ui/react";
import React from "react";

const StatsSelected = ({ title, customcolor, customTitlecolor, onClick, disabled }) => {
	const handleButtonClick = () => {
		if (disabled) return;
		onClick(title);
	};

	const backgroundColor = customcolor ? customcolor : "#fff";

	return (
		<Button
			onClick={handleButtonClick}
			backgroundColor={backgroundColor}
			_hover={{ background: backgroundColor }}
			variant="solid"
			size="md"
			color={customTitlecolor ? customTitlecolor : "#090A0B"}
			disabled={disabled}
		>
			{title}
		</Button>
	);
};

export default StatsSelected;
