import React from 'react';
import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import { IoMdBookmark, IoMdImage } from "react-icons/io";
import { LuShare2 } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";
import { TbFileTypeCsv } from "react-icons/tb";

const ShareBox = () => {
  return (
    <Box
      bg="#090A0B"
      borderWidth="1px"
      borderColor="#1C1E22"
      borderRadius="2xl"
      overflow="hidden"
      p={1}
      ml={0}
      mr={0}
      minW={{ base: "100%", lg: "384px" }}
      minH={{ base: "35vh", md: "20vh",   lg: "150px", "2xl": "150px" }}
      display={"flex"}
      h={"100%"}
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <HStack
        mt={{ base: 4, md: 12, lg: 12 }}
       ml={{ base: 0, lg: 16, "2xl": 0 }}
        display="flex"
        flexDirection="Row"
        justifyContent="center"
        flex="1"
        spacing={8}
      >
        {[IoMdBookmark, IoMdImage, LuShare2, FaXTwitter].map((Icon, index) => (
          <span key={index}>
            <Box
              as={Icon}
              w={6}
              h={6}
              color={"#868686"}
              _hover={{
                cursor: "pointer",
                filter: "brightness(1.2)",
              }}
            />
          </span>
        ))}
        <Box display={{ base: "none", md: "block" }}>
          <Box
            as={TbFileTypeCsv}
            w={6}
            h={6}
            color={"#868686"}
            _hover={{
              cursor: "pointer",
              filter: "brightness(1.2)",
            }}
          />
        </Box>
      </HStack>
      <VStack
        justify={"center"}
        mb={4}
        align={"start"}
        ml={10}
      >
        <Text fontSize="18" color="#fff" opacity={0.87}>
          Dela
        </Text>
        <Text
          w={"80%"}
          fontSize="16"
          mt={-4}
          color="#868686"
          opacity={0.87}
        >
          Bokmärk en dag, dela till en vän eller spara din
          statistik som bild för din sociala plattform.
        </Text>
      </VStack>
    </Box>
  );
};

export default ShareBox;