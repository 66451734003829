const STATUS_ON_DECK = { id: 1, name: "On Deck", color: "blue.300" };
const STATUS_IN_PROGRESS = {
  id: 2,
  name: "In Progress",
  color: "yellow.400",
};
const STATUS_TESTING = { id: 3, name: "Testing", color: "pink.300" };
const STATUS_DEPLOYED = { id: 4, name: "Deployed", color: "green.300" };
export const STATUSES = [
  STATUS_ON_DECK,
  STATUS_IN_PROGRESS,
  STATUS_TESTING,
  STATUS_DEPLOYED,
];

const DATA = [
  {
    vinstförlust: "Förlust",
    datum: "2023/02/15",
    namn: "Volvo AB",
    storlek: "23003",
    courtage: "50",
    resultat: "23003",
    resultatprocent: "100",
  },
  {
    vinstförlust: "Förlust",
    datum: "2023/02/15",
    namn: "AstraZeneca",
    storlek: "23003",
    courtage: "50",
    resultat: "23003",
    resultatprocent: "100",
  },
  {
    vinstförlust: "Förlust",
    datum: "2023/02/15",
    namn: "Hans Pharma AB",
    storlek: "23003",
    courtage: "50",
    resultat: "23003",
    resultatprocent: "100",
  },
  {
    vinstförlust: "Vinst",
    datum: "2023/03/21",
    namn: "Ericsson",
    storlek: "15200",
    courtage: "30",
    resultat: "460",
    resultatprocent: "3",
  },
  {
    vinstförlust: "Vinst",
    datum: "2023/04/10",
    namn: "IKEA Group",
    storlek: "40000",
    courtage: "75",
    resultat: "800",
    resultatprocent: "2",
  },
  
];


export default DATA;